<template>
  <base-input-primary-button
      :label=label
      class="ml-2"
      icon="mdi-microsoft-excel"
      @click="processExcel"/>
</template>

<script>
import XLSX from 'xlsx'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'

export default {
  name: 'BaseTableExcelExporter',
  components: { BaseInputPrimaryButton },
  props: {
    excelData: {
      type: Array,
      require: true
    },
    fileName: {
      type: String,
      require: true
    },
    label: {
      type: String,
      require: false,
      default: 'Download result to excel'
    },
    fetchAll: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  methods: {
    processExcel () {
      if (this.fetchAll) {
        this.$emit('fetchAll')
      } else {
        this.exportToExcel()
      }
    },
    exportToExcel () {
      const data = XLSX.utils.json_to_sheet(this.excelData)
      const wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      XLSX.utils.book_append_sheet(wb, data, this.fileName) // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, this.fileName + '.xlsx')
    }
  },
  watch: {
    excelData () {
      if (this.fetchAll && this.excelData.length > 0) {
        this.exportToExcel()
      }
    }
  }
}
</script>
<style scoped>
</style>
