<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="input"
        filled
        v-model="theValue"
        :label="itemLabel"
        prepend-icon="mdi-calendar"
        :rules="rules"
        :validate-on-blur="validateOnBlur"
        :hint="hint"
        :persistent-hint="showHint"
        :error="error"
        :error-messages="errorMessages"
        readonly
        v-bind="attrs"
        v-on="on"
        @input="formatDate"
        clearable>
      </v-text-field>
    </template>
    <v-date-picker
      no-title
      v-model="theValue"
      :max="maxDate"
      :min="minDate"
      :picker-date.sync="thePickerDate"
      @input="input">
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'BaseInputSelectDatePicker',
  props: {
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      type: String,
      require: false
    },
    min: {
      type: String,
      require: false,
      default: undefined
    },
    max: {
      type: String,
      require: false,
      default: undefined
    },
    pickerDate: {
      type: String,
      require: false,
      default: undefined
    },
    minToday: {
      type: Boolean,
      require: false,
      default: false
    },
    rules: {
      type: Array,
      require: false
    },
    hint: {
      type: String,
      require: false
    },
    // Puts the input in a manual error state
    error: {
      type: Boolean,
      require: false,
      default: false
    },
    // Puts the input in an error state and passes through custom error messages.
    // Will be combined with any validations that occur from the rules prop
    errorMessages: {
      type: Array,
      require: false
    },
    validateOnBlur: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      theValue: '',
      minDate: '',
      maxDate: '',
      thePickerDate: ''
    }
  },
  mounted () {
    this.theValue = this.selectedValue
    if (this.minToday) {
      this.minDate = new Date().toISOString().split('T')[0]
    } else {
      this.minDate = this.min
    }
    this.maxDate = this.max
    this.thePickerDate = this.pickerDate
  },
  watch: {
    selectedValue (val) {
      this.theValue = val
    },
    min (val) {
      this.minDate = val
    },
    max (val) {
      this.maxDate = val
    },
    pickerDate (val) {
      this.thePickerDate = val
    }
  },
  computed: {
    showHint () {
      return !!this.hint
    }
  },
  methods: {
    input () {
      this.menu = false
      this.$emit('change', this.theValue)
    },
    formatDate () {
      if (!this.theValue) {
        this.theValue = ''
      }
      this.$emit('change', this.theValue)
    }
  }
}
</script>

<style scoped>

</style>
