<template>
  <v-app>
    <base-ajax-loader/>
    <v-main class="grey lighten-3">
      <router-view></router-view>
    </v-main>
    <navigation></navigation>
    <v-footer app class="footer-component">
      <div class="app-footer">
        <v-img alt="HMGroup Brands Logo"
               class="shrink mr-2"
               contain
               src="@/assets/hmgroup-brands.png"/>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Navigation from '@/navigation/Navigation'
import BaseAjaxLoader from './base/BaseAjaxLoader'

export default {
  name: 'App',
  components: {
    Navigation,
    BaseAjaxLoader
  }
}
</script>
<style>
  .v-main {
    padding: 64px 0 40px 10px !important;
  }
  .container {
    max-width: 1100px;
  }
  /* Need for BaseInputMultiSelectWithFreightService.vue work properly */
  .v-list-item {
    min-height: 0;
  }
  /* Need for solo inputs to not take much space */
  .v-text-field.v-text-field--solo .v-input__control {
    max-height: 30px !important;
  }
  /* Removed arrows in number inputs*/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Removed arrows in number inputs*/
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .logistics-lead-time-search-results table tbody td {
    font-size: 12px !important;
  }

  .v-card__title.label {
    text-transform: uppercase !important;
  }

  .app-title {
    cursor: pointer;
    color: #909090;
    opacity: 0.8;
  }

  .app-footer {
    width: 40%;
    margin: auto;
  }

  .footer-component {
    background-color: #fff;
  }

  .v-dialog  {
    background-color: #fff !important;
  }
</style>
