<template>
  <v-card min-height="150px" class="pb-1">
    <v-card-title class="label mb-1  pt-3" >
      <v-icon class="mr-2">
        mdi-calendar-clock
      </v-icon>
      Timeline
    </v-card-title>
    <v-divider></v-divider>
    <!--Shipping Lead time section-->
    <v-timeline dense class="mt-3 mb-3">
      <v-timeline-item small color="red darken-1" class="mb-1">
        <v-card class="elevation-1 mr-4">
          <v-row>
            <v-col class="pt-0">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Place of loading</span>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2" class="padding">
              <span><country-flag :country="shippingLeadTime.placeOfLoading.location.code" size='normal' class="mt-1 ml-1"/></span>
            </v-col>
            <v-col class="text-body-2 ml-1 d-flex align-center padding">
              {{ shippingLeadTime.placeOfLoading.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
      <v-timeline-item color="red lighten-2" large class="mb-1">
        <template v-slot:icon>
          <span class="white--text text-small">{{shippingLeadTime.totalDays}} days</span>
        </template>

        <v-row class="mt-0">
          <v-col class="mt-0 col-md-5">
            <base-label-with-freight-service :freight-service="shippingLeadTime.freightService"/>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item small color="red darken-1" class="mb-1">
        <v-card class="elevation-1 mr-4 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Place of delivery</span>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2" class="padding">
              <span><country-flag :country="shippingLeadTime.placeOfDelivery.location.code" size='normal' class="mt-1 ml-1"/></span>
            </v-col>
            <v-col class="text-body-2 ml-1 d-flex align-center padding">
              {{ shippingLeadTime.placeOfDelivery.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <!--Transit Lead time section-->
      <v-timeline-item color="red lighten-2" large class="mb-1" v-if="showTransitTimeLine">
        <template v-slot:icon>
          <span class="white--text text-small">{{transitLeadTime.totalDays}} days</span>
        </template>

        <v-row class="mt-0">
          <v-col class="mt-0 col-md-5">
            <base-label-with-freight-service :freight-service="transitLeadTime.outboundFreightService"/>
          </v-col>
        </v-row>
      </v-timeline-item>

      <v-timeline-item color="red darken-2" small class="mb-1" v-if="showTransitTimeLine">
        <v-card class="elevation-4 mr-4">
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Place of delivery</span>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2" class="padding">
              <span><country-flag :country="transitLeadTime.placeOfDelivery.location.code" size='normal' class="mt-1 ml-1"/></span>
            </v-col>
            <v-col class="text-body-2 ml-1 d-flex align-center padding">
              {{ transitLeadTime.placeOfDelivery.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <!--Haulage Lead time section-->
      <v-timeline-item color="red lighten-2" large class="mb-1" v-if="showHaulageTimeLine">
        <template v-slot:icon>
          <span class="white--text text-small">{{haulageLeadTime.totalDays}} days</span>
        </template>

        <v-row class="mt-0">
          <v-col class="mt-0 col-md-5">
            <base-label-with-freight-service :freight-service="haulageLeadTime.freightService"/>
          </v-col>
        </v-row>
      </v-timeline-item>

      <v-timeline-item color="red darken-2" small class="mb-1" v-if="showHaulageTimeLine">
        <v-card class="elevation-4 mr-4">
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Destination point</span>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2" class="padding">
              <span><country-flag :country="haulageLeadTime.destinationPoint.location.code" size='normal' class="mt-1 ml-1"/></span>
            </v-col>
            <v-col class="text-body-2 ml-1 d-flex align-center padding">
              {{ haulageLeadTime.destinationPoint.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item color="red lighten-2" large class="mb-1" v-if="showFinalDestinationTimeLine">
        <template v-slot:icon>
          <span class="white--text text-small">{{finalDestinationLeadTime.totalDays}} days</span>
        </template>

        <v-card class="elevation-4 mr-4">
          <v-row class="mt-0">
            <v-col cols="2">
              <v-icon color="red" x-large class="mr-2 warehouseIcon">mdi-warehouse</v-icon>
            </v-col>
            <v-col class="text-body-2 d-flex align-center">
              {{ formatFinalDestinationWarehouse }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item color="red darken-2" small class="mb-1" v-if="showFinalDestinationTimeLine">
        <v-row class="mt-0">
          <v-col cols="4" class="ml-3">
            <v-img alt="Vuetify Logo"
                   class="shrink mr-2"
                   contain
                   src="@/assets/logo.gif"
                   transition="scale-transition"
                   width="40"/>
          </v-col>
        </v-row>
      </v-timeline-item>

      <v-timeline-item color="red lighten-2" large class="mb-1" v-if="showFinalDestinationTimeLine">
        <template v-slot:icon>
          <span class="white--text text-small font-weight-bold">{{logisticLeadTimeTotalDays}} days</span>
        </template>

        <v-card class="elevation-4 mr-4">
          <v-row class="mt-0">
            <v-col class="text-body-2 ml-3 d-flex align-center">
              <span>Total logistics lead time</span>
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
export default {
  name: 'LogisticLeadTimeTimeLine',
  data () {
    return {
      panel: [0, 1, 2, 3]
    }
  },
  components: { CountryFlag, BaseLabelWithFreightService },
  props: {
    shippingLeadTime: {
      type: Object,
      require: true
    },
    transitLeadTime: {
      type: Object,
      require: false
    },
    haulageLeadTime: {
      type: Object,
      require: false
    },
    finalDestinationLeadTime: {
      type: Object,
      require: true
    }
  },
  computed: {
    showTransitTimeLine () {
      return Object.keys(this.transitLeadTime).length > 0
    },
    showHaulageTimeLine () {
      return Object.keys(this.haulageLeadTime).length > 0
    },
    showFinalDestinationTimeLine () {
      return Object.keys(this.finalDestinationLeadTime).length > 0
    },
    formatFinalDestinationWarehouse () {
      if (!this.finalDestinationLeadTime || !this.finalDestinationLeadTime.logicalWarehouses) {
        return
      }

      return this.finalDestinationLeadTime.logicalWarehouses.map(warehouse => warehouse.currentWarehouseCode).join(', ')
    },
    logisticLeadTimeTotalDays () {
      let totalDays = this.shippingLeadTime.totalDays

      if (this.showTransitTimeLine) {
        totalDays += this.transitLeadTime.totalDays
      }

      if (this.showHaulageTimeLine) {
        totalDays += this.haulageLeadTime.totalDays
      }

      if (this.showFinalDestinationTimeLine) {
        totalDays += this.finalDestinationLeadTime.totalDays
      }
      return totalDays
    }
  }
}
</script>

<style scoped>
.padding {
  padding: 3px;
}

.text-small {
  font-size: small;
  text-align: center;
}

</style>
