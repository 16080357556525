<template>
  <v-container>
    <v-form ref="final-destination-lead-time-create-form">
      <v-row>
        <v-col class="mt-8">
          <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
            <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage.message">
              <li>{{validationErrorMessage.message}}</li>
            </ul>
          </v-alert>
          <v-card class="mb-6">
            <base-card-title
              label="Create final destination lead time"
              icon="mdi-calendar-export"/>
            <div class="text-h6 mb-3 mt-4 ml-4">Destination details</div>
            <v-row >
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-with-flag
                  item-label="Location"
                  :items="locations"
                  item-country="location.code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="finalDestinationLeadTime.locationId"
                  @change="finalDestinationLeadTime.locationId = arguments[0]; getWarehousesFor(finalDestinationLeadTime.locationId)"/>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-multi-select-with-freight-service
                  item-label="Shipping MoT"
                  :grouped-freight-services="groupedFreightServices"
                  :freight-services="freightServices"
                  :selected-value="finalDestinationLeadTime.inboundFreightServiceIds"
                  @change="finalDestinationLeadTime.inboundFreightServiceIds = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-multi-select-with-flag
                  :items="warehouses"
                  item-value="id"
                  item-text="description"
                  item-country="geographicalCountry.code"
                  item-label="Warehouses"
                  :rules="[rules.selected]"
                  :selected-values="finalDestinationLeadTime.warehouseIds"
                  @change="finalDestinationLeadTime.warehouseIds = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 ml-4 mb-6">Lead times</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Warehouse to allocated days"
                  :value="finalDestinationLeadTime.inWarehouseToAllocatedDays"
                  @blur="finalDestinationLeadTime.inWarehouseToAllocatedDays = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Allocated to sellable days"
                  :value="finalDestinationLeadTime.allocatedToSellableDays"
                  @blur="finalDestinationLeadTime.allocatedToSellableDays = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 ml-4 mb-6">Quick demand deduction</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-number-field
                  item-label="Quick demand deduction"
                  :value="finalDestinationLeadTime.quickDemandDeduction"
                  @blur="finalDestinationLeadTime.quickDemandDeduction = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 ml-4 mb-6">Valid period</div>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid from"
                  :rules="[rules.required]"
                  :selected-value="finalDestinationLeadTime.validFrom"
                  @change="finalDestinationLeadTime.validFrom = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Valid to"
                  :rules="[rules.fromDateIsBeforeToDate]"
                  :selected-value="finalDestinationLeadTime.validTo"
                  @change="finalDestinationLeadTime.validTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
            label="save"
            icon="mdi-arrow-right-bold-circle"
            @click="save" />

          <base-input-secondary-button
            label="clear"
            icon="mdi-autorenew"
            @click="clear"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </v-col>

        <v-col v-if="timelineRequired" cols="5" class="mt-8">
          <create-final-destination-lead-time-line
            :final-destination-lead-time="finalDestinationLeadTime"
            :locations="locations"
            :freight-services="freightServices"
            :warehouses="warehouses"/>
        </v-col>
      </v-row>
    </v-form>

    <base-dialog-send
      v-if="containerType === 'Page'"
      label="Final destination lead time has been saved successfully!"
      @create="createNew"
      @copy="copy"
      @search="search"
      :send-dialog="sendDialog"/>
    <base-dialog-ok
      v-if="containerType === 'Dialog'"
      label="Final destination lead time has been created successfully!"
      :max-width="550"
      :persistent="true"
      @ok="close"
      :dialog="sendDialog"/>
  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import CreateFinalDestinationLeadTimeLine from '@/components/finaldestinationleadtime/createfinaldestinationleadtime/CreateFinalDestinationLeadTimeLine'
import axios from 'axios'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputNumberField from '@/base/input/BaseInputNumberField'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import { validateDate } from '@/validation/customvalidation'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'

export default {
  name: 'CreateFinalDestinationLeadTime',
  components: {
    BaseDialogOk,
    BaseDialogSend,
    BaseInputNumberField,
    BaseInputMultiSelectWithFreightService,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseInputSelectWithFlag,
    BaseInputSelectDatePicker,
    BaseInputMultiSelectWithFlag,
    CreateFinalDestinationLeadTimeLine
  },
  props: {
    locationId: {
      type: String,
      require: false
    },
    proposedLeadTimeId: {
      type: String,
      require: false
    },
    containerType: {
      type: String,
      require: false,
      default: 'Page'
    },
    timelineRequired: {
      type: Boolean,
      require: false,
      default: true
    }
  },
  data () {
    return {
      validationErrorMessages: [],
      locations: [],
      warehouses: [],
      freightServices: [],
      groupedFreightServices: [],
      finalDestinationLeadTime: {
        locationId: '',
        warehouseIds: [],
        inboundFreightServiceIds: [],
        inWarehouseToAllocatedDays: '',
        allocatedToSellableDays: '',
        validFrom: '',
        validTo: '',
        quickDemandDeduction: ''
      },
      copiedLocationId: '',
      sendDialog: false,
      rules: {
        required: value => !!value || 'Required.',
        selected: value => value.length > 0 || 'Select one of the item.',
        fromDateIsBeforeToDate: value => validateDate(this.finalDestinationLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  watch: {
    locationId (val) {
      this.refreshWarehouseDropdown(val)
    },
    copiedLocationId (locationId) {
      this.refreshWarehouseDropdown(locationId)
    }
  },
  mounted () {
    if (this.$route.params.id != null) {
      axios.get('/api/touchpoint/final-destination-lead-time/' + this.$route.params.id)
        .then((response) => this.mapFinalDestinationLeadTimeResponse(response.data))
        .catch(e => console.log(e))
    }

    axios.get('/api/touchpoint/transport-location/place-of-discharge-or-delivery')
      .then(response => ([...response.data]).forEach(data => this.locations.push(data)))
      .then(response => {
        if (this.locationId) {
          this.finalDestinationLeadTime.locationId = this.locationId
          this.getWarehousesFor(this.locationId)
        }
      })
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/grouped-freight-services')
      .then(response => ([...response.data]).forEach(data => this.groupedFreightServices.push(data)))
      .catch(e => console.log(e))
  },
  methods: {
    validate () {
      return this.$refs['final-destination-lead-time-create-form'].validate()
    },
    resetForm () {
      return this.$refs['final-destination-lead-time-create-form'].reset()
    },
    refreshWarehouseDropdown (locationId) {
      this.finalDestinationLeadTime.locationId = locationId
      this.getWarehousesFor(locationId)
    },
    getWarehousesFor (locationId) {
      this.warehouses = []
      if (!locationId) {
        return
      }
      axios.get('/api/touchpoint/logical-warehouse/location/' + locationId)
        .then(response => ([...response.data]).forEach(data => this.warehouses.push(data)))
        .catch(e => console.log(e))
    },
    mapFinalDestinationLeadTimeResponse (data) {
      this.copiedLocationId = data.location.id
      data.logicalWarehouses
        .forEach(warehouse => this.finalDestinationLeadTime.warehouseIds.push(warehouse.id))
      data.inboundFreightServices
        .forEach(freightService => this.finalDestinationLeadTime.inboundFreightServiceIds.push(freightService.id))
      this.finalDestinationLeadTime.inWarehouseToAllocatedDays = data.inWarehouseToAllocated
      this.finalDestinationLeadTime.allocatedToSellableDays = data.allocatedToSellable
      this.finalDestinationLeadTime.validFrom = data.validFrom
      this.finalDestinationLeadTime.validTo = data.validTo
      this.finalDestinationLeadTime.quickDemandDeduction = data.quickDemandDeduction
    },
    save () {
      this.validationErrorMessages = []
      if (this.validate()) {
        if (this.proposedLeadTimeId) {
          const body = {
            proposedFinalDestinationId: this.proposedLeadTimeId,
            finalDestinationLeadTimeCreateCommand: this.finalDestinationLeadTime
          }

          axios.post('/api/touchpoint/create-from-proposed-final-destination-lead-time', body)
            .then((response) => {
              if (response.data.status === 'SUCCESS') {
                this.sendDialog = true
                this.clear()
              } else {
                this.validationErrorMessages = response.data.errorMessageList
                window.scrollTo(0, 0)
              }
            })
            .catch(e => console.log(e))
        } else {
          axios.post('/api/touchpoint/final-destination-lead-time', this.finalDestinationLeadTime)
            .then((response) => {
              if (response.data.status === 'SUCCESS') {
                this.sendDialog = true
              } else {
                this.validationErrorMessages = response.data.errorMessageList
                window.scrollTo(0, 0)
              }
            })
            .catch(e => console.log(e))
        }
      } else {
        window.scrollTo(0, 0)
      }
    },
    clear () {
      this.finalDestinationLeadTime = {
        locationId: '',
        warehouseIds: [],
        inboundFreightServiceIds: [],
        inWarehouseToAllocatedDays: '',
        allocatedToSellableDays: '',
        validFrom: '',
        validTo: '',
        quickDemandDeduction: ''
      }
    },
    cancel () {
      this.clear()
      if (this.proposedLeadTimeId) {
        this.$emit('cancel')
      } else {
        this.$router.push({ name: 'SearchFinalDestinationLeadTime' })
      }
    },
    createNew () {
      this.clear()
      this.sendDialog = false
    },
    copy () {
      this.sendDialog = false
    },
    close () {
      this.sendDialog = false
      this.$emit('close')
    },
    search () {
      this.$router.push({ name: 'SearchFinalDestinationLeadTime' })
    },
    resetValidation () {
      this.$refs['final-destination-lead-time-create-form'].resetValidation()
    }
  }
}
</script>

<style>

</style>
