export const modeOfTransportCategoryIconResolverMixin = {
  methods: {
    resolveIcon (motCategory) {
      if (motCategory && motCategory.name.toUpperCase() === 'SLOW') {
        return 'mdi-speedometer-slow'
      } else if (motCategory && (motCategory.name.toUpperCase() === 'MODERATE' || motCategory.name.toUpperCase() === 'MEDIUM')) {
        return 'mdi-speedometer-medium'
      } else if (motCategory && motCategory.name.toUpperCase() === 'REGULAR') {
        return 'mdi-speedometer-medium'
      } else if (motCategory && motCategory.name.toUpperCase() === 'FAST') {
        return 'mdi-speedometer'
      } else {
        return 'mdi-timelapse'
      }
    }
  }
}
