<template>
  <v-card min-height="150px" class="pb-1">
    <v-card-title>
      <v-icon class="mr-2">
        mdi-calendar-clock
      </v-icon>
      Haulage lead time
    </v-card-title>
    <v-divider class="mb-6" v></v-divider>

    <v-timeline dense v-if="showTimeLine" >

      <v-timeline-item v-if="selectedInboundFreightServices.length" small color="red darken-2" class="mb-2">
        <v-card class="elevation-2 mr-5 ">
          <v-row>
            <v-col class="pt-1 pb-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Inbound freight service</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row  v-for="freightService in selectedInboundFreightServices" v-bind:key="freightService.id">
            <v-col class="ml-4 pa-2">
              <base-label-with-freight-service :freight-service="freightService"/>
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item v-if="selectedAvailablePoint" small color="red darken-2" class="mb-2" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Available Point</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <country-flag :country="selectedAvailablePoint.location.code" size='big' class="mt-1 ml-2"/>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ selectedAvailablePoint.name }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <template v-if="selectedHaulageMot">
        <v-timeline-item v-for="(transportMode, index) in selectedHaulageMot.transportModes" v-bind:key="index"
                         fill-dot color="red darken-2" :icon="resolveIcon(transportMode)" class="mb-2">
          <v-card class="elevation-2 mr-5 " >
            <v-row>
              <v-col class="pt-1 pb-0">
                <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Haulage mode of transport</span>
                <v-divider class="mt-1"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-body-2 ml-3">
                <v-icon> {{resolveIcon(transportMode)}}</v-icon>
                <span class="font-weight-medium"> {{ transportMode.description }} </span>
              </v-col>
            </v-row>
          </v-card>
        </v-timeline-item>
      </template>

      <v-timeline-item v-if="selectedDestinationPoint" small color="red darken-2" class="mb-2" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Destination Point</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <country-flag :country="selectedDestinationPoint.location.code" size='big' class="mt-1 ml-2"/>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ selectedDestinationPoint.name }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <v-card-text v-else class="justify-center">
      <v-row class="justify-center"> Time line for haulage lead time </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'
export default {
  name: 'CreateHaulageLeadTimeTimeLine',
  mixins: [transportModeIconResolverMixin],
  components: { CountryFlag, BaseLabelWithFreightService },
  props: {
    haulageLeadTime: {
      type: Object,
      require: true
    },
    arrivalPoints: {
      type: Array,
      require: true
    },
    destinationPoints: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    }
  },
  computed: {
    selectedAvailablePoint () {
      return this.arrivalPoints.find(item => item.id === this.haulageLeadTime.arrivalPointId)
    },
    selectedDestinationPoint () {
      return this.destinationPoints.find(item => item.id === this.haulageLeadTime.destinationPointId)
    },
    selectedInboundFreightServices () {
      const selectedFreightServices = []
      if (this.haulageLeadTime.freightServiceIds) {
        this.freightServices.forEach(item => {
          if (this.haulageLeadTime.freightServiceIds.includes(item.id)) {
            selectedFreightServices.push(item)
          }
        })
        return selectedFreightServices
      }
      return []
    },
    selectedHaulageMot () {
      return this.freightServices.find(item => item.id === this.haulageLeadTime.freightServiceId)
    },
    showTimeLine () {
      return this.selectedAvailablePoint ||
        this.selectedDestinationPoint ||
        this.haulageLeadTime.freightServiceIds.length
    }
  }
}
</script>

<style scoped>

</style>
