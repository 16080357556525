<template>
  <v-container>
    <v-row>
      <v-col class="mt-8">
        <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="(validationErrorMessage, index) in validationErrorMessages" v-bind:key="index">
            <li>{{validationErrorMessage.message}}</li>
          </ul>
        </v-alert>
        <v-stepper v-model="stepper">
          <create-shipping-lead-time-stepper-head
            :stepper="stepper"/>
          <v-divider></v-divider>
          <v-stepper-items>
            <v-stepper-content step="1">
              <update-shipping-lead-time-step1
                ref="step1"
                :shipping-lead-time="shippingLeadTime"
                :geographical-countries="geographicalCountries"
                :places-of-loading="placesOfLoading"
                :service-providers="serviceProviders"
                :freight-services="freightServices"
                :places-of-delivery="placesOfDelivery"
                :carriers="carriers"
                :service-codes="serviceCodes"
                :replace-slt="replaceSlt"
                :old-shipping-lead-time="oldShippingLeadTime"/>
            </v-stepper-content>

            <v-stepper-content step="2">
              <create-shipping-lead-time-step2
                ref="events"
                :selected-freight-service-id="shippingLeadTime.freightServiceId"
                :freight-services="freightServices"
                @change="updateEvents"/>
            </v-stepper-content>

            <v-stepper-content step="3">
              <create-shipping-lead-time-step3
                :shipping-lead-time="shippingLeadTime"
                :geographical-countries="geographicalCountries"
                :places-of-loading="placesOfLoading"
                :service-providers="serviceProviders"
                :freight-services="freightServices"
                :places-of-delivery="placesOfDelivery"
                :carriers="carriers"
                :service-codes="serviceCodes"/>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <div class="mt-2">
          <base-input-primary-button v-if="stepper < 3"
                                     label="continue"
                                     icon="mdi-arrow-right-bold-circle"
                                     @click="nextStep"/>

          <base-input-primary-button v-if="stepper === 3"
                                     :label="replaceSlt ? 'Replace' : 'Update'"
                                     icon="mdi-arrow-right-bold-circle"
                                     @click="save"/>

          <base-input-secondary-button
            v-if="stepper > 1"
            label="back"
            icon="mdi-arrow-left-bold-circle"
            @click="previousStep"/>

          <base-input-secondary-button
            label="clear"
            icon="mdi-autorenew"
            @click="clear"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </div>
      </v-col>
      <v-col cols="5" class="mt-8">
        <create-shipping-lead-time-time-line
          :shipping-lead-time="shippingLeadTime"
          :geographical-countries="geographicalCountries"
          :places-of-loading="placesOfLoading"
          :service-providers="serviceProviders"
          :freight-services="freightServices"
          :places-of-delivery="placesOfDelivery"
          :carriers="carriers"/>
      </v-col>
    </v-row>
    <base-dialog-send
      :label="getDialogLabel()"
      @create="createNew"
      @copy="copy"
      @search="search"
      :send-dialog="sendDialog"/>
    <affected-logistic-lead-times-view-dialog
      :view-dialog="viewDialog"
      :view-affected-logistic-lead-times="viewAffectedLogisticLeadTimes"
      @cancel="viewDialog = false"
      @confirm="updateShippingLeadTime"/>
    <base-dialog-ok :label="warningMessage"
                    :show-icon="false"
                    :show-warning-icon="true"
                    :dialog="warningDialog"
                    @ok="() => this.warningDialog = false"/>
  </v-container>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import CreateShippingLeadTimeStepperHead from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStepperHead'
import axios from 'axios'
import CreateShippingLeadTimeTimeLine from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeTimeLine'
import UpdateShippingLeadTimeStep1 from '@/components/shippingleadtime/updateshippingleadtime/UpdateShippingLeadTimeStep1'
import CreateShippingLeadTimeStep2 from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStep2'
import CreateShippingLeadTimeStep3 from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStep3'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import AffectedLogisticLeadTimesViewDialog from '@/views/logisticsleadtime/AffectedLogisticLeadTimesViewDialog'
import { mapGetters } from 'vuex'
import { max } from 'lodash'
import BaseDialogOk from '@/base/dialog/BaseDialogOk.vue'

export default {
  name: 'UpdateShippingLeadTime',
  components: {
    BaseDialogOk,
    AffectedLogisticLeadTimesViewDialog,
    UpdateShippingLeadTimeStep1,
    BaseDialogSend,
    CreateShippingLeadTimeStep3,
    CreateShippingLeadTimeStep2,
    CreateShippingLeadTimeTimeLine,
    CreateShippingLeadTimeStepperHead,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton
  },
  data () {
    return {
      validationErrorMessages: [],
      stepper: 1,
      sendDialog: false,
      viewDialog: false,
      viewAffectedLogisticLeadTimes: [],
      oldShippingLeadTime: {},
      warningDialog: false,
      warningMessage: '',
      shippingLeadTime: {
        countryOfDeliveryId: '',
        placeOfLoadingId: '',
        placeOfDeliveryId: '',
        freightServiceId: '',
        serviceProviderId: '',
        carrierId: '',
        service: '',
        validFrom: '',
        validTo: '',
        serviceCode: '',
        shippingLeadTimeEvents: []
      },
      affectedLogisticsLeadTimeCount: 0
    }
  },
  props: {
    replaceSlt: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'geographicalCountries',
      'placesOfLoading',
      'placesOfDelivery',
      'freightServices',
      'serviceProviders',
      'carriers',
      'serviceCodes'
    ])
  },
  methods: {
    nextStep () {
      let isValid = false
      if (this.stepper === 1) {
        isValid = this.$refs.step1.validate()
      } else if (this.stepper === 2) {
        isValid = this.$refs.events.validate()
      }

      if (isValid) {
        this.stepper++
      } else {
        window.scrollTo(0, 0)
      }
    },
    previousStep () {
      this.validationErrorMessages = []
      this.stepper--
    },
    clear () {
      this.stepper = 1
      this.shippingLeadTime.carrierId = ''
      this.shippingLeadTime.service = ''
      this.shippingLeadTime.serviceCode = ''
      this.shippingLeadTime.main = false
      this.shippingLeadTime.defaultForCountryOfDelivery = false
      this.shippingLeadTime.validFrom = ''
      this.shippingLeadTime.validTo = ''
      this.shippingLeadTime.shippingLeadTimeEvents = []
      this.sendDialog = false
      this.$refs.events.clearEvents()
    },
    cancel () {
      this.$router.push({ name: 'SearchShippingLeadTime' })
    },
    updateEvents (events) {
      this.shippingLeadTime.shippingLeadTimeEvents = [...events]
    },
    save () {
      axios.get('/api/touchpoint/logistic-lead-time/shipping-lead-time/' + this.shippingLeadTime.id)
        .then(response => {
          if (response.data.length === 0) {
            this.updateShippingLeadTime()
          } else {
            if (this.shippingLeadTime.validTo) {
              const shippingEndDate = new Date(this.shippingLeadTime.validTo)
              const lltExistWithFutureStartDate = response.data.some(sllt => new Date(sllt.validFrom) > shippingEndDate)
              if (lltExistWithFutureStartDate) {
                const allValidFromDates = [...response.data.map(sllt => new Date(sllt.validFrom))]
                const maxValidConnectedSLLT = max(allValidFromDates).toISOString().split('T')[0]
                this.warningMessage = `Shipping lead time cannot end on '${this.shippingLeadTime.validTo}' as there are connected SLLT valid from '${maxValidConnectedSLLT}'`
                this.warningDialog = true
                return
              }
            }
            if (this.replaceSlt) {
              this.viewAffectedLogisticLeadTimes = response.data.filter(sllt => sllt.validTo == null)
            } else {
              this.viewAffectedLogisticLeadTimes = response.data
            }
            this.viewDialog = true
          }
        })
        .catch(e => console.log(e))
    },
    updateShippingLeadTime () {
      this.viewDialog = false

      if (!this.replaceSlt) {
        axios.put('/api/touchpoint/shipping-lead-time', this.shippingLeadTime)
          .then(response => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      } else {
        axios.put('/api/touchpoint/shipping-lead-time/replace', {
          createShippingLeadTimeCommand: this.shippingLeadTime,
          updateShippingLeadTimeCommand: this.shippingLeadTime
        })
          .then(response => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      }
    },
    createNew () {
      this.$router.push({ name: 'CreateShippingLeadTime' })
    },
    copy () {
      this.$router.push({ name: 'CreateShippingLeadTime', params: { copyShippingLeadTimeFrom: this.shippingLeadTime } })
    },
    search () {
      this.$router.push({ name: 'SearchShippingLeadTime' })
    },
    mapShippingLeadTimeResponse (data) {
      this.shippingLeadTime.id = data.id
      this.shippingLeadTime.countryOfDeliveryId = data.countryOfDelivery.id
      this.shippingLeadTime.placeOfLoadingId = data.placeOfLoading.id
      this.shippingLeadTime.placeOfDeliveryId = data.placeOfDelivery.id
      this.shippingLeadTime.freightServiceId = data.freightService.id
      this.shippingLeadTime.serviceProviderId = data.serviceProvider.id
      this.shippingLeadTime.carrierId = data.carrier && data.carrier.id
      this.shippingLeadTime.service = data.service
      this.shippingLeadTime.serviceCode = data.serviceCode && data.serviceCode.name
      this.shippingLeadTime.main = data.main
      this.shippingLeadTime.defaultForCountryOfDelivery = data.defaultForCountryOfDelivery
      this.shippingLeadTime.validFrom = data.validFrom
      this.shippingLeadTime.validTo = data.validTo

      if (this.replaceSlt) {
        Object.assign(this.oldShippingLeadTime, this.shippingLeadTime)
      }
    },
    getDialogLabel () {
      if (this.replaceSlt) {
        return 'Shipping lead time has been replaced successfully!'
      } else {
        return 'Shipping lead time has been updated successfully!'
      }
    }
  },
  async mounted () {
    axios.get('/api/touchpoint/shipping-lead-time/' + this.$route.params.id)
      .then((response) => this.mapShippingLeadTimeResponse(response.data))
      .catch(e => console.log(e))

    await axios.all([
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllPlacesOfDelivery'),
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllServiceProviders'),
      this.$store.dispatch('getAllCarriers'),
      this.$store.dispatch('getAllServiceCodes')
    ])
  }
}
</script>

<style scoped>
</style>
