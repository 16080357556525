const initState = () => {
  return {
    proposedTransitLeadTimeSearchCriteria: {
      availablePointId: '',
      freightServiceId: '',
      active: 'true'
    },
    proposedTransitLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}
const searchProposedTransitLeadTime = {
  state: initState(),
  mutations: {
    updateProposedTransitLeadTimeSearchResults (state, searchResults) {
      state.proposedTransitLeadTimeSearchResults = searchResults
    },
    resetProposedTransitLeadTimeSearchCriteria (state) {
      Object.assign(state.proposedTransitLeadTimeSearchCriteria, initState().proposedTransitLeadTimeSearchCriteria)
    }
  },
  getters: {
    proposedTransitLeadTimeSearchCriteria: state => state.proposedTransitLeadTimeSearchCriteria,
    proposedTransitLeadTimeSearchResults: state => state.proposedTransitLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchProposedTransitLeadTime
