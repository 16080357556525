<template>
  <div>
    <v-card-title class="label">
      <v-icon v-if="icon" class="mr-2">
        {{icon}}
      </v-icon>
        {{label}}
    </v-card-title>
    <v-divider class="mb-2"></v-divider>
  </div>
</template>

<script>
export default {
  name: 'BaseCardTitle',
  props: {
    label: {
      type: String,
      require: true
    },
    icon: {
      type: String,
      require: false
    }
  }
}
</script>

<style scoped>
  .label {
    text-transform: uppercase;
  }
</style>
