<template>
  <div>
    <v-autocomplete
      filled
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :label="itemLabel"
      :rules="rules"
      v-model="selectValueItem"
      :validate-on-blur=true
      @change="$emit('change', selectValueItem)"
      clearable>
      <template v-slot:item="{ item } ">
        <div v-if ="logisticLeadTimeToDisplay === 'mot'">
          <base-label-with-mode-of-transport :mode-of-transport="item"/>
        </div>
        <div v-else-if="logisticLeadTimeToDisplay === 'motCategory'">
          <base-label-with-mode-of-transport-category :mode-of-transport-category="item"/>
        </div>
        <div v-else-if="logisticLeadTimeToDisplay === 'packingMode'">
          <base-label-with-packing-mode :packing-mode="item"/>
        </div>
        <div v-else>
          {{ item[itemText] }}
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import BaseLabelWithModeOfTransport from '@/base/label/BaseLabelWithModeOfTransport.vue'
import BaseLabelWithModeOfTransportCategory from '@/base/label/BaseLabelWithModeOfTransportCategory.vue'
import BaseLabelWithPackingMode from '@/base/label/BaseLabelWithPackingMode.vue'

export default {
  name: 'BaseInputSelectWithLogisticsMasterData',
  components: {
    BaseLabelWithModeOfTransport,
    BaseLabelWithModeOfTransportCategory,
    BaseLabelWithPackingMode
  },
  props: {
    items: {
      type: Array,
      require: true
    },
    itemText: {
      type: String,
      require: true
    },
    itemValue: {
      type: String,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      type: String,
      require: false
    },
    logisticLeadTimeToDisplay: {
      type: String,
      require: true
    },
    rules: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      selectValueItem: '',
      validations: []
    }
  },
  computed: {
    selectedCountryCode () {
      const selectedItem = this.items.find(item => item[this.itemValue] === this.selectValueItem)
      if (selectedItem) {
        return this.resolve(this.itemCountry, selectedItem)
      } else {
        return null
      }
    }
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  },
  mounted () {
    this.selectValueItem = this.selectedValue
  },
  methods: {
    resolve (path, obj) {
      return path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
    },
    toggle () {}
  }
}
</script>

<style scoped>
  .v-list-item {
    min-height: 48px;
  }
  .item-text {
    margin-left: 10px;
    margin-top: 5px;
  }
</style>
