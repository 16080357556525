<template>
  <v-tooltip top color="red"
   :bottom="tooltipBottom"
   :left="tooltipLeft"
   :right="tooltipRight"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-icon v-if="icon" class="mr-2" @click="click">
          {{ icon }}
        </v-icon>
      </span>
    </template>
    <span>{{ label }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'BaseToolTip',
  props: {
    label: {
      type: String,
      require: false
    },
    icon: {
      type: String,
      require: false
    },
    tooltipBottom: {
      type: Boolean,
      require: false
    },
    tooltipLeft: {
      type: Boolean,
      require: false
    },
    tooltipRight: {
      type: Boolean,
      require: false
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>
