<template>
    <v-dialog
      v-model="viewDialog"
      scrollable
      persistent
      max-width="750px"
      @keydown.esc="viewDialog = false">
      <v-card v-if="viewDialog">
        <v-card-title class="label">
          Final Destination lead time details
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-text style="height: 900px;" class="text-body-1 text--primary">

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Location
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-flag :item-country="finalDestinationLeadTime.location.location.code"
                                    :item-label="finalDestinationLeadTime.location.description"/>
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Shipping MoTs
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <base-label-with-freight-services
                :freight-services="finalDestinationLeadTime.inboundFreightServices"/>
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Warehouses
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              <div class="pt-1 pb-1">
                <div v-for="warehouse in finalDestinationLeadTime.logicalWarehouses"  v-bind:key="warehouse.id">
                  <base-label-with-flag :item-country="warehouse.geographicalCountry.code" :item-label="warehouse.currentWarehouseCode"/>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Warehouse to allocated days
            </v-col>
            <v-col class="pt-2 pb-2 pr-0">
              {{ finalDestinationLeadTime.inWarehouseToAllocated }}
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Allocated to sellable days
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              {{ finalDestinationLeadTime.allocatedToSellable }}
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
               Quick demand deduction days
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              {{ finalDestinationLeadTime.quickDemandDeduction }}
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Total days
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" >
              {{ finalDestinationLeadTime.totalDays }}
            </v-col>
          </v-row>

          <v-divider class="mt-2 mb-2"></v-divider>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Valid from:
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" v-if="finalDestinationLeadTime.validFrom">
              <v-icon medium>mdi-clock</v-icon> {{ finalDestinationLeadTime.validFrom | formatDate }}
            </v-col>
          </v-row>

          <v-row class="mt-1 mr-0" dense>
            <v-col cols="5" class="ml-8 pt-2 pb-2">
              Valid to:
            </v-col>
            <v-col class="pt-2 pb-2 pr-0 text--black" v-if="finalDestinationLeadTime.validTo">
              <v-icon medium>mdi-clock</v-icon> {{ finalDestinationLeadTime.validTo | formatDate }}
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <base-input-primary-button v-if="canUpdate" label="Update" icon="mdi-pencil" @click="update"/>
          <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseLabelWithFreightServices from '@/base/label/BaseLabelWithFreightServices'

export default {
  name: 'FinalDestinationViewDetailsDialog',
  components: {
    BaseLabelWithFreightServices,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton,
    BaseLabelWithFlag
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    finalDestinationLeadTime: {
      type: Object,
      require: true
    },
    canUpdate: {
      type: Boolean,
      require: true,
      default: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    update () {
      this.$router.push({ path: '/FinalDestinationLeadTime/Update/' + this.finalDestinationLeadTime.id })
    }
  }
}
</script>

<style scoped>

</style>
