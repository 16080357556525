<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="dialog = false">
    <v-card v-if="dialog">
      <base-card-title label="exceptions"/>
      <v-card-text class="mt-2">
        <div class="mb-6" v-for="(exception, index) in exceptions" v-bind:key="index">

          <v-row class="mt-0 mr-0" dense>
            <v-col cols="3" class="ml-4 pt-1 pb-1">Message</v-col>
            <v-col class="pt-1 pb-1 pr-0 text--black"> {{ exception.message }}</v-col>
          </v-row>

          <v-row class="mt-0 mr-0" dense>
            <v-col cols="3" class="ml-4 pt-1 pb-1">StackTrace</v-col>
            <v-col class="pt-1 pb-1 pr-0 text--black">
              {{ exception.stackTrace[0] && exception.stackTrace[0].fileName }} :
              {{ exception.stackTrace[0] && exception.stackTrace[0].lineNumber }} </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-secondary-button label="Close" icon="mdi-close" @click="() => $emit('close')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>

import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseCardTitle from '@/base/card/BaseCardTitle'
export default {
  name: 'BaseLoadExceptionDialog',
  components: { BaseCardTitle, BaseInputSecondaryButton },
  props: {
    dialog: {
      type: Boolean,
      require: true
    },
    exceptions: {
      type: Array,
      require: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>

</style>
