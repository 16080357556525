<template>
  <v-dialog
    v-model="createDialog"
    scrollable
    persistent
    max-width="700px"
    @keydown.esc="close">
    <v-container>
      <v-form>
        <create-final-destination-lead-time
          ref="createFinalDestinationDialogRef"
          container-type="Dialog"
          :timeline-required=false
          :location-id="theLocationId"
          :proposed-lead-time-id="proposedLeadTimeId"
          @cancel="cancel"
          @close="close"
        />
      </v-form>
    </v-container>
  </v-dialog>

</template>

<script>
import CreateFinalDestinationLeadTime from '@/views/finaldestinationleadtime/CreateFinalDestinationLeadTime'

export default {
  name: 'CreateProposedFinalDestinationLeadTimeDialog',
  components: { CreateFinalDestinationLeadTime },
  props: {
    createDialog: {
      type: Boolean,
      require: true
    },
    locationId: {
      type: String,
      require: true
    },
    proposedLeadTimeId: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      theLocationId: ''
    }
  },
  watch: {
    locationId (val) {
      this.theLocationId = val
    }
  },
  methods: {
    cancel () {
      this.$refs.createFinalDestinationDialogRef.resetValidation()
      this.$emit('cancel')
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

.v-dialog.v-dialog--active .v-form  {
  background-color: #fff;
}

</style>
