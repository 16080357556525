<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search proposed haulage lead time"
        icon="mdi-truck-delivery-outline"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transportLocations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Arrival point"
              :selected-value="proposedHaulageLeadTimeSearchCriteria.arrivalPointId"
              @change="proposedHaulageLeadTimeSearchCriteria.arrivalPointId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-freight-service
              :freight-services="freightServices"
              item-label="Inbound freight service"
              :selected-value="proposedHaulageLeadTimeSearchCriteria.freightServiceId"
              @change="proposedHaulageLeadTimeSearchCriteria.freightServiceId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="proposedHaulageLeadTimeSearchCriteria.active"
              @change="setActiveOnlyFlag(arguments[0])"
              label="Only active proposed lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-buttons
      @search="search"
      @reset="reset"/>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="proposedHaulageLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="proposedHaulageLeadTimeSearchResults"
        url="/api/touchpoint/proposed-haulage-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.arrivalPoint="{ item }">
          <base-label-with-flag :item-country="item.arrivalPoint.location.code"
                                :item-label="item.arrivalPoint.description"/>
        </template>

        <template v-slot:item.freightService="{ item }">
          <base-label-with-freight-service :freight-service="item.freightService"/>
        </template>

        <template v-slot:item.active="{ item }">
          <v-switch
            v-model="item.active"
            @change="updateProposedHaulageLeadTime(item)">
          </v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="item.active"
            label="Create haulage lead time"
            icon="mdi-truck-delivery-outline"
            @click="showCreateDialog(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>
    <search-proposed-haulage-lead-time-view-details-dialog
      :view-dialog="viewDialog"
      :view-dialog-haulage-lead-time="viewDialogHaulageLeadTime"
      @close="closeViewDialog"/>
    <create-proposed-haulage-lead-time-dialog
      :create-dialog="createDialog"
      :create-dialog-haulage-lead-time="createDialogHaulageLeadTime"
      @close="closeCreateDialog"
      @cancel="cancelCreateDialog"/>
  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import SearchProposedHaulageLeadTimeViewDetailsDialog from '@/components/haulageleadtime/searchhaulageleadtime/SearchProposedHaulageLeadTimeViewDetailsDialog'
import CreateProposedHaulageLeadTimeDialog from '@/views/haulageleadtime/CreateProposedHaulageLeadTimeDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchProposedHaulageLeadTime',
  components: {
    CreateProposedHaulageLeadTimeDialog,
    SearchProposedHaulageLeadTimeViewDetailsDialog,
    BaseToolTip,
    BaseLabelWithFreightService,
    BaseLabelWithFlag,
    BaseInputSelectWithFlag,
    BaseInputSelectWithFreightService,
    BaseTablePagedServerSide,
    BaseInputSearchAndClearButtons,
    BaseCardTitle
  },
  computed: {
    ...mapGetters([
      'transportLocations',
      'freightServices',
      'proposedHaulageLeadTimeSearchCriteria'
    ])
  },
  data () {
    return {
      isLoaded: false,
      viewDialog: false,
      viewDialogHaulageLeadTime: {},
      createDialog: false,
      createDialogHaulageLeadTime: {},
      tableHeaders: [
        { text: 'Arrival point', align: 'start', sortable: true, value: 'arrivalPoint' },
        { text: 'Inbound freight service', sortable: true, value: 'freightService' },
        { text: 'Active?', sortable: false, value: 'active' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllFreightServices')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetProposedHaulageLeadTimeSearchCriteria')
    },
    setActiveOnlyFlag (val) {
      this.proposedHaulageLeadTimeSearchCriteria.active = val || null
    },
    showViewDialog (proposedHaulageLeadTime) {
      this.viewDialogHaulageLeadTime = proposedHaulageLeadTime
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    showCreateDialog (proposedHaulageLeadTime) {
      this.createDialogHaulageLeadTime = proposedHaulageLeadTime
      this.createDialog = true
    },
    closeCreateDialog () {
      this.createDialogHaulageLeadTime = {}
      this.createDialog = false
      this.search()
    },
    cancelCreateDialog () {
      this.createDialogHaulageLeadTime = {}
      this.createDialog = false
    },
    updateProposedHaulageLeadTime (proposedHaulageLeadTime) {
      axios.put('/api/touchpoint/proposed-haulage-lead-time/',
        {
          id: proposedHaulageLeadTime.id,
          active: proposedHaulageLeadTime.active
        })
        .then(response => {
          this.username = response.data
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>
</style>
