const initState = () => {
  return {
    finalDestinationLeadTimeSearchCriteria: {
      locationId: '',
      logicalWarehouseIds: [],
      inboundFreightServiceIds: [],
      active: true
    },
    finalDestinationLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchFinalDestinationLeadTime = {
  state: initState(),
  mutations: {
    updateFinalDestinationLeadTimeSearchResults (state, searchResults) {
      state.finalDestinationLeadTimeSearchResults = searchResults
    },
    resetFinalDestinationLeadTimeSearchCriteria (state) {
      Object.assign(state.finalDestinationLeadTimeSearchCriteria, initState().finalDestinationLeadTimeSearchCriteria)
    }
  },
  getters: {
    finalDestinationLeadTimeSearchCriteria: state => state.finalDestinationLeadTimeSearchCriteria,
    finalDestinationLeadTimeSearchResults: state => state.finalDestinationLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchFinalDestinationLeadTime
