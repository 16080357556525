<template>
  <div>
    <v-card>
      <v-card-title>
        {{ tableHeading }}
        <v-spacer></v-spacer>
        <v-text-field
          dense
          filled
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        dense
        :headers="tableHeaders"
        :items="items"
        :items-per-page="itemsPerPage"
        :page.sync="page"
        :search="search"
        hide-default-footer>

        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>

        <!-- Pass on all scoped slots -->
        <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
          <slot :name="slot" v-bind="scope"/>
        </template>

      </v-data-table>
      <v-divider></v-divider>
      <v-row class="ma-3" v-if="totalItems > 0">
        <p class="mb-0 mt-3 ml-2 body-2">{{ footerInfo }}</p>
        <v-spacer></v-spacer>
        <v-pagination
          elevation-0
          class="mb-3 elevation-0"
          color="red darken-2 elevation-0"
          v-model="page"
          :value="page"
          :total-visible="10"
          :length="pageCount">
        </v-pagination>
      </v-row>
      <v-row class="ma-3" v-else>
        <p class="mb-2 mt-0 ml-1 body-2">Nothing found to display</p>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { arraysUtils } from '@/mixins/utilsMixin'

export default {
  name: 'BaseTablePaged',
  mixins: [arraysUtils],
  props: {
    tableHeaders: {
      type: Array,
      require: true
    },
    items: {
      type: Array,
      require: true
    },
    tableHeading: {
      type: String,
      require: true
    }
  },
  data () {
    return {
      itemsPerPage: 50,
      page: 1,
      search: ''
    }
  },
  computed: {
    totalItems () {
      return this.items.length
    },
    footerInfo () {
      if (!this.totalItems) {
        return ''
      } else if (this.totalItems === 1) {
        return 'Showing one record'
      } else {
        return 'Showing ' + this.pageStart + ' to ' + this.pageStop + ' of ' + this.totalItems + ' records'
      }
    },
    pageCount () {
      return Math.ceil(this.items.length / this.itemsPerPage)
    },
    pageStart () {
      return this.itemsPerPage * this.page - this.itemsPerPage + 1
    },
    pageStop () {
      if (this.page === this.pageCount) {
        return this.totalItems
      } else {
        return this.itemsPerPage * this.page
      }
    }
  }
}
</script>

<style scoped>
.v-data-table >
.v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {

  padding: 0 0px 0 10px !important;

}
.v-card__title {
  font-size: 25px;
}
</style>
