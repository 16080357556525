<template>
  <v-container v-if="isLoaded">
    <v-card>
      <v-card-title>
        View transport locations
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="_headers"
        :items="transportLocations"
        :items-per-page="50"
        :search="search">

        <template v-slot:item.refresh="{ item }">
          <v-btn icon @click="refresh(item)">
            <v-icon>mdi-cached</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <base-dialog-ok
      label="Entity has been refresh!"
      :dialog="refreshDialog"
      @ok="() => refreshDialog = false"/>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import axios from 'axios'
import { security } from '@/mixins/utilsMixin'

export default {
  name: 'ViewTransportLocations',
  components: { BaseDialogOk },
  mixins: [security],
  data () {
    return {
      isLoaded: false,
      refreshDialog: false,
      search: '',
      tableHeaders: [
        { text: 'Country', align: 'start', sortable: true, value: 'location.name' },
        { text: 'Unlocode', sortable: true, value: 'unlocode' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'Place Of Loading', sortable: false, value: 'placeOfLoading' },
        { text: 'Place Of Receipt', sortable: false, value: 'placeOfReceipt' },
        { text: 'Place Of Discharge', sortable: false, value: 'placeOfDischarge' },
        { text: 'Place Of Delivery', sortable: false, value: 'placeOfDelivery' },
        { text: 'Refresh', align: 'start', sortable: false, value: 'refresh' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'transportLocations',
      'getUserRoles'
    ]),
    _headers () {
      if (!this.isTechAdmin(this.getUserRoles)) {
        return this.tableHeaders.filter(header => header.text !== 'Refresh')
      }
      return this.tableHeaders
    }
  },
  async mounted () {
    await this.$store.dispatch('getAllTransportLocations').then(() => { this.isLoaded = true })
  },
  methods: {
    refresh (item) {
      return axios.get('/api/touchpoint/transport-location/refresh/' + item.sourceSystemKey)
        .then(response => {
          const index = this.transportLocations.findIndex(tl => tl.id === response.data.id)
          this.transportLocations.splice(index, 1, response.data)
          this.refreshDialog = true
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>
