<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="1000px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">Affected logistic lead times</v-card-title>
      <v-card-text>Following logistic lead times will be updated. Are you confirm ?</v-card-text>
      <v-divider class="mb-2"></v-divider>
      <v-simple-table id="effectedLogisticLeadTimes">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Country of delivery</th>
              <th class="text-left">Place of loading</th>
              <th class="text-left">Shipping MoT</th>
              <th class="text-left">Shipping place of delivery</th>
              <th class="text-left">TSP</th>
              <th class="text-left">Final destination</th>
              <th class="text-left">Total days</th>
              <th class="text-left">Valid from</th>
              <th class="text-left">Valid to</th>
              <th class="text-left">Publish time</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in viewAffectedLogisticLeadTimes" :key="item.id">
              <td>
                <base-tool-tip2>
                  <base-label-with-flag
                      :item-country="item.shippingLeadTime.countryOfDelivery.code"
                      :item-label="item.shippingLeadTime.countryOfDelivery.code"/>
                  <template v-slot:tooltip>
                    {{ item.shippingLeadTime.countryOfDelivery.description }}
                  </template>
                </base-tool-tip2>

              </td>
               <td>
                 <base-tool-tip2>
                   <base-label-with-flag
                       :item-country="item.shippingLeadTime.placeOfLoading.location.code"
                       :item-label="item.shippingLeadTime.placeOfLoading.unlocode"/>
                   <template v-slot:tooltip>
                     {{ item.shippingLeadTime.placeOfLoading.description }}
                   </template>
                 </base-tool-tip2>
               </td>
              <td>
                <base-label-with-freight-service :freight-service="item.shippingLeadTime.freightService"/>
              </td>
              <td>
                <base-tool-tip2>
                  <base-label-with-flag
                      :item-country="item.shippingLeadTime.placeOfDelivery.location.code"
                      :item-label="item.shippingLeadTime.placeOfDelivery.unlocode"/>
                  <template v-slot:tooltip>
                    {{ item.shippingLeadTime.placeOfDelivery.description }}
                  </template>
                </base-tool-tip2>
              </td>
              <td>{{item.shippingLeadTime.serviceProvider.code}}</td>

              <td>
                <base-label-with-flag
                    :item-country="item.finalDestinationLeadTime.location.location.code"
                    :item-label="item.finalDestinationLeadTime.location.description"/>
              </td>
              <td>{{item.totalDays}}</td>
              <td>{{item.validFrom}}</td>
              <td>{{item.validTo}}</td>
              <td>{{item.publishTime | formatDate }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button label="Confirm" icon="mdi-check-bold" @click="confirm"/>
        <base-input-secondary-button label="Cancel" icon="mdi-close" @click="cancel"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseToolTip2 from '@/base/tooltip/BaseToolTip2'

export default {
  name: 'AffectedLogisticLeadTimesViewDialog',
  components: {
    BaseToolTip2,
    BaseLabelWithFreightService,
    BaseLabelWithFlag,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewAffectedLogisticLeadTimes: {
      type: Array,
      require: true
    }
  },
  methods: {
    confirm () {
      this.$emit('confirm')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>
