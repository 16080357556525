<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search transit lead time"
        icon="mdi-calendar-import"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transitWarehouses"
              item-value="id"
              item-text="description"
              item-country="geographicalCountry.code"
              item-label="Transit warehouse"
              :selected-value="transitLeadTimeSearchCriteria.transitWarehouseId"
              @change="transitLeadTimeSearchCriteria.transitWarehouseId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transportLocations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Available point"
              :selected-value="transitLeadTimeSearchCriteria.availablePointId"
              @change="transitLeadTimeSearchCriteria.availablePointId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transportLocations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Place of delivery"
              :selected-value="transitLeadTimeSearchCriteria.placeOfDeliveryId"
              @change="transitLeadTimeSearchCriteria.placeOfDeliveryId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-freight-service
              :freight-services="freightServices"
              item-label="Inbound MoT"
              :selected-value="transitLeadTimeSearchCriteria.inboundFreightServiceId"
              @change="transitLeadTimeSearchCriteria.inboundFreightServiceId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-freight-service
              :freight-services="freightServices"
              item-label="Outbound MoT"
              :selected-value="transitLeadTimeSearchCriteria.outboundFreightServiceId"
              @change="transitLeadTimeSearchCriteria.outboundFreightServiceId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="transitLeadTimeSearchCriteria.active"
              input-value="searchCriteria.active"
              @change="transitLeadTimeSearchCriteria.active = arguments[0]"
              label="Only active transit lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-and-download-buttons
      @search="search"
      @reset="reset"
      @download="download"/>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="transitLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="transitLeadTimeSearchResults"
        url="/api/touchpoint/transit-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.transitWarehouse="{ item }">
          <base-label-with-flag :item-country="item.transitWarehouse.geographicalCountry.code" :item-label="item.transitWarehouse.currentWarehouseCode"/>
        </template>
        <template v-slot:item.availablePoint="{ item }">
          <base-label-with-flag :item-country="item.availablePoint.location.code" :item-label="item.availablePoint.unlocode"/>
        </template>
        <template v-slot:item.placeOfDelivery="{ item }">
          <base-label-with-flag :item-country="item.placeOfDelivery.location.code" :item-label="item.placeOfDelivery.unlocode"/>
        </template>
        <template v-slot:item.inboundFreightService="{ item }">
          <base-label-with-freight-service :freight-service="item.inboundFreightService"/>
        </template>
        <template v-slot:item.outboundFreightService="{ item }">
          <base-label-with-freight-service :freight-service="item.outboundFreightService"/>
        </template>
        <template v-slot:item.modificationTime="{ item }">
          {{ item.modificationTime | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Edit"
            icon="mdi-pencil"
            @click="$router.push({ path: `/TransitLeadTime/Update/${item.id}` })">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Copy"
            icon="mdi-content-copy"
            @click="$router.push({ path: `/TransitLeadTime/Create/${item.id}` })">
          </base-tool-tip>
          <base-tool-tip
            v-if="!item.successor && !item.validTo && isAdmin(getUserRoles)""
            label="Replace"
            icon="mdi-file-replace-outline"
            @click="replace(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="item.eligibleForDelete && isAdmin(getUserRoles)"
            label="Delete"
            icon="mdi-delete"
            @click="deleteConfirmation(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>
    <search-transit-lead-time-view-details-dialog
      :can-update="isAdmin(getUserRoles)"
      :view-dialog="viewDialog"
      :view-dialog-transit-lead-time="viewDialogTransitLeadTime"
      @close="closeViewDialog"/>

    <base-dialog-confirm
      :confirm-dialog="deleteDialogConfirm"
      label="Do you want to delete transit lead time ?"
      @confirm="deleteTransitLeadTime()"
      @cancel="() => this.deleteDialogConfirm = false">
    </base-dialog-confirm>

    <base-dialog-ok
      label="Transit lead time deleted successfully."
      @ok="closeDeleteDialog"
      :dialog="deleteDialogOk"/>
  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import SearchTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchTransitLeadTimeViewDetailsDialog'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import { mapGetters } from 'vuex'
import { download, security } from '@/mixins/utilsMixin'
import BaseInputSearchAndClearAndDownloadButtons from '@/base/input/BaseInputSearchAndClearAndDownloadButtons'

export default {
  name: 'SearchTransitLeadTime',
  mixins: [security, download],
  components: {
    BaseInputSearchAndClearAndDownloadButtons,
    BaseLabelWithFlag,
    BaseLabelWithFreightService,
    BaseTablePagedServerSide,
    BaseInputSelectWithFreightService,
    BaseInputSelectWithFlag,
    BaseCardTitle,
    SearchTransitLeadTimeViewDetailsDialog,
    BaseToolTip,
    BaseDialogConfirm,
    BaseDialogOk
  },
  computed: {
    ...mapGetters([
      'transportLocations',
      'freightServices',
      'transitLeadTimeSearchCriteria',
      'getUserRoles'
    ])
  },
  data () {
    return {
      isLoaded: false,
      showDelete: true,
      deleteDialogConfirm: false,
      deleteDialogOk: false,
      transitLeadTimeIdToDelete: '',
      transitWarehouses: [],
      viewDialog: false,
      viewDialogTransitLeadTime: {},
      tableHeaders: [
        { text: 'Transit warehouse', align: 'start', sortable: true, value: 'transitWarehouse' },
        { text: 'Available point', sortable: true, value: 'availablePoint' },
        { text: 'Place of delivery', sortable: true, value: 'placeOfDelivery' },
        { text: 'Inbound MoT', sortable: true, value: 'inboundFreightService' },
        { text: 'Outbound MoT', sortable: true, value: 'outboundFreightService' },
        { text: 'Total days ', sortable: false, value: 'totalDays' },
        { text: 'Valid from', sortable: false, value: 'validFrom' },
        { text: 'Valid to', sortable: false, value: 'validTo' },
        { text: 'Modified on', sortable: false, value: 'modificationTime' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllFreightServices'),
      axios.get('/api/touchpoint/logical-warehouse')
        .then(response => ([...response.data])
          .filter(value => value.warehouseType === 'TR')
          .forEach(data => this.transitWarehouses.push(data)))
        .catch(e => console.log(e))
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetTransitLeadTimeSearchCriteria')
    },
    showViewDialog (transitLeadTime) {
      this.viewDialogTransitLeadTime = transitLeadTime
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    updateTransitLeadTime (transitLeadTime) {
      this.$router.push({ path: '/TransitLeadTime/Update/' + transitLeadTime.id })
    },
    replace (item) {
      this.$router.push({ path: '/TransitLeadTime/Replace/' + item.id })
    },
    deleteConfirmation (item) {
      this.transitLeadTimeIdToDelete = item.id
      this.deleteDialogConfirm = true
    },
    deleteTransitLeadTime () {
      this.deleteDialogConfirm = false
      axios.delete('/api/touchpoint/transit-lead-time/' + this.transitLeadTimeIdToDelete)
        .then(response => {
          this.$refs.table.doSearch()
          this.deleteDialogOk = true
        })
        .catch(e => console.log(e))
    },
    closeDeleteDialog () {
      this.deleteDialogOk = false
    },
    download () {
      const fileName = 'TransitLeadTimes.xlsx'
      const url = '/api/touchpoint/transit-lead-time/criteria-search/download'
      this.downloadToExcel(url, this.transitLeadTimeSearchCriteria, fileName)
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
