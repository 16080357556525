<template>
  <v-container v-if="isLoaded">

    <v-card class="mt-5">
      <base-card-title
        label="Search Shipping & Logistic lead time"
        icon="mdi-calendar-range"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Country of delivery"
              :selected-values="logisticLeadTimeSearchCriteria.countryOfDeliveryIds"
              @change="logisticLeadTimeSearchCriteria.countryOfDeliveryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-freight-service
              :grouped-freight-services="groupedFreightServices"
              :freight-services="freightServices"
              item-label="Shipping MoT"
              :selected-value="logisticLeadTimeSearchCriteria.freightServiceIds"
              @change="logisticLeadTimeSearchCriteria.freightServiceIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select
              :items="planningMarkets"
              item-value="id"
              item-text="description"
              item-label="Planning market"
              :selected-values="logisticLeadTimeSearchCriteria.planningMarketIds"
              @change="logisticLeadTimeSearchCriteria.planningMarketIds = arguments[0]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-service-providers
              :service-providers="serviceProviders"
              :selected-value="logisticLeadTimeSearchCriteria.serviceProviderId"
              @change="logisticLeadTimeSearchCriteria.serviceProviderId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placesOfLoading"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Shipping place of loading"
              :selected-value="logisticLeadTimeSearchCriteria.shippingPlaceOfLoadingId"
              @change="logisticLeadTimeSearchCriteria.shippingPlaceOfLoadingId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placesOfDischarge"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Shipping place of delivery"
              :selected-value="logisticLeadTimeSearchCriteria.shippingPlaceOfDeliveryId"
              @change="logisticLeadTimeSearchCriteria.shippingPlaceOfDeliveryId = arguments[0]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Final destination country"
              :selected-value="logisticLeadTimeSearchCriteria.finalDestinationCountryId"
              @change="logisticLeadTimeSearchCriteria.finalDestinationCountryId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Valid from"
              :selected-value="logisticLeadTimeSearchCriteria.validFrom"
              @change="logisticLeadTimeSearchCriteria.validFrom = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Publish from"
              :selected-value="logisticLeadTimeSearchCriteria.publishTimeFrom"
              @change="logisticLeadTimeSearchCriteria.publishTimeFrom = arguments[0]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transportLocations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Final destination location"
              :selected-value="logisticLeadTimeSearchCriteria.finalDestinationLocationId"
              @change="logisticLeadTimeSearchCriteria.finalDestinationLocationId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Valid to"
              :selected-value="logisticLeadTimeSearchCriteria.validTo"
              :rules="[rules.validFromDateIsBeforeToDate]"
              @change="logisticLeadTimeSearchCriteria.validTo = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Publish to"
              :selected-value="logisticLeadTimeSearchCriteria.publishTimeTo"
              :rules="[rules.publishFromDateIsBeforeToDate]"
              @change="logisticLeadTimeSearchCriteria.publishTimeTo = arguments[0]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              item-label="Published lead times"
              :items="optionsForIncludeTransitLeadTime"
              item-text="name"
              item-value="id"
              :selected-value="logisticLeadTimeSearchCriteria.published"
              @change="logisticLeadTimeSearchCriteria.published = arguments[0]"/>
          </v-col>

          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              item-label="Lead times with transit"
              :items="optionsForIncludeTransitLeadTime"
              item-text="name"
              item-value="id"
              :selected-value="logisticLeadTimeSearchCriteria.includeTransit"
              @change="logisticLeadTimeSearchCriteria.includeTransit = arguments[0]"/>
          </v-col>

          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Include published logistic lead times valid at"
              :selected-value="logisticLeadTimeSearchCriteria.validOn"
              :error-messages="totalReportErrorMessages"
              @change="setValidOnDate"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="logisticLeadTimeSearchCriteria.quickDemand"
              @change="logisticLeadTimeSearchCriteria.quickDemand = arguments[0]"
              label="Only quick demand lead times"/>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <div class="mt-2">
      <base-input-search-and-clear-buttons
        class="d-inline"
        @search="search"
        @reset="reset"/>
      <base-input-primary-button
        label="Total report"
        icon="mdi-microsoft-excel"
        class="ml-2"
        @click="downloadTotalReport"/>
    </div>

    <v-card class="mt-10 logistics-lead-time-search-results">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="logisticLeadTimeSearchCriteria"
        :table-headers="_headers"
        search-result-name="logisticLeadTimeSearchResults"
        url="/api/touchpoint/logistic-lead-time/criteria-search">

        <template v-slot:item.shippingLtFrom="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.placeOfLoading.location.code"
            :item-label="item.shippingLeadTime.placeOfLoading.description"/>
        </template>
        <template v-slot:item.shippingLtTo="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.placeOfDelivery.location.code"
            :item-label="item.shippingLeadTime.placeOfDelivery.description"/>
        </template>
        <template v-slot:item.shippingMoT="{ item }">
          <base-label-with-freight-service :freight-service="item.shippingLeadTime.freightService"/>
        </template>
        <template v-slot:item.shippingLtTo="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.placeOfDelivery.location.code"
            :item-label="item.shippingLeadTime.placeOfDelivery.description"/>
        </template>

        <template v-slot:item.finalDestination="{ item }">
          <base-label-with-flag
            :item-country="item.finalDestinationLeadTime.location.location.code"
            :item-label="item.finalDestinationLeadTime.location.description"/>
        </template>
        <template v-slot:item.warehouses="{ item }">
          <BaseWarehouseWithToolTip
              :logical-warehouses="item.finalDestinationLeadTime.logicalWarehouses"/>
        </template>
        <template v-slot:item.planningMarkets="{ item }">
          <base-planning-market-with-tool-tip
              :planning-markets="item.finalDestinationLeadTime.planningMarketsActiveOnCurrentDate"/>
        </template>
        <template v-slot:item.transitLt="{ item }">
          <a v-if="hasTransitLeadTime(item) === 'Yes'" @click="showTransitView(item)" title="View transit details">Yes</a>
          <span v-else>No</span>
        </template>
        <template v-slot:item.haulageLt="{ item }">
          <a v-if="hasHaulageLeadTime(item) === 'Yes'" @click="showHaulageView(item)" title="View haulage details">Yes</a>
          <span v-else>No</span>
        </template>
        <template v-slot:item.serviceProvider="{ item }">
          <label>{{item.shippingLeadTime.serviceProvider.code}}</label>
        </template>
        <template v-slot:item.totalDays="{ item }">
            <v-tooltip v-if="item.shippingLeadTime.hasAdditionalShippingLeadTimes" bottom color="red" >
              <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <label> {{ item.totalDays }}</label> <span><v-icon>mdi-alarm</v-icon></span>
              </span>
              </template>
              <div v-for="interval in item.additionalShippingLeadTimeIntervalsDetails" v-bind:key="interval.validFrom">
                <label>{{ interval }}</label>
              </div>
            </v-tooltip>
            <template v-else>
              <label> {{ item.totalDays }} </label>
            </template>
        </template>
        <template v-slot:item.totalWeeks="{ item }">
          <label> {{ item.totalWeeks }} </label>
        </template>
        <template v-slot:item.quickDemandDays="{ item }">
          <label> {{ item.totalDays - item.totalQuickDemandDeductionInDays }} </label>
        </template>

        <template v-slot:item.defaultForDeliveryCountry="{ item }">
          <span> {{item.shippingLeadTime.defaultForCountryOfDelivery ? 'Yes' : 'No' }} </span>
        </template>

        <template v-slot:item.publishTime="{ item }">
          <span v-if="item.publishTime"> {{ item.publishTime | formatDate }} </span>
        </template>

        <template v-slot:item.publish="{ item }" v-if="isAdmin(getUserRoles)">
          <v-checkbox v-if="!item.publishTime" :value="item.id" v-model="publish"></v-checkbox>
        </template>

        <template v-slot:header.publish="{ header}" v-if="isAdmin(getUserRoles)">
          {{ header.text }}<v-checkbox @click="publishAll" v-model="selectAll"/>
        </template>

        <template v-slot:item.validTo="{ item }">
          <div><span> {{item.validTo}} </span></div>
        </template>
        <template v-slot:item.modificationTime="{ item }">
          {{ item.modificationTime | formatDate }}
        </template>
        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Update"
            icon="mdi-pencil"
            @click="update(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="!item.publishTime && isAdmin(getUserRoles)"
            label="Delete"
            icon="mdi-delete"
            @click="deleteConfirmation(item)">
          </base-tool-tip>
          <base-tool-tip
              v-if="item.publishTime && isTechAdmin(getUserRoles)"
              label="Send Event"
              icon="mdi-send-outline"
              @click="confirmSendEvent(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>

    <base-input-primary-button
      v-if="isAdmin(getUserRoles) && publish.length > 0"
      label="Publish selected"
      icon="mdi-upload"
      @click="showPublishConfirmDialog"/>

    <search-logistic-lead-time-view-details-dialog
      :can-update="isAdmin(getUserRoles)"
      :viewDialog="viewDialog"
      :viewDialogLogisticLeadTime="viewDialogLogisticLeadTime"
      @close="closeViewDialog"/>

    <search-transit-lead-time-view-details-dialog
      :view-dialog="viewTransitDialog"
      :view-dialog-transit-lead-time="transitInContext"
      :can-update="false"
      @close="closeTransitViewDialog"/>

    <search-haulage-lead-time-view-details-dialog
      :view-dialog="viewHaulageDialog"
      :view-dialog-haulage-lead-time="haulageInContext"
      :can-update="false"
      @close="closeHaulageViewDialog"/>

    <base-dialog-confirm
      :confirm-dialog="publishDialogConfirm"
      label="Select a date for when logistic lead time should be published."
      @confirm="publishLeadTimes"
      @cancel="() => this.publishDialogConfirm = false">
      <v-form ref="publishForm">
        <v-row justify="center">
          <v-col cols="6">
          <base-input-select-date-picker
            :min-today="true"
            item-label="Publish time"
            :rules="[rules.required]"
            :validate-on-blur=true
            @change="publishTime = arguments[0]"/>
          </v-col>
        </v-row>
      </v-form>
    </base-dialog-confirm>

    <base-dialog-confirm
      :confirm-dialog="deleteDialogConfirm"
      label="Do you want to delete logistic lead time ?"
      @confirm="deleteLogisticLeadTime()"
      @cancel="() => this.deleteDialogConfirm = false">
    </base-dialog-confirm>

    <base-dialog-confirm
        :confirm-dialog="sendEventDialogConfirm"
        label="Do you want to send logistic lead time event to external systems?"
        @confirm="sendLogisticLeadTimeEvent"
        @cancel="() => this.sendEventDialogConfirm = false">
    </base-dialog-confirm>

    <base-dialog-ok
      :label="okDialogMessage"
      @ok="closeOkDialog"
      :dialog="showOkDialog"/>

  </v-container>
</template>

<script>
import axios from 'axios'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputMultiSelect from '@/base/input/BaseInputMultiSelect'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import SearchLogisticLeadTimeViewDetailsDialog from '@/components/logisticsleadtime/searchlogisticleadtime/SearchLogisticLeadTimeViewDetailsDialog'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import SearchHaulageLeadTimeViewDetailsDialog from '@/components/haulageleadtime/searchhaulageleadtime/SearchHaulageLeadTimeViewDetailsDialog'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import SearchTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchTransitLeadTimeViewDetailsDialog'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import { validateDate } from '@/validation/customvalidation'
import { arraysUtils, security } from '@/mixins/utilsMixin'
import { mapGetters } from 'vuex'
import BaseWarehouseWithToolTip from '@/base/tooltip/BaseWarehouseWithToolTip'
import BasePlanningMarketWithToolTip from '@/base/tooltip/BasePlanningMarketWithToolTip'
import BaseInputSelectServiceProviders from '@/base/input/BaseInputSelectServiceProviders'

export default {
  name: 'SearchLogisticsLeadTime',
  components: {
    BaseInputSelectServiceProviders,
    BasePlanningMarketWithToolTip,
    BaseWarehouseWithToolTip,
    SearchTransitLeadTimeViewDetailsDialog,
    BaseLabelWithFreightService,
    SearchHaulageLeadTimeViewDetailsDialog,
    BaseInputSelectDatePicker,
    BaseDialogConfirm,
    BaseDialogOk,
    BaseInputPrimaryButton,
    SearchLogisticLeadTimeViewDetailsDialog,
    BaseCardTitle,
    BaseInputMultiSelectWithFlag,
    BaseInputMultiSelectWithFreightService,
    BaseInputMultiSelect,
    BaseInputSelect,
    BaseInputSelectWithFlag,
    BaseInputSearchAndClearButtons,
    BaseTablePagedServerSide,
    BaseLabelWithFlag,
    BaseToolTip
  },
  mixins: [arraysUtils, security],
  data () {
    return {
      isLoaded: false,
      viewDialog: false,
      viewHaulageDialog: false,
      viewTransitDialog: false,
      transitInContext: undefined,
      haulageInContext: undefined,
      deleteDialogConfirm: false,
      sendEventDialogConfirm: false,
      logisticLeadTimeId: '',
      totalReportErrorMessages: [],
      viewDialogLogisticLeadTime: {},
      optionsForIncludeTransitLeadTime: [],
      selectAll: false,
      publishTime: '',
      publish: [],
      publishDialogConfirm: false,
      showOkDialog: false,
      okDialogMessage: '',
      tableHeaders: [
        { text: 'Shipping from', align: 'start', sortable: true, value: 'shippingLtFrom' },
        { text: 'Shipping to', align: 'start', sortable: true, value: 'shippingLtTo' },
        { text: 'Shipping MoT', align: 'start', sortable: true, value: 'shippingMoT' },
        { text: 'Final destination', align: 'start', sortable: true, value: 'finalDestination' },
        { text: 'Warehouses', align: 'start', sortable: false, value: 'warehouses' },
        { text: 'PM', align: 'start', sortable: false, value: 'planningMarkets' },
        { text: 'Transit', align: 'start', sortable: false, value: 'transitLt' },
        { text: 'Haulage', align: 'start', sortable: false, value: 'haulageLt' },
        { text: 'TSP', align: 'start', sortable: true, value: 'serviceProvider' },
        { text: 'Total days', align: 'start', sortable: true, value: 'totalDays' },
        { text: 'Total weeks', align: 'start', sortable: false, value: 'totalWeeks' },
        { text: 'Total days (after deduction)', align: 'start', sortable: false, value: 'quickDemandDays', width: '5%' },
        { text: 'Del Ctry default?', align: 'start', sortable: false, value: 'defaultForDeliveryCountry' },
        { text: 'Valid from', align: 'start', sortable: true, value: 'validFrom' },
        { text: 'Valid to', align: 'start', sortable: true, value: 'validTo' },
        { text: 'Publish time', align: 'start', sortable: true, value: 'publishTime' },
        { text: '', align: 'start', sortable: false, value: 'publish' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      rules: {
        required: value => !!value || 'Required.',
        validFromDateIsBeforeToDate: value => validateDate(this.logisticLeadTimeSearchCriteria.validFrom, value) || 'Valid to date cannot be before valid from date.',
        publishFromDateIsBeforeToDate: value => validateDate(this.logisticLeadTimeSearchCriteria.publishTimeFrom, value) || 'Publish to date cannot be before valid from date.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'planningMarkets',
      'serviceProviders',
      'transportLocations',
      'geographicalCountries',
      'placesOfLoading',
      'placesOfDischarge',
      'logisticLeadTimeSearchCriteria',
      'getUserRoles'
    ]),
    _headers () {
      if (!this.logisticLeadTimeSearchCriteria?.quickDemand) {
        return this.tableHeaders.filter(header => header.value !== 'quickDemandDays')
      }
      return this.tableHeaders
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllPlanningMarkets'),
      this.$store.dispatch('getAllServiceProviders'),
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllPlacesOfDischarge')
    ]).then(() => { this.isLoaded = true })

    this.optionsForIncludeTransitLeadTime = [
      { id: null, name: 'All' },
      { id: true, name: 'Include' },
      { id: false, name: 'Exclude' }
    ]
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetLogisticLeadTimeSearchCriteria')
      this.totalReportErrorMessages = []
    },
    hasTransitLeadTime (item) {
      if (item.transitLeadTime) {
        return 'Yes'
      }
      return 'No'
    },
    hasHaulageLeadTime (item) {
      if (item.haulageLeadTime) {
        return 'Yes'
      }
      return 'No'
    },
    showTransitView (item) {
      this.transitInContext = item.transitLeadTime
      this.viewTransitDialog = true
    },
    showHaulageView (item) {
      this.haulageInContext = item.haulageLeadTime
      this.viewHaulageDialog = true
    },
    showViewDialog (item) {
      this.viewDialogLogisticLeadTime = item
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    closeHaulageViewDialog () {
      this.viewHaulageDialog = false
    },
    closeTransitViewDialog () {
      this.viewTransitDialog = false
    },
    update (item) {
      this.$router.push({ path: '/LogisticsLeadTime/Update/' + item.id })
    },
    showPublishConfirmDialog () {
      if (this.$refs.publishForm) {
        this.$refs.publishForm.reset()
      }
      this.publishDialogConfirm = true
    },
    publishLeadTimes () {
      if (this.$refs.publishForm && this.$refs.publishForm.validate()) {
        axios.post('/api/touchpoint/logistic-lead-time/publish',
          {
            ids: this.publish,
            publishTime: this.publishTime
          })
          .then((response) => {
            this.publishDialogConfirm = false
            this.okDialogMessage = 'Logistic lead times has been published!'
            this.showOkDialog = true
            this.selectAll = false
          })
          .catch(e => console.log(e))
      }
    },
    deleteConfirmation (item) {
      this.logisticLeadTimeId = item.id
      this.deleteDialogConfirm = true
    },
    deleteLogisticLeadTime () {
      this.deleteDialogConfirm = false
      axios.delete('/api/touchpoint/logistic-lead-time/' + this.logisticLeadTimeId)
        .then((response) => {
          this.okDialogMessage = 'Logistic lead time deleted successfully.'
          this.showOkDialog = true
        })
        .catch(e => console.log(e))
    },
    confirmSendEvent (item) {
      this.logisticLeadTimeId = item.id
      this.sendEventDialogConfirm = true
    },
    sendLogisticLeadTimeEvent () {
      this.sendEventDialogConfirm = false
      axios.post('/api/touchpoint/logistic-lead-time/sendEvent/' + this.logisticLeadTimeId)
        .then((response) => {
          this.okDialogMessage = 'Logistic lead time event sent successfully.'
          this.showOkDialog = true
        })
        .catch(e => console.log(e))
    },
    closeOkDialog () {
      this.showOkDialog = false
      this.publish = []
      this.publishTime = ''
      this.okDialogMessage = ''
      this.search()
    },
    publishAll () {
      if (this.selectAll) {
        const items = this.$refs.table.items
        for (const item in items) {
          if (items[item].publishTime == null) {
            this.publish.push(items[item].id)
          }
        }
      } else {
        this.publish = []
      }
    },
    downloadTotalReport () {
      if (!this.logisticLeadTimeSearchCriteria.validOn) {
        this.totalReportErrorMessages.push('Please select a date')
      } else {
        const fileName = 'TotalShippingAndLogisticLeadTimeReportValidOn' + this.logisticLeadTimeSearchCriteria.validOn + '.xlsx'
        axios.post('/api/touchpoint/logistic-lead-time/download-total-report',
          this.removeEmptyArrays(this.logisticLeadTimeSearchCriteria), {
            headers: { 'Content-Disposition': 'attachment; filename=' + fileName },
            responseType: 'arraybuffer'
          }
        ).then((response) => {
          if (response.status === 204) { // no content
            this.okDialogMessage = 'No published logistic lead times found for selected criteria.'
            this.showOkDialog = true
          } else {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
          }
        })
          .catch((error) => console.log(error))
      }
    },
    setValidOnDate (event) {
      this.logisticLeadTimeSearchCriteria.validOn = event
      if (this.logisticLeadTimeSearchCriteria.validOn) {
        this.totalReportErrorMessages = []
      }
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}

.v-icon.v-icon {
  font-size: 20px !important;
  margin-right: 0px !important;
}
</style>
