<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">
          Warehouse details
      </v-card-title>

      <v-divider class="mb-2"></v-divider>

      <v-card-text style="height: 900px;" class="text-body-1 text--primary">
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Warehouse id
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ logicalWarehouse.warehouseId }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Warehouse code
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ logicalWarehouse.currentWarehouseCode }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Type
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ logicalWarehouse.warehouseDescription }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Location
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag
              v-if="logicalWarehouse.transportLocation"
              :item-country="logicalWarehouse.transportLocation.location.code"
              :item-label="logicalWarehouse.transportLocation.description"/>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Street address
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ logicalWarehouse.streetAddress }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Postal address
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ logicalWarehouse.postalAddress }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Zip code
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ logicalWarehouse.zipCode }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            State
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            {{ logicalWarehouse.state }}
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-2"></v-divider>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Planning market
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <div v-for="planningMarketEntries in logicalWarehouse.planningMarketEntries"  v-bind:key="planningMarketEntries.planningMarket.id">
              <base-label-with-flag :item-country="planningMarketEntries.planningMarket.logisticCountry.code" :item-label="planningMarketEntries.planningMarket.description"/>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mb-2 mt-2"></v-divider>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Open monday
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon v-if="logicalWarehouse.openMonday">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Open tuesday
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon v-if="logicalWarehouse.openTuesday">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Open wednesday
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon v-if="logicalWarehouse.openWednesday">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Open thursday
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon v-if="logicalWarehouse.openThursday">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Open friday
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon v-if="logicalWarehouse.openFriday">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Open saturday
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon v-if="logicalWarehouse.openSaturday">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Open sunday
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon v-if="logicalWarehouse.openSunday">mdi-checkbox-marked</v-icon>
            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
          </v-col>
        </v-row>

        <v-divider class="mb-2 mt-2"></v-divider>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Active start date:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" >
            <v-icon medium>mdi-clock</v-icon>
            {{ logicalWarehouse.activeStartDate | formatDate }}
          </v-col>
        </v-row>
        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Active end date:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <v-icon medium>mdi-clock</v-icon>
            {{ logicalWarehouse.activeEndDate | formatDate }}
          </v-col>
        </v-row>
        <v-divider class="mt-2"></v-divider>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button label="Close" icon="mdi-close" @click="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'

export default {
  name: 'LogicalWarehouseViewDetailsDialog',
  components: {
    BaseInputPrimaryButton,
    BaseLabelWithFlag
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    logicalWarehouse: {
      type: Object,
      require: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
