<template>
  <v-card min-height="150px" class="pb-1">
    <v-card-title class="label mb-1  pt-5" >
      <v-icon class="mr-2">
        mdi-calendar-clock
      </v-icon>
      Shipping lead time
    </v-card-title>
    <v-divider class="mb-6" v></v-divider>

    <v-timeline dense v-if="showTimeLine" >
      <v-timeline-item v-if="countryOfDelivery" small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Country of delivery</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <country-flag :country="countryOfDelivery.code" size='big' class="mt-1 ml-2"/>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ countryOfDelivery.name }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item small v-if="placeOfLoading" color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Place of loading</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <country-flag :country="placeOfLoading.location.code" size='big' class="mt-1 ml-2"/>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ placeOfLoading.name }} <br> {{ placeOfLoading.unlocode }}, {{ placeOfLoading.location.name }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
      <template v-if="freightService">
        <v-timeline-item v-for="(transportMode, index) in freightService.transportModes" v-bind:key="index"
                         fill-dot color="red darken-2" :icon="resolveIcon(transportMode)" class="mb-10">
          <v-card class="elevation-2 mr-5 " >
            <v-row>
              <v-col class="pt-1 pb-0">
                <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Mode of transport</span>
                <v-divider class="mt-1"></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-body-2 ml-3">
                Transporting goods with <v-icon> {{resolveIcon(transportMode)}} </v-icon> <span class="font-weight-medium"> {{ transportMode.description }} </span> by
                <span v-if="carrier"> <br> {{ carrier.name }} </span>
                <span v-if="serviceProvider"><br> {{ serviceProvider.name }} </span>
              </v-col>
            </v-row>
          </v-card>
        </v-timeline-item>
      </template>
      <v-timeline-item v-if="placeOfDelivery" small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Place of delivery</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <country-flag :country="placeOfDelivery.location.code" size='big' class="mt-1 ml-2"/>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ placeOfDelivery.name }} <br> {{ placeOfDelivery.unlocode }}, {{ placeOfDelivery.location.name }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>
    </v-timeline>
    <v-card-text v-else class="justify-center">
      <v-row class="justify-center"> Time line for shipping lead time </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'
export default {
  name: 'CreateShippingLeadTimeTimeLine',
  mixins: [transportModeIconResolverMixin],
  components: { CountryFlag },
  props: {
    shippingLeadTime: {
      type: Object,
      require: true
    },
    geographicalCountries: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    placesOfLoading: {
      type: Array,
      require: true
    },
    placesOfDelivery: {
      type: Array,
      require: true
    },
    serviceProviders: {
      type: Array,
      require: true
    },
    carriers: {
      type: Array,
      require: true
    }
  },
  computed: {
    countryOfDelivery () {
      return this.geographicalCountries.find(item => item.id === this.shippingLeadTime.countryOfDeliveryId)
    },
    placeOfLoading () {
      return this.placesOfLoading.find(item => item.id === this.shippingLeadTime.placeOfLoadingId)
    },
    placeOfDelivery () {
      return this.placesOfDelivery.find(item => item.id === this.shippingLeadTime.placeOfDeliveryId)
    },
    freightService () {
      return this.freightServices.find(item => item.id === this.shippingLeadTime.freightServiceId)
    },
    carrier () {
      return this.carriers.find(item => item.id === this.shippingLeadTime.carrierId)
    },
    serviceProvider () {
      return this.serviceProviders.find(item => item.id === this.shippingLeadTime.serviceProviderId)
    },
    showTimeLine () {
      return this.serviceProvider ||
        this.countryOfDelivery ||
        this.placeOfLoading ||
        this.placeOfDelivery ||
        this.freightService ||
        this.carrier ||
        this.serviceProvider
    }
  }
}
</script>

<style scoped>

</style>
