<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="grey lighten-2" tile depressed small v-bind="attrs" v-on="on">
        Event
        <v-icon color="orange darken-4" right>mdi-table-plus</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group @change="addEvent" v-model="selectedItem">
        <div v-for="eventType in eventTypes" v-bind:key="eventType.id">
          <v-list-item>
            {{ eventType.name }} <span class="ml-1 text-sm-caption"> ({{ eventType.eventCharacteristicDescriptions }})</span>
          </v-list-item>
        </div>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>

export default {
  name: 'CreateShippingLeadTimeStep2EventMenu',
  props: {
    groupedEventTypes: {
      type: Array,
      require: true
    },
    eventTypes: {
      type: Array,
      require: true
    },
    weekNumber: {
      type: Number,
      require: true
    },
    dayOfWeek: {
      type: Number,
      require: true
    }
  },
  data () {
    return {
      selectedItem: ''
    }
  },
  methods: {
    addEvent () {
      this.$emit('click', {
        shippingEventTypeId: this.eventTypes[this.selectedItem].id,
        weekNumber: this.weekNumber,
        dayOfWeek: this.dayOfWeek,
        name: this.eventTypes[this.selectedItem].name
      })
    }
  }
}
</script>

<style scoped>

</style>
