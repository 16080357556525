<template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="maxWidth">
    <v-card>
      <slot></slot>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button icon="mdi-check" @click="$emit('ok')" :label="okActionLabel"/>
        <base-input-secondary-button icon="mdi-cancel" label="cancel" @click="$emit('cancel')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'

export default {
  name: 'BaseDialogPlain',
  components: {
    BaseInputPrimaryButton,
    BaseInputSecondaryButton
  },
  props: {
    dialog: {
      type: Boolean,
      require: true
    },
    maxWidth: {
      type: Number,
      require: false,
      default: 750
    },
    persistent: {
      type: Boolean,
      require: false,
      default: false
    },
    okActionLabel: {
      type: String,
      require: false,
      default: 'Ok'
    }
  }
}
</script>

<style scoped>

</style>
