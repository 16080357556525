<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search proposed transit lead time"
        icon="mdi-truck-delivery-outline"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transportLocations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Available point"
              :selected-value="proposedTransitLeadTimeSearchCriteria.availablePointId"
              @change="proposedTransitLeadTimeSearchCriteria.availablePointId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-freight-service
              :freight-services="freightServices"
              item-label="Inbound freight service"
              :selected-value="proposedTransitLeadTimeSearchCriteria.freightServiceId"
              @change="proposedTransitLeadTimeSearchCriteria.freightServiceId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="proposedTransitLeadTimeSearchCriteria.active"
              @change="setActiveOnlyFlag(arguments[0])"
              label="Only active proposed lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-buttons
      @search="search"
      @reset="reset"/>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="proposedTransitLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="proposedTransitLeadTimeSearchResults"
        url="/api/touchpoint/proposed-transit-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.availablePoint="{ item }">
          <base-label-with-flag :item-country="item.availablePoint.location.code"
                                :item-label="item.availablePoint.description"/>
        </template>

        <template v-slot:item.freightService="{ item }">
          <base-label-with-freight-service :freight-service="item.freightService"/>
        </template>

        <template v-slot:item.active="{ item }">
          <v-switch
            v-model="item.active"
            @change="updateProposedTransitLeadTime(item)">
          </v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="item.active"
            label="Create transit lead time"
            icon="mdi-truck-delivery-outline"
            @click="showCreateDialog(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>
    <search-proposed-transit-lead-time-view-details-dialog
      :view-dialog="viewDialog"
      :view-dialog-transit-lead-time="viewDialogTransitLeadTime"
      @close="closeViewDialog"/>
    <create-proposed-transit-lead-time-dialog
      :create-dialog="createDialog"
      :create-dialog-transit-lead-time="createDialogTransitLeadTime"
      @close="closeCreateDialog"
      @cancel="cancelCreateDialog"/>
  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import { mapGetters } from 'vuex'
import SearchProposedTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchProposedTransitLeadTimeViewDetailsDialog'
import CreateProposedTransitLeadTimeDialog from '@/views/transitleadtime/CreateProposedTransitLeadTimeDialog'

export default {
  name: 'SearchProposedTransitLeadTime',
  components: {
    CreateProposedTransitLeadTimeDialog,
    SearchProposedTransitLeadTimeViewDetailsDialog,
    BaseToolTip,
    BaseLabelWithFreightService,
    BaseLabelWithFlag,
    BaseInputSelectWithFlag,
    BaseInputSelectWithFreightService,
    BaseTablePagedServerSide,
    BaseInputSearchAndClearButtons,
    BaseCardTitle
  },
  computed: {
    ...mapGetters([
      'transportLocations',
      'freightServices',
      'proposedTransitLeadTimeSearchCriteria'
    ])
  },
  data () {
    return {
      isLoaded: false,
      viewDialog: false,
      viewDialogTransitLeadTime: {},
      createDialog: false,
      createDialogTransitLeadTime: {},
      tableHeaders: [
        { text: 'Available point', align: 'start', sortable: true, value: 'availablePoint' },
        { text: 'Inbound freight service', sortable: true, value: 'freightService' },
        { text: 'Active?', sortable: false, value: 'active' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllFreightServices')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetProposedTransitLeadTimeSearchCriteria')
    },
    setActiveOnlyFlag (val) {
      this.proposedTransitLeadTimeSearchCriteria.active = val || null
    },
    showViewDialog (proposedTransitLeadTime) {
      this.viewDialogTransitLeadTime = proposedTransitLeadTime
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    showCreateDialog (proposedTransitLeadTime) {
      this.createDialogTransitLeadTime = proposedTransitLeadTime
      this.createDialog = true
    },
    closeCreateDialog () {
      this.createDialogTransitLeadTime = {}
      this.createDialog = false
      this.search()
    },
    cancelCreateDialog () {
      this.createDialogTransitLeadTime = {}
      this.createDialog = false
    },
    updateProposedTransitLeadTime (proposedTransitLeadTime) {
      axios.put('/api/touchpoint/proposed-transit-lead-time/',
        {
          id: proposedTransitLeadTime.id,
          active: proposedTransitLeadTime.active
        })
        .then(response => {
          this.username = response.data
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>
