<template>
  <div>
    <v-container>
      <v-card>
        <v-card-title>
          View service providers
          <v-spacer></v-spacer>
          <v-text-field
            filled
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="_headers"
          :items="serviceProviders"
          :items-per-page="50"
          :search="search">
          <template v-slot:item.refresh="{ item }">
            <v-btn icon @click="refresh(item)">
              <v-icon>mdi-cached</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
    <base-dialog-ok
      label="Entity has been refreshed!"
      :dialog="refreshDialog"
      @ok="() => refreshDialog = false"/>
  </div>
</template>

<script>
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import { mapGetters } from 'vuex'
import { security } from '@/mixins/utilsMixin'

export default {
  name: 'ViewServiceProvider',
  components: { BaseDialogOk },
  mixins: [security],
  data () {
    return {
      isLoaded: false,
      search: '',
      tableHeaders: [
        { text: 'Code', align: 'start', sortable: true, value: 'code' },
        { text: 'Name', sortable: true, value: 'name' },
        { text: 'active', sortable: true, value: 'active' },
        { text: 'Refresh', align: 'start', sortable: false, value: 'refresh' }
      ],
      refreshDialog: false
    }
  },
  computed: {
    ...mapGetters([
      'serviceProviders',
      'getUserRoles'
    ]),
    _headers () {
      if (!this.isTechAdmin(this.getUserRoles)) {
        return this.tableHeaders.filter(header => header.text !== 'Refresh')
      }
      return this.tableHeaders
    }
  },
  async mounted () {
    await this.$store.dispatch('getAllServiceProviders').then(() => { this.isLoaded = true })
  },
  methods: {
    refresh (item) {
      return axios.get('/api/touchpoint/refreshServiceProvider/' + item.sourceSystemKey)
        .then(response => {
          const index = this.serviceProviders.findIndex(sp => sp.id === response.data.id)
          this.serviceProviders.splice(index, 1, response.data)
          this.refreshDialog = true
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>

</style>
