import moment from 'moment'

export const validateDate = (fromDate, toDate) => {
  if (fromDate && toDate) {
    return moment(fromDate).isSameOrBefore(moment(toDate))
  }
  return true
}

export const validateDateIsBefore = (fromDate, toDate) => {
  if (fromDate && toDate) {
    return moment(fromDate).isBefore(moment(toDate))
  }
  return true
}
