<template>
  <v-tooltip top color="red"
   :bottom="tooltipBottom"
   :left="tooltipLeft"
   :right="tooltipRight">
      <template v-slot:activator="{ on, attrs }">
        <span
          v-bind="attrs"
          v-on="on">
          <slot></slot>
        </span>
      </template>
      <span>
        <slot name="tooltip"></slot>
      </span>
    </v-tooltip>
</template>

<script>
export default {
  name: 'BaseToolTip2',
  props: {
    tooltipBottom: {
      type: Boolean,
      require: false
    },
    tooltipLeft: {
      type: Boolean,
      require: false
    },
    tooltipRight: {
      type: Boolean,
      require: false
    }
  }
}
</script>

<style scoped>

</style>
