<template>
  <v-container>
    <v-form ref="terms-of-delivery-update-form">
      <v-row justify="center">
        <v-col class="mt-8" cols="6">
          <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
            <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
              <li>{{validationErrorMessage.message}}</li>
            </ul>
          </v-alert>
          <v-card class="mb-6">
            <base-card-title
              label="Update terms of delivery"
              icon="mdi-truck-delivery-outline"/>
            <v-row >
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-with-flag-read-only
                  item-label="Country of delivery"
                  :items="geographicalCountries"
                  item-country="code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="termsOfDelivery.countryOfDeliveryId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-2 ml-6">
                <base-input-select-with-flag-read-only
                  item-label="Destination logistics country"
                  :items="geographicalCountries"
                  item-country="code"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="termsOfDelivery.destinationLogisticCountryId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10"  class="pb-0 pt-0 ml-6">
                <v-checkbox
                  dense
                  class="mt-0"
                  v-model="termsOfDelivery.hazMat"
                  disabled
                  label="HazMat"
                  />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-read-only
                  :items="packingModes"
                  item-label="Packing mode"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="termsOfDelivery.packingModeId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-2 ml-6">
                <base-input-select-read-only
                  item-label="Mode of transport category"
                  :items="activeModeOfTransportCategories"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="termsOfDelivery.modeOfTransportCategoryId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-2 ml-6">
                <base-input-select-read-only
                  item-label="Mode of transport"
                  :items="activeModeOfTransports"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="termsOfDelivery.modeOfTransportId"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10"  class="pb-0 pt-0 ml-6">
                <base-input-select-read-only
                  item-label="Inco term"
                  :items="activeIncoTerms"
                  item-text="description"
                  item-value="id"
                  :rules="[rules.required]"
                  :selected-value="termsOfDelivery.incoTermId"
                  @change="termsOfDelivery.incoTermId = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-2 ml-6">
                <base-input-select-date-picker-read-only
                  item-label="Valid from"
                  :value="termsOfDelivery.validFrom"
                  @change="termsOfDelivery.validFrom = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-2 ml-6">
                <base-input-select-date-picker
                  item-label="Valid to"
                  :rules="[rules.fromDateIsBeforeToDate]"
                  :selected-value="termsOfDelivery.validTo"
                  @change="termsOfDelivery.validTo = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-2 ml-6">
                <base-input-select-date-picker
                  :min-today="true"
                  item-label="Publish time"
                  :selected-value="termsOfDelivery.publishTime | formatDateTimeFromInstant"
                  @change="termsOfDelivery.publishTime = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>
          <base-input-primary-button
            label="update"
            icon="mdi-arrow-right-bold-circle"
            @click="update"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </v-col>
      </v-row>
    </v-form>
    <base-dialog-send max-width="400"
      label="Terms Of Delivery has been saved successfully!"
      @create="createNew"
      :show-copy=false
      @search="search"
      :send-dialog="sendDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import BaseInputSelectDatePickerReadOnly from '@/base/input/BaseInputSelectDatePickerReadOnly'
import BaseInputSelectWithFlagReadOnly from '@/base/input/BaseInputSelectWithFlagReadOnly'
import { validateDate } from '@/validation/customvalidation'
import BaseInputSelectReadOnly from '@/base/input/BaseInputSelectReadOnly.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'UpdateTermsOfDelivery',
  components: {
    BaseDialogSend,
    BaseCardTitle,
    BaseInputSelectDatePicker,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseInputSelectDatePickerReadOnly,
    BaseInputSelectWithFlagReadOnly,
    BaseInputSelectReadOnly
  },
  data () {
    return {
      sendDialog: false,
      viewDialog: false,
      validationErrorMessages: [],
      termsOfDelivery: {
        id: '',
        countryOfDeliveryId: '',
        destinationLogisticCountryId: '',
        modeOfTransportId: '',
        modeOfTransportCategoryId: '',
        packingModeId: '',
        incoTermId: '',
        hazMat: '',
        validFrom: '',
        validTo: '',
        publishTime: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        selected: value => value.length > 0 || 'Select one of the item.',
        fromDateIsBeforeToDate: value => validateDate(this.termsOfDelivery.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserRoles',
      'geographicalCountries',
      'packingModes',
      'activeIncoTerms',
      'activeModeOfTransports',
      'activeModeOfTransportCategories'
    ])
  },
  async mounted () {
    if (this.$route.params.id != null) {
      axios.get('/api/touchpoint/terms-of-delivery/' + this.$route.params.id)
        .then((response) => this.mapTermsOfDeliveryResponse(response.data))
        .catch(e => console.log(e))
    }

    await axios.all([
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllIncoTerms'),
      this.$store.dispatch('getAllPackingModes'),
      this.$store.dispatch('getAllModeOfTransports'),
      this.$store.dispatch('getAllModeOfTransportCategories')
    ])
  },
  methods: {
    validate () {
      return this.$refs['terms-of-delivery-update-form'].validate()
    },
    mapTermsOfDeliveryResponse (data) {
      this.termsOfDelivery.id = this.$route.params.id
      this.termsOfDelivery.countryOfDeliveryId = data.countryOfDelivery.id
      this.termsOfDelivery.destinationLogisticCountryId = data.destinationLogisticsCountry.id
      this.termsOfDelivery.modeOfTransportId = data.modeOfTransport.id
      this.termsOfDelivery.modeOfTransportCategoryId = data.modeOfTransportCategory.id
      this.termsOfDelivery.packingModeId = data.packingMode.id
      this.termsOfDelivery.hazMat = data.hazMat
      this.termsOfDelivery.incoTermId = data.incoTerm.id
      this.termsOfDelivery.validFrom = data.validFrom
      this.termsOfDelivery.validTo = data.validTo
      this.termsOfDelivery.publishTime = data.publishTime
    },
    update () {
      this.viewDialog = false
      axios.put('/api/touchpoint/terms-of-delivery/update', this.termsOfDelivery)
        .then((response) => {
          if (response.data.status === 'SUCCESS') {
            this.sendDialog = true
          } else {
            this.validationErrorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },
    cancel () {
      this.$router.push({ name: 'SearchTermsOfDelivery' })
    },
    createNew () {
      this.$router.push({ name: 'CreateTermsOfDelivery' })
    },
    search () {
      this.$router.push({ name: 'SearchTermsOfDelivery' })
    }
  }
}
</script>

<style scoped>
</style>
