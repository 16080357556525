import axios from 'axios'

const initState = () => {
  return {
    transportLocations: [],
    freightServices: [],
    groupedFreightServices: [],
    serviceProviders: [],
    activeServiceProviders: [],
    placesOfLoading: [],
    geographicalCountries: [],
    placesOfDelivery: [],
    placeOfLoadingCountries: [],
    placeOfDeliveryCountries: [],
    placesOfDischarge: [],
    serviceCodes: [],
    destinationPoints: [],
    carriers: [],
    planningMarkets: [],
    locations: [],
    logicalWarehouses: [],
    corporateBrands: []
  }
}

const supportData = {
  state: initState(),
  mutations: {
    populateTransportLocations (state, transportLocations) {
      state.transportLocations = transportLocations
    },
    populateFreightServices (state, freightServices) {
      state.freightServices = freightServices
    },
    populateGroupedFreightServices (state, groupedFreightServices) {
      state.groupedFreightServices = groupedFreightServices
    },
    populateServiceProviders (state, serviceProviders) {
      state.serviceProviders = serviceProviders
    },
    populateActiveServiceProviders (state, activeServiceProviders) {
      state.activeServiceProviders = activeServiceProviders
    },
    populatePlacesOfLoading (state, placesOfLoading) {
      state.placesOfLoading = placesOfLoading
    },
    populateGeographicalCountries (state, geographicalCountries) {
      state.geographicalCountries = geographicalCountries
    },
    populatePlacesOfDelivery (state, placesOfDelivery) {
      state.placesOfDelivery = placesOfDelivery
    },
    populatePlaceOfLoadingCountries (state, placeOfLoadingCountries) {
      state.placeOfLoadingCountries = placeOfLoadingCountries
    },
    populatePlaceOfDeliveryCountries (state, placeOfDeliveryCountries) {
      state.placeOfDeliveryCountries = placeOfDeliveryCountries
    },
    populatePlacesOfDischarge (state, placesOfDischarge) {
      state.placesOfDischarge = placesOfDischarge
    },
    populateServiceCodes (state, serviceCodes) {
      state.serviceCodes = serviceCodes
    },
    populateDestinationPoints (state, destinationPoints) {
      state.destinationPoints = destinationPoints
    },
    populateCarriers (state, carriers) {
      state.carriers = carriers
    },
    populatePlanningMarkets (state, planningMarkets) {
      state.planningMarkets = planningMarkets
    },
    populateLocations (state, locations) {
      state.locations = locations
    },
    populateLogicalWarehouses (state, logicalWarehouses) {
      state.logicalWarehouses = logicalWarehouses
    },
    populateCorporateBrands (state, corporateBrands) {
      state.corporateBrands = corporateBrands
    }
  },
  getters: {
    transportLocations: state => state.transportLocations,
    freightServices: state => state.freightServices,
    groupedFreightServices: state => state.groupedFreightServices,
    serviceProviders: state => state.serviceProviders,
    activeServiceProviders: state => state.activeServiceProviders,
    placesOfLoading: state => state.placesOfLoading,
    geographicalCountries: state => state.geographicalCountries,
    placesOfDelivery: state => state.placesOfDelivery,
    placeOfLoadingCountries: state => state.placeOfLoadingCountries,
    placeOfDeliveryCountries: state => state.placeOfDeliveryCountries,
    placesOfDischarge: state => state.placesOfDischarge,
    serviceCodes: state => state.serviceCodes,
    destinationPoints: state => state.destinationPoints,
    carriers: state => state.carriers,
    planningMarkets: state => state.planningMarkets,
    locations: state => state.locations,
    logicalWarehouses: state => state.logicalWarehouses,
    corporateBrands: state => state.corporateBrands
  },

  actions: {
    getAllTransportLocations ({ commit, state }) {
      if (state.transportLocations.length === 0) {
        const transportLocations = []
        axios.get('/api/touchpoint/transportLocations')
          .then(response => {
            response.data.forEach(data => transportLocations.push(data))
          })
          .then(response => commit('populateTransportLocations', transportLocations))
          .catch(e => console.log(e))
      }
    },

    getAllFreightServices ({ commit, state }) {
      if (state.freightServices.length === 0) {
        const freightServices = []
        axios.get('/api/touchpoint/freightServices')
          .then(response => {
            response.data.forEach(data => freightServices.push(data))
          })
          .then(response => commit('populateFreightServices', freightServices))
          .catch(e => console.log(e))
      }
    },

    getAllGroupedFreightServices ({ commit, state }) {
      if (state.groupedFreightServices.length === 0) {
        const groupedFreightServices = []
        axios.get('/api/touchpoint/grouped-freight-services')
          .then(response => {
            response.data.forEach(data => groupedFreightServices.push(data))
          })
          .then(response => commit('populateGroupedFreightServices', groupedFreightServices))
          .catch(e => console.log(e))
      }
    },

    getAllServiceProviders ({ commit, state }) {
      if (state.serviceProviders.length === 0) {
        const serviceProviders = []
        axios.get('/api/touchpoint/service-providers')
          .then(response => {
            response.data.forEach(data => serviceProviders.push(data))
          })
          .then(response => commit('populateServiceProviders', serviceProviders))
          .catch(e => console.log(e))
      }
    },

    getAllActiveServiceProviders ({ commit, state }) {
      if (state.activeServiceProviders.length === 0) {
        const serviceProviders = []
        axios.get('/api/touchpoint/service-providers/active')
          .then(response => {
            response.data.forEach(data => serviceProviders.push(data))
          })
          .then(response => commit('populateActiveServiceProviders', serviceProviders))
          .catch(e => console.log(e))
      }
    },

    getAllPlacesOfLoading ({ commit, state }) {
      if (state.placesOfLoading.length === 0) {
        const placesOfLoading = []
        axios.get('/api/touchpoint/transport-location/place-of-loading')
          .then(response => {
            response.data.forEach(data => placesOfLoading.push(data))
          })
          .then(response => commit('populatePlacesOfLoading', placesOfLoading))
          .catch(e => console.log(e))
      }
    },

    getAllGeographicalCountries ({ commit, state }) {
      if (state.geographicalCountries.length === 0) {
        const geographicalCountries = []
        axios.get('/api/touchpoint/geographicalCountries')
          .then(response => {
            response.data.forEach(data => geographicalCountries.push(data))
          })
          .then(response => commit('populateGeographicalCountries', geographicalCountries))
          .catch(e => console.log(e))
      }
    },

    getAllPlacesOfDelivery ({ commit, state }) {
      if (state.placesOfDelivery.length === 0) {
        const placesOfDelivery = []
        axios.get('/api/touchpoint/transport-location/place-of-delivery')
          .then(response => {
            response.data.forEach(data => placesOfDelivery.push(data))
          })
          .then(response => commit('populatePlacesOfDelivery', placesOfDelivery))
          .catch(e => console.log(e))
      }
    },

    getPlaceOfLoadingCountries ({ commit, state }) {
      if (state.placeOfLoadingCountries.length === 0) {
        const placeOfLoadingCountries = []
        axios.get('/api/touchpoint/transport-location/place-of-loading-countries')
          .then(response => {
            response.data.forEach(data => placeOfLoadingCountries.push(data))
          })
          .then(response => commit('populatePlaceOfLoadingCountries', placeOfLoadingCountries))
          .catch(e => console.log(e))
      }
    },

    getPlaceOfDeliveryCountries ({ commit, state }) {
      if (state.placeOfDeliveryCountries.length === 0) {
        const placeOfDeliveryCountries = []
        axios.get('/api/touchpoint/transport-location/place-of-delivery-countries')
          .then(response => {
            response.data.forEach(data => placeOfDeliveryCountries.push(data))
          })
          .then(response => commit('populatePlaceOfDeliveryCountries', placeOfDeliveryCountries))
          .catch(e => console.log(e))
      }
    },

    getAllPlacesOfDischarge ({ commit, state }) {
      if (state.placesOfDischarge.length === 0) {
        const placesOfDischarge = []
        axios.get('/api/touchpoint/transport-location/place-of-discharge')
          .then(response => {
            response.data.forEach(data => placesOfDischarge.push(data))
          })
          .then(response => commit('populatePlacesOfDischarge', placesOfDischarge))
          .catch(e => console.log(e))
      }
    },

    getAllServiceCodes ({ commit, state }) {
      if (state.serviceCodes.length === 0) {
        const serviceCodes = []
        axios.get('/api/touchpoint/service-codes')
          .then(response => {
            response.data.forEach(data => serviceCodes.push(data))
          })
          .then(response => commit('populateServiceCodes', serviceCodes))
          .catch(e => console.log(e))
      }
    },

    getAllDestinationPoints ({ commit, state }) {
      if (state.destinationPoints.length === 0) {
        const destinationPoints = []
        axios.get('/api/touchpoint/transport-location/place-of-discharge-or-delivery')
          .then(response => {
            response.data.forEach(data => destinationPoints.push(data))
          })
          .then(response => commit('populateDestinationPoints', destinationPoints))
          .catch(e => console.log(e))
      }
    },

    getAllCarriers ({ commit, state }) {
      if (state.carriers.length === 0) {
        const carriers = []
        axios.get('/api/touchpoint/carriers')
          .then(response => {
            response.data.forEach(data => carriers.push(data))
          })
          .then(response => commit('populateCarriers', carriers))
          .catch(e => console.log(e))
      }
    },

    getAllPlanningMarkets ({ commit, state }) {
      if (state.planningMarkets.length === 0) {
        const planningMarkets = []
        axios.get('/api/touchpoint/planningMarkets')
          .then(response => {
            response.data.forEach(data => {
              planningMarkets.push(data)
            })
          })
          .then(response => commit('populatePlanningMarkets', planningMarkets))
          .catch(e => console.log(e))
      }
    },

    getAllCorporateBrands ({ commit, state }) {
      if (state.corporateBrands.length === 0) {
        const corporateBrands = []
        axios.get('/api/touchpoint/corporateBrands')
          .then(response => {
            response.data.forEach(data => corporateBrands.push(data))
          })
          .then(response => commit('populateCorporateBrands', corporateBrands))
          .catch(e => console.log(e))
      }
    },

    getAllLocations ({ commit, state }) {
      if (state.locations.length === 0) {
        const locations = []
        axios.get('/api/touchpoint/transport-location/place-of-discharge-or-delivery')
          .then(response => {
            response.data.forEach(data => locations.push(data))
          })
          .then(response => commit('populateLocations', locations))
          .catch(e => console.log(e))
      }
    },

    getAllLogicalWarehouses ({ commit, state }) {
      if (state.logicalWarehouses.length === 0) {
        const logicalWarehouses = []
        axios.get('/api/touchpoint/logical-warehouse')
          .then(response => {
            response.data.forEach(data => logicalWarehouses.push(data))
          })
          .then(response => commit('populateLogicalWarehouses', logicalWarehouses))
          .catch(e => console.log(e))
      }
    }
  }
}

export default supportData
