<template>
  <v-card min-height="150px" class="pb-1">
    <v-card-title>
      <v-icon class="mr-2">mdi-calendar-clock</v-icon> Summary
    </v-card-title>
    <v-divider class="mb-6" v></v-divider>

    <v-timeline dense v-if="showTimeLine">
      <v-timeline-item v-if="location" small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Location</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <span><country-flag :country="location.location.code" size='big' class="mt-1 ml-2"/></span>
            </v-col>
            <v-col class="text-body-2 ml-5 d-flex align-center">
              {{ location.description }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item v-if="selectedFreightServices.length" small color="red darken-2" class="mb-10">
        <v-card class="elevation-2 mr-5 ">
          <v-row>
            <v-col class="pt-1 pb-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Shipping MoT</span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row  v-for="freightService in selectedFreightServices" v-bind:key="freightService.id">
            <v-col class="ml-4 pa-2">
              <base-label-with-freight-service :freight-service="freightService"/>
              </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item v-if="selectedWarehouses.length" small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">Warehouse
                <template v-if="finalDestinationLeadTime.inWarehouseToAllocatedDays > 0">
                  ({{ finalDestinationLeadTime.inWarehouseToAllocatedDays }} days)
                </template>
              </span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="2">
              <v-icon color="red" x-large class="mr-2 warehouseIcon">mdi-warehouse</v-icon>
            </v-col>
            <v-col class="text-body-2 d-flex align-center">
              {{ selectedWarehouseDescription }}
            </v-col>
          </v-row>
        </v-card>
      </v-timeline-item>

      <v-timeline-item v-if="finalDestinationLeadTime.allocatedToSellableDays"  small color="red darken-2" class="mb-10" >
        <v-card class="elevation-2 mr-5 " >
          <v-row>
            <v-col class="pt-1">
              <span class=" pt-0 pb-2 pl-3 caption font-weight-medium">To selling
                <template v-if="finalDestinationLeadTime.allocatedToSellableDays > 0">
                  ({{finalDestinationLeadTime.allocatedToSellableDays}} days)
                </template>
              </span>
              <v-divider class="mt-1"></v-divider>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="4" class="ml-3">
              <v-img alt="Vuetify Logo"
                     class="shrink mr-2"
                     contain
                     src="@/assets/logo.gif"
                     transition="scale-transition"
                     width="40"/>
            </v-col>
          </v-row>
        </v-card>

      </v-timeline-item>
    </v-timeline>
    <v-card-text v-else class="justify-center">
      <v-row class="justify-center"> Timeline for final destination lead time </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
export default {
  name: 'CreateFinalDestinationLeadTimeLine',
  components: { BaseLabelWithFreightService, CountryFlag },
  props: {
    finalDestinationLeadTime: {
      type: Object,
      require: true
    },
    locations: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    warehouses: {
      type: Array,
      require: true
    }
  },
  computed: {
    location () {
      return this.locations.find(item => item.id === this.finalDestinationLeadTime.locationId)
    },
    selectedWarehouses () {
      const selectedWarehouses = []
      if (this.finalDestinationLeadTime.warehouseIds) {
        this.warehouses.forEach(item => {
          if (this.finalDestinationLeadTime.warehouseIds.includes(item.id)) {
            selectedWarehouses.push(item)
          }
        })
        return selectedWarehouses
      }
      return new Array(0)
    },
    selectedWarehouseDescription () {
      let warehouseDescription
      if (this.selectedWarehouses.length > 0) {
        warehouseDescription = this.selectedWarehouses.find(warehouse => warehouse).currentWarehouseCode
      }
      if (this.selectedWarehouses.length > 1) {
        warehouseDescription += ' (+' + (this.selectedWarehouses.length - 1) + ' others)'
      }
      return warehouseDescription
    },
    selectedFreightServices () {
      const selectedFreightServices = []
      if (this.finalDestinationLeadTime.inboundFreightServiceIds) {
        this.freightServices.forEach(item => {
          if (this.finalDestinationLeadTime.inboundFreightServiceIds.includes(item.id)) {
            selectedFreightServices.push(item)
          }
        })
        return selectedFreightServices
      }
      return []
    },
    showTimeLine () {
      return this.location ||
          this.selectedWarehouses.length ||
          this.selectedFreightServices.length ||
          this.finalDestinationLeadTime.allocatedToSellableDays
    }
  }
}
</script>

<style>
.warehouseIcon {
  margin-left: 8px;
}
</style>
