<template>
  <v-container>
    <v-form ref="transit-lead-time-create-form">
      <create-transit-or-proposed-transit-lead-time
        :transit-lead-time="transitLeadTime"
        :freight-services="freightServices"
        :transit-warehouses="transitWarehouses"
        :transport-locations="transportLocations"
        :validation-error-messages="validationErrorMessages"
        :rules="rules"
        :timeline-required="true"
        @save="save"
        @cancel="cancel"/>
    </v-form>
    <base-dialog-send
      label="Transit lead time has been saved successfully!"
      @create="createNew"
      @copy="copy"
      @search="search"
      :send-dialog="sendDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import { validateDate } from '@/validation/customvalidation'
import CreateTransitOrProposedTransitLeadTime from '@/components/transitleadtime/createtransitleadtime/CreateTransitOrProposedTransitLeadTime'

export default {
  name: 'CreateTransitLeadTime',
  components: {
    CreateTransitOrProposedTransitLeadTime,
    BaseDialogSend
  },
  data () {
    return {
      sendDialog: false,
      validationErrorMessages: [],
      transitWarehouses: [],
      transportLocations: [],
      freightServices: [],
      serviceProviders: [],
      carriers: [],
      transitLeadTime: {
        transitWarehouseId: '',
        availablePointId: '',
        placeOfDeliveryId: '',
        inboundFreightServiceId: '',
        outboundFreightServiceId: '',
        arrivalToAvailable: '',
        availableToInTransit: '',
        inTransitToDeparture: '',
        departureToPlaceOfDelivery: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.transitLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  mounted () {
    if (this.$route.params.id != null) {
      axios.get('/api/touchpoint/transit-lead-time/' + this.$route.params.id)
        .then((response) => this.mapTransitLeadTimeResponse(response.data))
        .catch(e => console.log(e))
    }
    axios.get('/api/touchpoint/logical-warehouse')
      .then(response => ([...response.data])
        .filter(value => value.warehouseType === 'TR')
        .forEach(data => this.transitWarehouses.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/transportLocations')
      .then(response => ([...response.data]).forEach(data => {
        this.transportLocations.push(data)
      }))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))
  },
  methods: {
    validate () {
      return this.$refs['transit-lead-time-create-form'].validate()
    },
    save () {
      if (this.validate()) {
        axios.post('/api/touchpoint/transit-lead-time', this.transitLeadTime)
          .then((response) => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    cancel () {
      this.$router.push({ name: 'SearchTransitLeadTime' })
    },
    createNew () {
      this.sendDialog = false
      this.transitLeadTime = {
        transitWarehouseId: '',
        availablePointId: '',
        placeOfDeliveryId: '',
        inboundFreightServiceId: '',
        outboundFreightServiceId: '',
        arrivalToAvailable: '',
        availableToInTransit: '',
        inTransitToDeparture: '',
        departureToPlaceOfDelivery: '',
        validFrom: '',
        validTo: ''
      }
    },
    copy () {
      this.sendDialog = false
    },
    search () {
      this.$router.push({ name: 'SearchTransitLeadTime' })
    },
    mapTransitLeadTimeResponse (data) {
      this.transitLeadTime.transitWarehouseId = data.transitWarehouse.id
      this.transitLeadTime.availablePointId = data.availablePoint.id
      this.transitLeadTime.placeOfDeliveryId = data.placeOfDelivery.id
      this.transitLeadTime.inboundFreightServiceId = data.inboundFreightService.id
      this.transitLeadTime.outboundFreightServiceId = data.outboundFreightService.id
      this.transitLeadTime.arrivalToAvailable = data.arrivalToAvailable
      this.transitLeadTime.availableToInTransit = data.availableToInTransit
      this.transitLeadTime.inTransitToDeparture = data.inTransitToDeparture
      this.transitLeadTime.departureToPlaceOfDelivery = data.departureToPlaceOfDelivery
      this.transitLeadTime.validFrom = data.validFrom
      this.transitLeadTime.validTo = data.validTo
    }
  }
}
</script>

<style scoped>

</style>
