<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search proposed final destination lead time"
        icon="mdi-truck-delivery-outline"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="destinationPoints"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Location"
              :selected-value="proposedFinalDestinationLeadTimeSearchCriteria.locationId"
              @change="proposedFinalDestinationLeadTimeSearchCriteria.locationId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="proposedFinalDestinationLeadTimeSearchCriteria.active"
              @change="setActiveOnlyFlag(arguments[0])"
              label="Only active proposed lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-buttons
      @search="search"
      @reset="reset"/>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="proposedFinalDestinationLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="proposedFinalDestinationLeadTimeSearchResults"
        url="/api/touchpoint/proposed-final-destination-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.location="{ item }">
          <base-label-with-flag :item-country="item.location.location.code"
                                :item-label="item.location.description"/>
        </template>

        <template v-slot:item.active="{ item }">
          <v-switch
            v-model="item.active"
            @change="updateProposedFinalDestinationLeadTime(item)">
          </v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            v-if="item.active"
            label="Create final destination lead time"
            icon="mdi-truck-delivery-outline"
            @click="showCreateDialog(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>
    <create-proposed-final-destination-lead-time-dialog
      ref="createDialogRef"
      :create-dialog="createDialog"
      :location-id="createDialogLocationId"
      :proposed-lead-time-id="proposedLeadTimeId"
      @close="closeCreateDialog"
      @cancel="cancelCreateDialog"/>
  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputSearchAndClearButtons from '@/base/input/BaseInputSearchAndClearButtons'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import CreateProposedFinalDestinationLeadTimeDialog from '@/views/finaldestinationleadtime/CreateProposedFinalDestinationLeadTimeDialog'
import { mapGetters } from 'vuex'

export default {
  name: 'SearchProposedFinalDestinationLeadTime',
  components: {
    CreateProposedFinalDestinationLeadTimeDialog,
    BaseToolTip,
    BaseLabelWithFlag,
    BaseInputSelectWithFlag,
    BaseTablePagedServerSide,
    BaseInputSearchAndClearButtons,
    BaseCardTitle
  },
  computed: {
    ...mapGetters([
      'destinationPoints',
      'proposedFinalDestinationLeadTimeSearchCriteria'
    ])
  },
  data () {
    return {
      isLoaded: false,
      createDialog: false,
      createDialogLocationId: '',
      proposedLeadTimeId: '',
      tableHeaders: [
        { text: 'Location', align: 'start', sortable: true, value: 'location' },
        { text: 'Active?', sortable: false, value: 'active' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('getAllDestinationPoints').then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetProposedFinalDestinationLeadTimeSearchCriteria')
    },
    setActiveOnlyFlag (val) {
      this.proposedFinalDestinationLeadTimeSearchCriteria.active = val || null
    },
    showCreateDialog (proposedFinalDestinationLeadTime) {
      this.createDialogLocationId = proposedFinalDestinationLeadTime.location.id
      this.proposedLeadTimeId = proposedFinalDestinationLeadTime.id
      this.createDialog = true
    },
    closeCreateDialog () {
      this.createDialogLocationId = ''
      this.createDialog = false
      this.search()
    },
    cancelCreateDialog () {
      this.createDialogLocationId = ''
      this.createDialog = false
    },
    updateProposedFinalDestinationLeadTime (proposedFinalDestinationLeadTime) {
      axios.put('/api/touchpoint/proposed-final-destination-lead-time/',
        {
          id: proposedFinalDestinationLeadTime.id,
          active: proposedFinalDestinationLeadTime.active
        })
        .catch(e => console.log(e))
    }
  }
}
</script>

<style scoped>
</style>
