const initState = () => {
  return {
    proposedHaulageLeadTimeSearchCriteria: {
      arrivalPointId: '',
      freightServiceId: '',
      active: 'true'
    },
    proposedHaulageLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchProposedHaulageLeadTime = {
  state: initState(),
  mutations: {
    updateProposedHaulageLeadTimeSearchResults (state, searchResults) {
      state.proposedHaulageLeadTimeSearchResults = searchResults
    },
    resetProposedHaulageLeadTimeSearchCriteria (state) {
      Object.assign(state.proposedHaulageLeadTimeSearchCriteria, initState().proposedHaulageLeadTimeSearchCriteria)
    }
  },
  getters: {
    proposedHaulageLeadTimeSearchCriteria: state => state.proposedHaulageLeadTimeSearchCriteria,
    proposedHaulageLeadTimeSearchResults: state => state.proposedHaulageLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchProposedHaulageLeadTime
