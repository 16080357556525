import moment from 'moment/moment'
const initState = () => {
  return {
    logisticLeadTimeSearchCriteria: {
      countryOfDeliveryIds: [],
      freightServiceIds: [],
      planningMarketIds: [],
      serviceProviderId: '',
      shippingPlaceOfLoadingId: '',
      shippingPlaceOfDeliveryId: '',
      finalDestinationLocationId: '',
      finalDestinationCountryId: '',

      validOn: moment().format('YYYY-MM-DD'),
      published: null,
      includeTransit: null,
      quickDemand: null
    },
    logisticLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    },
    bopoLogisticLeadTimeSearchCriteria: {
      countryOfDeliveryIds: [],
      shippingPlaceOfLoadingId: '',
      planningMarketIds: [],
      corporateBrandIds: [],
      freightServiceIds: [],
      channelSdsId: '',
      active: 'true',
      finalDestinationLocationId: '',
      finalDestinationCountryId: '',
      quickDemand: null
    },
    bopoLogisticLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchLogisticLeadTime = {
  state: initState(),
  mutations: {
    updateLogisticLeadTimeSearchResults (state, searchResults) {
      state.logisticLeadTimeSearchResults = searchResults
    },
    resetLogisticLeadTimeSearchCriteria (state) {
      Object.assign(state.logisticLeadTimeSearchCriteria, initState().logisticLeadTimeSearchCriteria)
    },
    updateBopoLogisticLeadTimeSearchResults (state, searchResults) {
      state.bopoLogisticLeadTimeSearchResults = searchResults
    },
    resetBopoLogisticLeadTimeSearchCriteria (state) {
      Object.assign(state.bopoLogisticLeadTimeSearchCriteria, initState().bopoLogisticLeadTimeSearchCriteria)
    }
  },
  getters: {
    logisticLeadTimeSearchCriteria: state => state.logisticLeadTimeSearchCriteria,
    logisticLeadTimeSearchResults: state => state.logisticLeadTimeSearchResults,
    bopoLogisticLeadTimeSearchCriteria: state => state.bopoLogisticLeadTimeSearchCriteria,
    bopoLogisticLeadTimeSearchResults: state => state.bopoLogisticLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchLogisticLeadTime
