<template>
  <v-dialog
    v-model="createDialog"
    scrollable
    persistent
    max-width="700px"
    @keydown.esc="close">
    <v-container>
      <v-form ref="haulage-lead-time-create-form">
        <create-haulage-or-proposed-haulage-leadtime
          :haulage-lead-time="haulageLeadTimeCopy"
          :transport-locations="transportLocations"
          :destination-points="destinationPoints"
          :freight-services="freightServices"
          :grouped-freight-services="groupedFreightServices"
          :rules="rules"
          :timelineRequired="false"
          @save="save"
          @cancel="cancel"/>
      </v-form>
      <base-dialog-ok
        label="Haulage lead time has been created successfully!"
        :max-width="550"
        :persistent="true"
        @ok="close"
        :dialog="confirmationDialog"/>
    </v-container>
  </v-dialog>

</template>

<script>
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import CreateHaulageOrProposedHaulageLeadtime from '@/components/haulageleadtime/createhaulageleadtime/CreateHaulageOrProposedHaulageLeadtime'
import { validateDate } from '@/validation/customvalidation'

export default {
  name: 'CreateProposedHaulageLeadTimeDialog',
  components: { CreateHaulageOrProposedHaulageLeadtime, BaseDialogOk },
  props: {
    createDialog: {
      type: Boolean,
      require: true
    },
    createDialogHaulageLeadTime: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      haulageLeadTimeCopy: {
        arrivalPointId: '',
        destinationPointId: '',
        freightServiceIds: [],
        freightServiceId: '',
        arrivalToAvailable: '',
        availableToAtDestination: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      },
      sendDialog: false,
      transportLocations: [],
      destinationPoints: [],
      freightServices: [],
      groupedFreightServices: [],
      serviceProviders: [],
      carriers: [],
      confirmationDialog: false,
      rules: {
        required: value => !!value || 'Required.',
        selected: value => value.length > 0 || 'Select one of the item.',
        fromDateIsBeforeToDate: value => validateDate(this.haulageLeadTimeCopy.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  mounted () {
    axios.get('/api/touchpoint/transportLocations')
      .then(response => ([...response.data]).forEach(data => {
        this.transportLocations.push(data)
      }))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/transport-location/place-of-discharge-or-delivery')
      .then(response => ([...response.data]).forEach(data => this.destinationPoints.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/grouped-freight-services')
      .then(response => ([...response.data]).forEach(data => this.groupedFreightServices.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))
  },
  watch: {
    createDialog (val) {
      if (val && Object.keys(this.createDialogHaulageLeadTime).length > 0) {
        this.haulageLeadTimeCopy.arrivalPointId = this.createDialogHaulageLeadTime.arrivalPoint.id
        this.haulageLeadTimeCopy.freightServiceIds.push(this.createDialogHaulageLeadTime.freightService.id)
      }
    }
  },
  methods: {
    validate () {
      return this.$refs['haulage-lead-time-create-form'].validate()
    },
    save () {
      if (this.validate()) {
        axios.post('/api/touchpoint/create-from-proposed-haulage-lead-time',
          {
            haulageLeadTimeCommand: this.haulageLeadTimeCopy,
            proposedHaulageId: this.createDialogHaulageLeadTime.id
          })
          .then(() => {
            this.confirmationDialog = true
            this.haulageLeadTimeCopy = {}
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    close () {
      this.reset()
      this.$refs['haulage-lead-time-create-form'].reset()
      this.$emit('close')
    },
    cancel () {
      this.reset()
      this.$refs['haulage-lead-time-create-form'].reset()
      this.$emit('cancel')
    },
    reset () {
      this.confirmationDialog = false
      this.haulageLeadTimeCopy = {
        arrivalPointId: '',
        destinationPointId: '',
        freightServiceIds: [],
        freightServiceId: '',
        arrivalToAvailable: '',
        availableToAtDestination: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
