<template>
  <UpdateHaulageLeadTime :replace-hlt=true></UpdateHaulageLeadTime>
</template>

<script>
import UpdateHaulageLeadTime from '@/views/haulageleadtime/UpdateHaulageLeadTime'

export default {
  name: 'ReplaceHaulageLeadTime',
  components: {
    UpdateHaulageLeadTime
  }
}
</script>

<style scoped>

</style>
