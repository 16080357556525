<template>
  <v-container>
      <v-row>
        <v-col class="mt-8">
          <v-card class="mb-6">
            <base-card-title
              label="Corporate Brand BaseLoad"
              icon="mdi-calendar-export"/>
            <div class="mb-3 mt-4 ml-4">No criteria exists currently. The baseload will be performed for all Corporate brands.</div>
          </v-card>

          <base-input-primary-button
            label="Start BaseLoad"
            icon="mdi-arrow-right-bold-circle"
            @click="start" />
        </v-col>

        <v-col cols="5" class="mt-8">
          <base-load-status
            :base-load-statuses="baseLoadStatuses"
            @baseLoadUpdate="baseLoadStatusRefresh"
            complete-url="/api/touchpoint/corporate-brands/base-loads/complete"
            cancel-url="/api/touchpoint/corporate-brands/base-loads/cancel"/>
        </v-col>

        <base-dialog-ok
          :dialog="dialogStartBaseLoad"
          label="Base load has been submitted"
          @ok="() => this.dialogStartBaseLoad = false"/>

      </v-row>
  </v-container>
</template>

<script>

import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseLoadStatus from '@/components/baseload/BaseLoadStatus'
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'

export default {
  name: 'CorporateBrandBaseLoad',
  components: {
    BaseDialogOk,
    BaseLoadStatus,
    BaseInputPrimaryButton,
    BaseCardTitle
  },
  data () {
    return {
      search: {},
      baseLoadStatuses: {},
      dialogStartBaseLoad: false,
      baseLoadRefreshRequest: {}
    }
  },
  mounted () {
    this.updateBaseLoadStatus()
    this.baseLoadRefreshRequest = setInterval(this.updateBaseLoadStatus, 5000)
  },
  beforeDestroy () {
    clearInterval(this.baseLoadRefreshRequest)
  },
  methods: {
    start () {
      axios.post('/api/touchpoint/corporate-brands/base-loads/run', this.search)
        .then((response) => {
          this.updateBaseLoadStatus()
          this.dialogStartBaseLoad = true
        })
        .catch(e => console.log(e))
    },
    updateBaseLoadStatus () {
      axios.get('/api/touchpoint/corporate-brands/base-loads/statuses', { headers: { silent: 'silent' } })
        .then((response) => { this.baseLoadStatuses = response.data })
        .catch(e => console.log(e))
    },
    baseLoadStatusRefresh (baseLoads) {
      this.baseLoadStatuses = baseLoads
    }
  }
}
</script>

<style scoped>

</style>
