<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">
        Transit lead time details
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text style="height: 900px;" class="text-body-1 text--primary">

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Transit warehouse
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" >
            <base-label-with-flag
              v-if="viewDialogTransitLeadTime.transitWarehouse"
              :item-country="viewDialogTransitLeadTime.transitWarehouse.geographicalCountry.code"
              :item-label="viewDialogTransitLeadTime.transitWarehouse.description"/>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Available point
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text&#45;&#45;black" >
            <base-label-with-flag
              v-if="viewDialogTransitLeadTime"
              :item-country="viewDialogTransitLeadTime.availablePoint.location.code"
              :item-label="viewDialogTransitLeadTime.availablePoint.description"/>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Place of delivery
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text&#45;&#45;black" >
            <base-label-with-flag
              v-if="viewDialogTransitLeadTime.placeOfDelivery"
              :item-country="viewDialogTransitLeadTime.placeOfDelivery.location.code"
              :item-label="viewDialogTransitLeadTime.placeOfDelivery.description"/>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Inbound freight service
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freight-service="viewDialogTransitLeadTime.inboundFreightService"/>
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Outbound freight service
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freight-service="viewDialogTransitLeadTime.outboundFreightService"/>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Arrival to available (days)
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTransitLeadTime.arrivalToAvailable }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Available to in transit (days)
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTransitLeadTime.availableToInTransit }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            In transit to departure (days)
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTransitLeadTime.inTransitToDeparture }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Departure to place of delivery (days)
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTransitLeadTime.departureToPlaceOfDelivery }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            <b>Total days</b>
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            {{ viewDialogTransitLeadTime.totalDays }}
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid from:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text&#45;&#45;black" v-if="viewDialogTransitLeadTime.validFrom">
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogTransitLeadTime.validFrom | formatDate }}
          </v-col>
        </v-row>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid to:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text&#45;&#45;black" v-if="viewDialogTransitLeadTime.validTo">
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogTransitLeadTime.validTo | formatDate }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button v-if="canUpdate" label="Update" icon="mdi-pencil" @click="update"/>
        <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'

export default {
  name: 'SearchTransitLeadTimeViewDetailsDialog',
  components: {
    BaseLabelWithFreightService,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton,
    BaseLabelWithFlag
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogTransitLeadTime: {
      type: Object,
      require: true
    },
    canUpdate: {
      type: Boolean,
      require: true,
      default: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    update () {
      this.$router.push({ path: '/TransitLeadTime/Update/' + this.viewDialogTransitLeadTime.id })
    }
  }
}
</script>

<style scoped>

</style>
