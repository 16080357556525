<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search haulage lead time"
        icon="mdi-truck-delivery-outline"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
          <base-input-select-with-flag
            :items="transportLocations"
            item-value="id"
            item-text="description"
            item-country="location.code"
            item-label="Arrival point"
            :selected-value="haulageLeadTimeSearchCriteria.arrivalPointId"
            @change="haulageLeadTimeSearchCriteria.arrivalPointId = arguments[0]"/>
        </v-col>
          <v-col md="4" class="pb-0 pt-0">
          <base-input-select-with-flag
            :items="destinationPoints"
            item-value="id"
            item-text="description"
            item-country="location.code"
            item-label="Destination point"
            :selected-value="haulageLeadTimeSearchCriteria.destinationPointId"
            @change="haulageLeadTimeSearchCriteria.destinationPointId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-freight-service
              :grouped-freight-services="groupedFreightServices"
              item-label="Inbound freight service"
              :freight-services="freightServices"
              :selected-value="haulageLeadTimeSearchCriteria.inboundFreightServiceIds"
              @change="haulageLeadTimeSearchCriteria.inboundFreightServiceIds = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-freight-service
                :grouped-freight-services="groupedFreightServices"
                item-label="Haulage mode of transport"
                :freight-services="freightServices"
                :selected-value="haulageLeadTimeSearchCriteria.freightServiceIds"
                @change="haulageLeadTimeSearchCriteria.freightServiceIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="haulageLeadTimeSearchCriteria.active"
              input-value="searchCriteria.active"
              @change="haulageLeadTimeSearchCriteria.active = arguments[0]"
              label="Only active haulage lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-and-download-buttons
      @search="search"
      @reset="reset"
      @download="download"/>

    <v-card class="mt-10">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="haulageLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="haulageLeadTimeSearchResults"
        url="/api/touchpoint/haulage-lead-time/criteria-search">

        <!-- slots -->
        <template v-slot:item.arrivalPoint="{ item }">
          <base-label-with-flag :item-country="item.arrivalPoint.location.code" :item-label="item.arrivalPoint.description"/>
        </template>
        <template v-slot:item.destinationPoint="{ item }">
          <base-label-with-flag :item-country="item.destinationPoint.location.code" :item-label="item.destinationPoint.description"/>
        </template>
        <template v-slot:item.inboundFreightServices="{ item }">
          <BaseFreightServiceWithTooltip
            :inbound-freight-services="item.inboundFreightServices"/>
        </template>
        <template v-slot:item.freightService="{ item }">
          <base-label-with-freight-service :freight-service="item.freightService"/>
        </template>
        <template v-slot:item.modificationTime="{ item }">
          {{ item.modificationTime | formatDate }}
        </template>

        <template v-slot:item.actions="{ item }">
          <base-tool-tip
              label="View detail"
              icon="mdi-magnify"
              @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
              v-if="isAdmin(getUserRoles)"
              label="Update"
              icon="mdi-pencil"
              @click="goToUpdateHaulageLeadTime(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Copy"
            icon="mdi-content-copy"
            @click="copyHaulageLeadTime(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="!item.successor && !item.validTo && isAdmin(getUserRoles)"
            label="Replace"
            icon="mdi-file-replace-outline"
            @click="replace(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="item.eligibleForDeletion && isAdmin(getUserRoles)"
            label="Delete"
            icon="mdi-delete"
            @click="deleteConfirmation(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>

    <base-dialog-confirm
      :confirm-dialog="deleteDialogConfirm"
      label="Do you want to delete the haulage lead time ?"
      @confirm="deleteHaulageLeadTime()"
      @cancel="() => this.deleteDialogConfirm = false">
    </base-dialog-confirm>

    <search-haulage-lead-time-view-details-dialog
      :can-update="isAdmin(getUserRoles)"
      :view-dialog="viewDialog"
      :view-dialog-haulage-lead-time="viewDialogHaulageLeadTime"
      @close="closeViewDialog"/>

    <base-dialog-ok
      :label="okDialogMessage"
      @ok="closeOkDialog"
      :dialog="showOkDialog"/>

  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import SearchHaulageLeadTimeViewDetailsDialog from '@/components/haulageleadtime/searchhaulageleadtime/SearchHaulageLeadTimeViewDetailsDialog'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import BaseFreightServiceWithTooltip from '@/base/tooltip/BaseFreightServiceWithTooltip'
import { mapGetters } from 'vuex'
import { security, download } from '@/mixins/utilsMixin'
import BaseInputSearchAndClearAndDownloadButtons from '@/base/input/BaseInputSearchAndClearAndDownloadButtons'

export default {
  name: 'SearchHaulageLeadTime',
  mixins: [security, download],
  components: {
    BaseInputSearchAndClearAndDownloadButtons,
    SearchHaulageLeadTimeViewDetailsDialog,
    BaseInputSelectWithFlag,
    BaseInputMultiSelectWithFreightService,
    BaseTablePagedServerSide,
    BaseCardTitle,
    BaseLabelWithFlag,
    BaseLabelWithFreightService,
    BaseToolTip,
    BaseDialogConfirm,
    BaseDialogOk,
    BaseFreightServiceWithTooltip
  },
  data () {
    return {
      isLoaded: false,
      deleteDialogConfirm: false,
      haulageLeadTimeId: '',
      okDialogMessage: '',
      showOkDialog: false,
      viewDialog: false,
      viewDialogHaulageLeadTime: {},
      tableHeaders: [
        { text: 'Arrival point', align: 'start', sortable: true, value: 'arrivalPoint' },
        { text: 'Destination point', sortable: true, value: 'destinationPoint' },
        { text: 'Inbound freight services', sortable: false, value: 'inboundFreightServices' },
        { text: 'Haulage MoT', sortable: true, value: 'freightService' },
        { text: 'Total days ', sortable: false, value: 'totalDays' },
        { text: 'Valid from', sortable: false, value: 'validFrom' },
        { text: 'Valid to', sortable: false, value: 'validTo' },
        { text: 'Quick demand deduction', sortable: false, value: 'quickDemandDeduction' },
        { text: 'Modified on', sortable: false, value: 'modificationTime' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'transportLocations',
      'destinationPoints',
      'freightServices',
      'groupedFreightServices',
      'carriers',
      'haulageLeadTimeSearchCriteria',
      'getUserRoles'
    ])
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllDestinationPoints'),
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllCarriers')
    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetHaulageLeadTimeSearchCriteria')
    },
    showViewDialog (haulageLeadTime) {
      this.viewDialogHaulageLeadTime = haulageLeadTime
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    goToUpdateHaulageLeadTime (haulageLeadTime) {
      this.$router.push({ path: '/HaulageLeadTime/Update/' + haulageLeadTime.id })
    },
    replace (item) {
      this.$router.push({ path: '/HaulageLeadTime/Replace/' + item.id })
    },
    copyHaulageLeadTime (item) {
      this.$router.push({ path: '/HaulageLeadTime/Create/' + item.id })
    },
    deleteConfirmation (item) {
      this.haulageLeadTimeId = item.id
      this.deleteDialogConfirm = true
    },
    deleteHaulageLeadTime () {
      this.deleteDialogConfirm = false
      axios.delete('/api/touchpoint/haulage-lead-time/' + this.haulageLeadTimeId)
        .then((response) => {
          if (response.status === 204) {
            this.okDialogMessage = 'Haulage lead time deleted successfully.'
            this.showOkDialog = true
          }
        })
        .catch(e => console.log(e))
    },
    closeOkDialog () {
      this.showOkDialog = false
      this.okDialogMessage = ''
      this.search()
    },
    download () {
      const fileName = 'HaulageLeadTimes.xlsx'
      const url = '/api/touchpoint/haulage-lead-time/criteria-search/download'
      this.downloadToExcel(url, this.haulageLeadTimeSearchCriteria, fileName)
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
