<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on" >
        <span v-for="freightService in freightServices.slice(0, 1)"  v-bind:key="freightService.id">
              <span v-for="(transportMode, index) in freightService.transportModes" v-bind:key="index">
                <v-icon class="mr-1">{{ resolveIcon(transportMode) }}</v-icon>
              </span>
            <span> {{freightService.name}} {{ hasMore() }} </span>
        </span>
      </span>
    </template>
    <span>
      <div v-for="freightService in freightServices"  v-bind:key="freightService.id">
        <base-label-with-freight-service :freight-service="freightService"/>
      </div>
    </span>
  </v-tooltip>
</template>

<script>
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import { orderBy } from 'lodash'
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'

export default {
  name: 'BaseFreightServiceWithTooltip',
  mixins: [transportModeIconResolverMixin],
  components: {
    BaseLabelWithFreightService
  },
  data () {
    return {
      sortedFreightService: []
    }
  },
  props: {
    inboundFreightServices: {
      type: Array,
      require: true
    }
  },
  methods: {
    hasMore () {
      if (this.inboundFreightServices.length > 1) {
        return '(+' + (this.inboundFreightServices.length - 1) + ' others)'
      }
      return ''
    }
  },
  computed: {
    freightServices () {
      return orderBy(this.inboundFreightServices, 'name')
    }
  }
}
</script>

<style scoped>

</style>
