<template>
    <v-stepper-header class="elevation-0">
      <v-stepper-step
          :color="stepper > 1 ? 'green': 'red darken-2'"
          :complete="stepper > 1"
          step="1">
        Shipping
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          :color="stepper > 2 ? 'green': 'red darken-2'"
          :complete="stepper > 2"
          step="2">
        Events
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step
          step="3"
          color="red darken-2">
        Confirmation
      </v-stepper-step>
    </v-stepper-header>
</template>

<script>
export default {
  name: 'CreateShippingLeadTimeStepperHead',
  props: {
    stepper: {
      type: Number,
      require: true
    }
  }
}
</script>

<style scoped>

</style>
