<template>
  <v-container v-if="isLoaded">

    <v-card class="mt-5">
      <base-card-title
        label="Search BO & PO Shipping & Logistic lead time"
        icon="mdi-calendar-range"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Country of delivery"
              :selected-values="bopoLogisticLeadTimeSearchCriteria.countryOfDeliveryIds"
              @change="bopoLogisticLeadTimeSearchCriteria.countryOfDeliveryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placesOfLoading"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Shipping place of loading"
              :selected-value="bopoLogisticLeadTimeSearchCriteria.shippingPlaceOfLoadingId"
              @change="bopoLogisticLeadTimeSearchCriteria.shippingPlaceOfLoadingId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select
              :items="planningMarkets"
              item-value="id"
              item-text="shortNameDescription"
              item-label="Planning market"
              :selected-values="bopoLogisticLeadTimeSearchCriteria.planningMarketIds"
              @change="bopoLogisticLeadTimeSearchCriteria.planningMarketIds = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-freight-service
              :grouped-freight-services="groupedFreightServices"
              :freight-services="freightServices"
              item-label="Shipping MoT"
              :selected-value="bopoLogisticLeadTimeSearchCriteria.freightServiceIds"
              @change="bopoLogisticLeadTimeSearchCriteria.freightServiceIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transportLocations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Final destination location"
              :selected-value="bopoLogisticLeadTimeSearchCriteria.finalDestinationLocationId"
              @change="bopoLogisticLeadTimeSearchCriteria.finalDestinationLocationId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              :items="channels"
              item-value="sdsId"
              item-text="name"
              item-label="Channel"
              :selected-value="bopoLogisticLeadTimeSearchCriteria.channelSdsId"
              @change="bopoLogisticLeadTimeSearchCriteria.channelSdsId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select
              :items="corporateBrands"
              item-value="id"
              item-text="name"
              item-label="Brand"
              :selected-values="bopoLogisticLeadTimeSearchCriteria.corporateBrandIds"
              @change="bopoLogisticLeadTimeSearchCriteria.corporateBrandIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-4">
            <v-checkbox
              class="mt-0"
              v-model="bopoLogisticLeadTimeSearchCriteria.active"
              @change="bopoLogisticLeadTimeSearchCriteria.active = arguments[0]"
              label="Only active lead times from today"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-4">
            <v-checkbox
              class="mt-0"
              v-model="bopoLogisticLeadTimeSearchCriteria.quickDemand"
              @change="bopoLogisticLeadTimeSearchCriteria.quickDemand = arguments[0]"
              label="Only quick demand lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="mt-2">
      <base-input-search-and-clear-and-download-buttons
          @search="search"
          @reset="reset"
          @download="download"/>
    </div>

    <v-card class="mt-10 logistics-lead-time-search-results">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="bopoLogisticLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        :display-footer-text=false
        search-result-name="bopoLogisticLeadTimeSearchResults"
        url="/api/touchpoint/bopo-logistic-lead-time/criteria-search">

        <template v-slot:item.countryOfDelivery="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.countryOfDelivery.code"
            :item-label="item.shippingLeadTime.countryOfDelivery.name"/>
        </template>
        <template v-slot:item.shippingLtFrom="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.placeOfLoading.location.code"
            :item-label="item.shippingLeadTime.placeOfLoading.description"/>
        </template>
        <template v-slot:item.shippingMoT="{ item }">
          <base-label-with-freight-service :freight-service="item.shippingLeadTime.freightService"/>
        </template>

        <template v-slot:item.planningMarket="{ item }">
          <base-label-with-flag :item-label="item.planningMarket.shortNameDescription"
                                :item-country="item.planningMarket.logisticCountry.code"/>
        </template>

        <template v-slot:item.finalDestination="{ item }">
          <base-label-with-flag
            :item-country="item.finalDestinationLeadTime.location.location.code"
            :item-label="item.finalDestinationLeadTime.location.description"/>
        </template>

        <template v-slot:item.transitLt="{ item }">
          <v-tooltip v-if="item.transitLeadTime" bottom color="red" >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <a @click="showTransitView(item)">Yes</a>
              </span>
            </template>
            <span>View transit details</span>
          </v-tooltip>
          <span v-else>No</span>
        </template>

        <template v-slot:item.totalDays="{ item }">
          <v-tooltip v-if="item.shippingLeadTime.hasAdditionalShippingLeadTimes" bottom color="red" >
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <label> {{ item.totalDays }}</label> <span><v-icon>mdi-alarm</v-icon></span>
              </span>
            </template>
            <div v-for="interval in item.additionalShippingLeadTimeIntervalsDetails" v-bind:key="interval.validFrom">
              <label>{{ interval }}</label>
            </div>
          </v-tooltip>
          <template v-else>
            <label> {{ item.totalDays }} </label>
          </template>
        </template>
        <template v-slot:item.totalWeeks="{ item }">
          <label> {{ item.totalWeeks }} </label>
        </template>
        <template v-slot:item.defaultForDeliveryCountry="{ item }">
          <span> {{item.shippingLeadTime.defaultForCountryOfDelivery ? 'Yes' : 'No' }} </span>
        </template>

        <template v-slot:item.publishTime="{ item }">
          <span v-if="item.publishTime"> {{ item.publishTime | formatDate }} </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
        </template>

      </base-table-paged-server-side>
    </v-card>

    <search-logistic-lead-time-view-details-dialog
    :can-update="false"
    :viewDialog="viewDialog"
    :viewDialogLogisticLeadTime="viewDialogLogisticLeadTime"
    @close="closeViewDialog"/>

    <search-transit-lead-time-view-details-dialog
      :view-dialog="viewTransitDialog"
      :view-dialog-transit-lead-time="transitInContext"
      :can-update="false"
      @close="closeTransitViewDialog"/>

  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseInputMultiSelect from '@/base/input/BaseInputMultiSelect'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import SearchLogisticLeadTimeViewDetailsDialog from '@/components/logisticsleadtime/searchlogisticleadtime/SearchLogisticLeadTimeViewDetailsDialog'
import SearchTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchTransitLeadTimeViewDetailsDialog'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import BaseInputSearchAndClearAndDownloadButtons from '@/base/input/BaseInputSearchAndClearAndDownloadButtons'
import { download } from '@/mixins/utilsMixin'
import moment from 'moment'
export default {
  name: 'SearchBopoLogisticsLeadTime.vue',
  mixins: [download],
  components: {
    BaseInputSearchAndClearAndDownloadButtons,
    BaseCardTitle,
    BaseInputMultiSelectWithFlag,
    BaseInputSelectWithFlag,
    BaseInputMultiSelect,
    BaseTablePagedServerSide,
    BaseLabelWithFlag,
    SearchLogisticLeadTimeViewDetailsDialog,
    SearchTransitLeadTimeViewDetailsDialog,
    BaseInputMultiSelectWithFreightService,
    BaseInputSelect,
    BaseLabelWithFreightService,
    BaseToolTip
  },
  data () {
    return {
      isLoaded: false,
      viewDialog: false,
      viewTransitDialog: false,
      transitInContext: undefined,
      viewDialogLogisticLeadTime: {},
      channels: [],
      tableHeaders: [
        { text: 'Country of del.', align: 'start', sortable: false, value: 'countryOfDelivery' },
        { text: 'Shipping from', align: 'start', sortable: false, value: 'shippingLtFrom' },
        { text: 'Shipping MoT', align: 'start', sortable: false, value: 'shippingMoT' },
        { text: 'Final destination', align: 'start', sortable: false, value: 'finalDestination' },
        { text: 'Channel', align: 'start', sortable: false, value: 'channel' },
        { text: 'Brand', align: 'start', sortable: false, value: 'planningMarket.corporateBrand.name' },
        { text: 'PM', align: 'start', sortable: false, value: 'planningMarket' },
        { text: 'Transit', align: 'start', sortable: false, value: 'transitLt' },
        { text: 'Total days', align: 'start', sortable: false, value: 'totalDays' },
        { text: 'Total weeks', align: 'start', sortable: false, value: 'totalWeeks' },
        { text: 'Del Ctry default?', align: 'start', sortable: false, value: 'defaultForDeliveryCountry' },
        { text: 'Valid from', align: 'start', sortable: true, value: 'validFrom' },
        { text: 'Valid to', align: 'start', sortable: true, value: 'validTo' },
        { text: 'Publish time', align: 'start', sortable: true, value: 'publishTime' },
        { text: 'Action', sortable: false, value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'planningMarkets',
      'serviceProviders',
      'transportLocations',
      'geographicalCountries',
      'placesOfLoading',
      'placesOfDischarge',
      'bopoLogisticLeadTimeSearchCriteria',
      'getUserRoles',
      'corporateBrands'
    ])
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllPlanningMarkets'),
      this.$store.dispatch('getAllServiceProviders'),
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllPlacesOfDischarge'),
      this.$store.dispatch('getAllCorporateBrands'),

      axios.get('/api/touchpoint/bopo-logistic-lead-time/channels')
        .then(response => {
          response.data.forEach(data => this.channels.push(data))
        })
        .catch(e => console.log(e))

    ]).then(() => { this.isLoaded = true })
  },
  methods: {
    search () {
      this.$refs.table.doSearch()
    },
    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetBopoLogisticLeadTimeSearchCriteria')
      this.totalReportErrorMessages = []
    },
    download () {
      const url = '/api/touchpoint//bopo-logistic-lead-time/download-excel'
      const fileName = 'BoPoLogisticLeadTimes' + moment().format('YYYY_MM_DD-HH_mm') + '.xlsx'
      this.downloadToExcel(url, this.bopoLogisticLeadTimeSearchCriteria, fileName)
    },
    showTransitView (item) {
      this.transitInContext = item.transitLeadTime
      this.viewTransitDialog = true
    },
    showViewDialog (item) {
      this.viewDialogLogisticLeadTime = item
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    closeTransitViewDialog () {
      this.viewTransitDialog = false
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
