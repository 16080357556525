const initState = () => {
  return {
    haulageLeadTimeSearchCriteria: {
      arrivalPointId: '',
      destinationPointId: '',
      inboundFreightServiceIds: [],
      freightServiceIds: [],
      active: true
    },
    haulageLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchHaulageLeadTime = {
  state: initState(),
  mutations: {
    updateHaulageLeadTimeSearchResults (state, searchResults) {
      state.haulageLeadTimeSearchResults = searchResults
    },
    resetHaulageLeadTimeSearchCriteria (state) {
      Object.assign(state.haulageLeadTimeSearchCriteria, initState().haulageLeadTimeSearchCriteria)
    }
  },
  getters: {
    haulageLeadTimeSearchCriteria: state => state.haulageLeadTimeSearchCriteria,
    haulageLeadTimeSearchResults: state => state.haulageLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchHaulageLeadTime
