<template>
  <v-row>
    <v-col class="mt-8">
      <v-alert v-if="validationErrorMessages && validationErrorMessages.length > 0" color="red lighten-2" dark>
        <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
          <li>{{validationErrorMessage.message}}</li>
        </ul>
      </v-alert>
      <v-card class="mb-6">
        <base-card-title
          label="Create Transit lead time"
          icon="mdi-calendar-import"/>
        <div class="text-h6 mb-3 mt-4 ml-4">Location points</div>
        <v-row>
          <v-col cols="10" class="pb-0 pt-4 ml-6">
            <base-input-select-with-flag
              :items="transitWarehouses"
              item-value="id"
              item-text="description"
              item-country="geographicalCountry.code"
              item-label="Transit warehouse"
              :rules="[rules.required]"
              :selected-value="transitLeadTime.transitWarehouseId"
              @change="transitLeadTime.transitWarehouseId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-select-with-flag
              item-label="Available point"
              :items="transportLocations"
              item-country="location.code"
              item-text="description"
              item-value="id"
              :rules="[rules.required]"
              :selected-value="transitLeadTime.availablePointId"
              @change="transitLeadTime.availablePointId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-select-with-flag
              item-label="Place of delivery"
              :items="transportLocations"
              item-country="location.code"
              item-text="description"
              item-value="id"
              :rules="[rules.required]"
              :selected-value="transitLeadTime.placeOfDeliveryId"
              @change="transitLeadTime.placeOfDeliveryId = arguments[0]"/>
          </v-col>
        </v-row>
        <div class="text-h6 mb-3 mt-0 ml-4">Transport modes</div>
        <v-row>
          <v-col cols="10" class="pb-0 pt-4 ml-6">
            <base-input-select-with-freight-service
              :freight-services="freightServices"
              item-label="Inbound freight service"
              :rules="[rules.required]"
              :selected-value="transitLeadTime.inboundFreightServiceId"
              @change="transitLeadTime.inboundFreightServiceId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-select-with-freight-service
              :freight-services="freightServices"
              item-label="Outbound freight service"
              :rules="[rules.required]"
              :selected-value="transitLeadTime.outboundFreightServiceId"
              @change="transitLeadTime.outboundFreightServiceId = arguments[0]"/>
          </v-col>
        </v-row>

        <div class="text-h6 mb-3 mt-0 ml-4">Lead times</div>
        <v-row>
          <v-col cols="10" class="pb-0 pt-4 ml-6">
            <base-input-number-field
              item-label="Arrival to available"
              :value="transitLeadTime.arrivalToAvailable"
              @blur="transitLeadTime.arrivalToAvailable = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-number-field
              item-label="Available to in-transit"
              :value="transitLeadTime.availableToInTransit"
              @blur="transitLeadTime.availableToInTransit = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-number-field
              item-label="In-transit to departure"
              :value="transitLeadTime.inTransitToDeparture"
              @blur="transitLeadTime.inTransitToDeparture = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-number-field
              item-label="Departure to place of delivery"
              :value="transitLeadTime.departureToPlaceOfDelivery"
              @blur="transitLeadTime.departureToPlaceOfDelivery = arguments[0]"/>
          </v-col>
        </v-row>
        <div class="text-h6 mb-3 mt-0 ml-4">Valid</div>
        <v-row>
          <v-col cols="10" class="pb-0 pt-4 ml-6">
            <base-input-select-date-picker
              item-label="Valid from"
              :rules="[rules.required]"
              :selected-value="transitLeadTime.validFrom"
              @change="transitLeadTime.validFrom = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-select-date-picker
              item-label="Valid to"
              :rules="[rules.fromDateIsBeforeToDate]"
              :selected-value="transitLeadTime.validTo"
              @change="transitLeadTime.validTo = arguments[0]"/>
          </v-col>
        </v-row>
      </v-card>
      <base-input-primary-button
        label="save"
        icon="mdi-arrow-right-bold-circle"
        @click="save"/>

      <base-input-secondary-button
        label="cancel"
        icon="mdi-cancel"
        @click="cancel"/>
    </v-col>
    <v-col v-if="timelineRequired" cols="5" class="mt-8">
      <create-transit-lead-time-time-line
        :transit-lead-time="transitLeadTime"
        :warehouses="transitWarehouses"
        :locations="transportLocations"
        :freightServices="freightServices"/>
    </v-col>
  </v-row>
</template>

<script>
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseInputSelectWithFreightService from '@/base/input/BaseInputSelectWithFreightService'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputNumberField from '@/base/input/BaseInputNumberField'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import CreateTransitLeadTimeTimeLine from '@/components/transitleadtime/createtransitleadtime/CreateTransitLeadTimeTimeLine'

export default {
  name: 'CreateTransitOrProposedTransitLeadTime',
  components: {
    CreateTransitLeadTimeTimeLine,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton,
    BaseInputSelectDatePicker,
    BaseInputNumberField,
    BaseCardTitle,
    BaseInputSelectWithFreightService,
    BaseInputSelectWithFlag
  },
  props: {
    transitLeadTime: {
      type: Object,
      require: true
    },
    transportLocations: {
      type: Array,
      require: true
    },
    transitWarehouses: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    timelineRequired: {
      type: Boolean,
      require: true
    },
    validationErrorMessages: {
      type: Array,
      require: false
    },
    rules: {
      type: Object,
      require: false
    }
  },
  methods: {
    save () {
      this.$emit('save')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
