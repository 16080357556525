<template>
  <div class="mt-2">
    <base-input-primary-button
      label="Search"
      icon="mdi-magnify"
      @click="search"/>
    <base-input-secondary-button
      label="Reset"
      icon="mdi-close"
      @click="reset"/>
    <base-input-primary-button
      class="ml-2"
      icon="mdi-microsoft-excel"
      label="Download result to excel"
      @click="download"/>
  </div>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
export default {
  name: 'BaseInputSearchAndClearAndDownloadButtons',
  components: {
    BaseInputSecondaryButton,
    BaseInputPrimaryButton
  },
  methods: {
    search () {
      this.$emit('search')
    },
    reset () {
      this.$emit('reset')
    },
    download () {
      this.$emit('download')
    }
  }
}
</script>

<style scoped>

</style>
