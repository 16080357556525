<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-2">
      <base-card-title
        label="Search terms of delivery"
        icon="mdi-calendar-import"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-2">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Country of delivery"
              :selected-values="termsOfDeliverySearchCriteria.countryOfDeliveryIds"
              @change="termsOfDeliverySearchCriteria.countryOfDeliveryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Destination logistics country"
              :selected-values="termsOfDeliverySearchCriteria.destinationLogisticCountryIds"
              @change="termsOfDeliverySearchCriteria.destinationLogisticCountryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              item-label="HazMat Type"
              :items="optionsForHazMatTermsOfDelivery"
              item-text="name"
              item-value="id"
              :selected-value="termsOfDeliverySearchCriteria.hazMat"
              @change="termsOfDeliverySearchCriteria.hazMat = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <multi-select-for-logistics-master-data
              :items="packingModes"
              item-value="id"
              item-text="name"
              item-label="Packing mode"
              logistic-lead-time-to-display="packingMode"
              :selected-values="termsOfDeliverySearchCriteria.packingModeIds"
              @change="termsOfDeliverySearchCriteria.packingModeIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <multi-select-for-logistics-master-data
              :items="activeModeOfTransportCategories"
              item-value="id"
              item-text="name"
              item-label="Mode of transport category"
              logistic-lead-time-to-display="motCategory"
              :selected-values="termsOfDeliverySearchCriteria.modeOfTransportCategoryIds"
              @change="termsOfDeliverySearchCriteria.modeOfTransportCategoryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <multi-select-for-logistics-master-data
              :items="activeModeOfTransports"
              item-value="id"
              item-text="name"
              item-label="Mode of transport"
              logistic-lead-time-to-display="mot"
              :selected-values="termsOfDeliverySearchCriteria.modeOfTransportIds"
              @change="termsOfDeliverySearchCriteria.modeOfTransportIds = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select
              :items="activeIncoTerms"
              item-value="id"
              item-text="description"
              item-label="Inco term"
              :selected-values="termsOfDeliverySearchCriteria.incoTermIds"
              @change="termsOfDeliverySearchCriteria.incoTermIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Valid from"
              :selected-value="termsOfDeliverySearchCriteria.validFrom"
              @change="termsOfDeliverySearchCriteria.validFrom = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Publish from"
              :selected-value="termsOfDeliverySearchCriteria.publishTimeFrom"
              @change="termsOfDeliverySearchCriteria.publishTimeFrom = arguments[0]"/>
          </v-col>

        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <v-text-field ref='external-id'
              filled
              id="priority"
              v-model="termsOfDeliverySearchCriteria.externalId"
              hint="External ID must be numeric"
              label="External ID"
              :counter="7"
              maxlength="7"
              :rules="[rules.externalIdRule]"
              @change="this.termsOfDeliverySearchCriteria.externalId = arguments[0]"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Valid to"
              :rules="[rules.fromDateIsBeforeToDate]"
              :selected-value="termsOfDeliverySearchCriteria.validTo"
              @change="termsOfDeliverySearchCriteria.validTo = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Publish to"
              :rules="[rules.publishFromIsBeforePublishTo]"
              :selected-value="termsOfDeliverySearchCriteria.publishTimeTo"
              @change="termsOfDeliverySearchCriteria.publishTimeTo = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Valid on"
              :selected-value="termsOfDeliverySearchCriteria.validOn"
              @change="termsOfDeliverySearchCriteria.validOn = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              item-label="Published terms of delivery"
              :items="optionsForPublishedTermsOfDelivery"
              item-text="name"
              item-value="id"
              :selected-value="termsOfDeliverySearchCriteria.published"
              @change="termsOfDeliverySearchCriteria.published = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-4">
            <v-checkbox
              class="mt-0"
              v-model="termsOfDeliverySearchCriteria.active"
              @change="this.termsOfDeliverySearchCriteria.active = arguments[0]"
              label="Only active terms of delivery"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <base-input-search-and-clear-and-download-buttons
      @search="search"
      @reset="reset"
      @download="download"/>

    <v-card class="mt-5">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="this.termsOfDeliverySearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="termsOfDeliverySearchResults"
        url="/api/touchpoint/terms-of-delivery/criteria-search">

        <template v-slot:item.countryOfDelivery="{ item }">
          <base-label-with-flag :item-country="item.countryOfDelivery.code" :item-label="item.countryOfDelivery.name"/>
        </template>
        <template v-slot:item.destinationLogisticsCountry="{ item }">
          <base-label-with-flag :item-country="item.destinationLogisticsCountry.code" :item-label="item.destinationLogisticsCountry.name"/>
        </template>
        <template v-slot:item.hazMat="{ item }">
          {{ item.hazMat | formatBoolean }}
        </template>
        <template v-slot:item.packingMode.name="{ item }">
          <base-label-with-packing-mode :packing-mode="item.packingMode"/>
        </template>
        <template v-slot:item.modeOfTransportCategory.name="{ item }">
          <base-label-with-mode-of-transport-category :mode-of-transport-category="item.modeOfTransportCategory"/>
        </template>
        <template v-slot:item.modeOfTransport.name="{ item }">
          <base-label-with-mode-of-transport :mode-of-transport="item.modeOfTransport"/>
        </template>
        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="isAdmin(getUserRoles)"
            label="Edit"
            icon="mdi-pencil"
            @click="$router.push({ path: `/TermsOfDelivery/Update/${item.id}` })">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
      <search-terms-of-delivery-view-details-dialog
        :can-update="isAdmin(getUserRoles)"
        :view-dialog="viewDialog"
        :view-dialog-terms-of-delivery="viewDialogTermsOfDelivery"
        @close="closeViewDialog"/>
    </v-card>
  </v-container>
</template>

<script>
import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import BaseInputMultiSelect from '@/base/input/BaseInputMultiSelect'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import { mapGetters } from 'vuex'
import { download, security } from '@/mixins/utilsMixin'
import BaseInputSearchAndClearAndDownloadButtons from '@/base/input/BaseInputSearchAndClearAndDownloadButtons'
import { validateDate } from '@/validation/customvalidation'
import moment from 'moment/moment'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import SearchTermsOfDeliveryViewDetailsDialog from '@/components/termsofdelivery/searchtermsofdelivery/SearchTermsOfDeliveryViewDetailsDialog'
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'
import BaseLabelWithModeOfTransport from '@/base/label/BaseLabelWithModeOfTransport'
import BaseLabelWithModeOfTransportCategory from '@/base/label/BaseLabelWithModeOfTransportCategory'
import BaseLabelWithPackingMode from '@/base/label/BaseLabelWithPackingMode'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import MultiSelectForLogisticsMasterData from '@/views/termsofdelivery/MultiSelectForLogisticsMasterData'
export default {
  name: 'SearchTermsOfDelivery',
  mixins: [security, download, transportModeIconResolverMixin],
  components: {
    BaseLabelWithModeOfTransport,
    BaseLabelWithModeOfTransportCategory,
    BaseLabelWithPackingMode,
    BaseInputSelectDatePicker,
    BaseInputSearchAndClearAndDownloadButtons,
    BaseLabelWithFlag,
    BaseTablePagedServerSide,
    BaseInputMultiSelect,
    BaseInputMultiSelectWithFlag,
    BaseCardTitle,
    BaseToolTip,
    SearchTermsOfDeliveryViewDetailsDialog,
    BaseInputSelect,
    MultiSelectForLogisticsMasterData
  },
  data () {
    return {
      isLoaded: false,
      viewDialog: false,
      viewDialogTermsOfDelivery: {},
      optionsForHazMatTermsOfDelivery: [],
      optionsForPublishedTermsOfDelivery: [],
      tableHeaders: [
        { text: 'Country of delivery.', align: 'start', sortable: true, value: 'countryOfDelivery' },
        { text: 'Destination logistics country', align: 'start', sortable: true, value: 'destinationLogisticsCountry' },
        { text: 'Is HazMat?', align: 'start', sortable: true, value: 'hazMat' },
        { text: 'Packing mode', align: 'start', sortable: false, value: 'packingMode.name' },
        { text: 'MoT category', align: 'start', sortable: false, value: 'modeOfTransportCategory.name' },
        { text: 'Mode of transport', align: 'start', sortable: true, value: 'modeOfTransport.name' },
        { text: 'Inco term', align: 'start', sortable: true, value: 'incoTerms.code' },
        { text: 'Valid from', align: 'start', sortable: true, value: 'validFrom' },
        { text: 'Valid to', align: 'start', sortable: true, value: 'validTo' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ],
      rules: {
        fromDateIsBeforeToDate: value => validateDate(this.termsOfDeliverySearchCriteria.validFrom, value) || 'Valid to date cannot be before valid from date.',
        publishFromIsBeforePublishTo: value => validateDate(this.termsOfDeliverySearchCriteria.publishTimeFrom, value) || 'Publish to date cannot be before publish from date.',
        externalIdRule: value => (value === '' || /^\d+$/.test(value)) || 'Enter a valid number.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'termsOfDeliverySearchCriteria',
      'getUserRoles',
      'geographicalCountries',
      'packingModes',
      'activeIncoTerms',
      'activeModeOfTransports',
      'activeModeOfTransportCategories'
    ])
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllIncoTerms'),
      this.$store.dispatch('getAllPackingModes'),
      this.$store.dispatch('getAllModeOfTransports'),
      this.$store.dispatch('getAllModeOfTransportCategories')
    ]).then(() => {
      this.isLoaded = true
    })
    this.optionsForHazMatTermsOfDelivery = [
      { id: null, name: 'All' },
      { id: true, name: 'Yes' },
      { id: false, name: 'No' }
    ]
    this.optionsForPublishedTermsOfDelivery = [
      { id: null, name: 'All' },
      { id: true, name: 'Included' },
      { id: false, name: 'Excluded' }
    ]
  },
  methods: {
    search () {
      if (this.$refs['external-id'].validate()) {
        this.$refs.table.doSearch()
      }
    },

    reset () {
      this.$refs.table.doReset()
      this.$store.commit('resetTermsOfDeliverySearchCriteria')
    },

    showViewDialog (termsOfDelivery) {
      this.viewDialogTermsOfDelivery = termsOfDelivery
      this.viewDialog = true
    },

    closeViewDialog () {
      this.viewDialog = false
    },

    download () {
      const url = '/api/touchpoint/terms-of-delivery/criteria-search/download'
      const fileName = 'TermsOfDeliveries' + moment().format('YYYY_MM_DD-HH_mm') + '.xlsx'
      this.downloadToExcel(url, this.termsOfDeliverySearchCriteria, fileName)
    }
  }
}
</script>

<style scoped>

</style>
