<template>
  <v-autocomplete
    filled
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :label="itemLabel"
    :rules="rules"
    :hint="hint"
    :persistent-hint="showHint"
    v-model="selectValueItem"
    @change="$emit('change', selectValueItem)"
    clearable>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BaseInputSelect',
  props: {
    items: {
      type: Array,
      require: true
    },
    itemText: {
      type: String,
      require: true
    },
    itemValue: {
      type: String,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      require: false
    },
    rules: {
      type: Array,
      require: false
    },
    hint: {
      type: String,
      require: false
    }
  },
  data () {
    return {
      selectValueItem: ''
    }
  },
  mounted () {
    this.selectValueItem = this.selectedValue
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  },
  computed: {
    showHint () {
      return !!this.hint
    }
  }
}
</script>

<style scoped>

</style>
