const initState = () => {
  return {
    shippingLeadTimeSearchCriteria: {
      freightServiceIds: [],
      placeOfDeliveryId: '',
      placeOfLoadingId: '',
      placeOfDeliveryCountryIds: [],
      countryOfDeliveryIds: [],
      placeOfLoadingCountryIds: [],
      serviceProviderId: '',
      serviceCode: '',
      service: '',
      main: '',
      active: 'true'
    },
    shippingLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchShippingLeadTime = {
  state: initState(),
  mutations: {
    updateShippingLeadTimeSearchResults (state, searchResults) {
      state.shippingLeadTimeSearchResults = searchResults
    },
    resetShippingLeadTimeSearchCriteria (state) {
      Object.assign(state.shippingLeadTimeSearchCriteria, initState().shippingLeadTimeSearchCriteria)
    }
  },
  getters: {
    shippingLeadTimeSearchCriteria: state => state.shippingLeadTimeSearchCriteria,
    shippingLeadTimeSearchResults: state => state.shippingLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchShippingLeadTime
