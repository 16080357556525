import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Home from '../views/Home.vue'
import AccessDenied from '../base/AccessDenied'
import Logout from '../auth/Logout'
import ViewGeographicalCountry from '../views/geographicalcountry/ViewGeographicalCountry'
import ViewTransportLocations from '../views/transportlocation/ViewTransportLocations'
import ViewPlanningMarkets from '../views/planningmarket/ViewPlanningMarkets'
import ViewCarriers from '../views/carrier/ViewCarriers'
import ViewServiceProvider from '../views/serviceprovider/ViewServiceProvider'
import ViewLogicalWarehouse from '../views/logicalwarehouse/ViewLogicalWarehouse'
import ViewFreightService from '../views/freightservice/ViewFreightService'

import SearchShippingLeadTime from '../views/shippingleadtime/SearchShippingLeadTime'
import CreateShippingLeadTime from '../views/shippingleadtime/CreateShippingLeadTime'
import UpdateShippingLeadTime from '../views/shippingleadtime/UpdateShippingLeadTime'

import SearchAdditionalShippingLeadTime from '../views/shippingleadtime/SearchAdditionalShippingLeadTime'
import CreateAdditionalShippingLeadTime from '../views/shippingleadtime/CreateAdditionalShippingLeadTime'
import UpdateAdditionalShippingLeadTime from '../views/shippingleadtime/UpdateAdditionalShippingLeadTime'

import CreateTransitLeadTime from '@/views/transitleadtime/CreateTransitLeadTime'
import UpdateTransitLeadTime from '@/views/transitleadtime/UpdateTransitLeadTime'
import SearchTransitLeadTime from '@/views/transitleadtime/SearchTransitLeadTime'

import SearchHaulageLeadTime from '@/views/haulageleadtime/SearchHaulageLeadTime'
import CreateHaulageLeadTime from '../views/haulageleadtime/CreateHaulageLeadTime'
import UpdateHaulageLeadTime from '../views/haulageleadtime/UpdateHaulageLeadTime'
import SearchProposedHaulageLeadTime from '@/views/haulageleadtime/SearchProposedHaulageLeadTime'

import CreateFinalDestinationLeadTime from '../views/finaldestinationleadtime/CreateFinalDestinationLeadTime'
import SearchFinalDestinationLeadTime from '../views/finaldestinationleadtime/SearchFinalDestinationLeadTime'
import UpdateFinalDestinationLeadTime from '../views/finaldestinationleadtime/UpdateFinalDestinationLeadTime'
import SearchProposedFinalDestinationLeadTime from '../views/finaldestinationleadtime/SearchProposedFinalDestinationLeadTime'

import CreateLogisticsLeadTime from '../views/logisticsleadtime/CreateLogisticsLeadTime'
import UpdateLogisticsLeadTime from '../views/logisticsleadtime/UpdateLogisticsLeadTime'
import SearchLogisticsLeadTime from '../views/logisticsleadtime/SearchLogisticsLeadTime'
import SearchProposedLogisticLeadTime from '../views/logisticsleadtime/SearchProposedLogisticLeadTime'
import CreateProposedLogisticsLeadTime from '../views/logisticsleadtime/CreateProposedLogisticsLeadTime'
import ServiceProviderBaseLoad from '@/views/serviceprovider/ServiceProviderBaseLoad'
import LogicalWarehouseBaseLoad from '@/views/logicalwarehouse/LogicalWarehouseBaseLoad'
import TransportLocationBaseLoad from '@/views/transportlocation/TransportLocationBaseLoad'
import SearchProposedTransitLeadTime from '@/views/transitleadtime/SearchProposedTransitLeadTime'
import CarrierBaseLoad from '@/views/carrier/CarrierBaseLoad'
import PlanningMarketBaseload from '@/views/planningmarket/PlanningMarketBaseload'
import GeographicalCountryBaseLoad from '@/views/geographicalcountry/GeographicalCountryBaseLoad'
import CorporateBrandBaseLoad from '@/views/corporatebrand/CorporateBrandBaseLoad'
import BulkEventSendLogisticsLeadTime from '@/views/logisticsleadtime/BulkEventSendLogisticsLeadTime'
import ImportListeners from '@/views/integration/ImportListeners'
import ReplaceShippingLeadTime from '@/views/shippingleadtime/ReplaceShippingLeadTime'
import ReplaceShippingLeadTimes from '@/views/shippingleadtime/ReplaceShippingLeadTimes'
import LogisticsLeadTimeDifferenceReport from '@/views/logisticsleadtime/LogisticsLeadTimeDifferenceReport'
import SearchBopoLogisticsLeadTime from '@/views/logisticsleadtime/SearchBopoLogisticsLeadTime'
import ViewCorporateBrands from '@/views/corporatebrand/ViewCorporateBrands'
import ViewCreateOrUpdateIncoTerm from '@/views/logisticmasterdata/ViewCreateOrUpdateIncoTerm'
import ViewCreateOrUpdateModeOfTransport from '@/views/logisticmasterdata/ViewCreateOrUpdateModeOfTransport'
import ViewCreateOrUpdateModeOfTransportCategory from '@/views/logisticmasterdata/ViewCreateOrUpdateModeOfTransportCategory'
import ViewCreateOrUpdatePackingMode from '@/views/logisticmasterdata/ViewCreateOrUpdatePackingMode'
import SearchTermsOfDelivery from '@/views/termsofdelivery/SearchTermsOfDelivery'
import UpdateTermsOfDelivery from '@/views/termsofdelivery/UpdateTermsOfDelivery'
import CreateTermsOfDelivery from '@/views/termsofdelivery/CreateTermsOfDelivery'
import ReplaceHaulageLeadTime from '@/views/haulageleadtime/ReplaceHaulageLeadTime.vue'
import ReplaceTransitLeadTime from '@/views/transitleadtime/ReplaceTransitLeadTime.vue'
import ReplaceFinalDestinationLeadTime from '@/views/finaldestinationleadtime/ReplaceFinalDestinationLeadTime.vue'

Vue.use(VueRouter)

const TECH_ADMIN_ROLES = ['ROLE_TECH_ADMIN']
const ADMIN_ROLES = ['ROLE_ADMIN', 'ROLE_TECH_ADMIN']

const routes = [
  {
    path: '/',
    alias: '/code=*',
    name: 'Home',
    component: Home
  },
  {
    path: '/AccessDenied',
    name: 'AccessDenied',
    component: AccessDenied,
    meta: { requiresNoAuth: true }
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: Logout,
    meta: { requiresNoAuth: true }
  },
  {
    path: '/ShippingLeadTime/Search',
    name: 'SearchShippingLeadTime',
    component: SearchShippingLeadTime
  },
  {
    path: '/ShippingLeadTime/Create',
    name: 'CreateShippingLeadTime',
    component: CreateShippingLeadTime,
    props: true,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ShippingLeadTime/MultiReplace',
    name: 'ReplaceShippingLeadTimes',
    component: ReplaceShippingLeadTimes,
    props: true,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ShippingLeadTime/Create/:id',
    name: 'CreateShippingLeadTime',
    component: CreateShippingLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ShippingLeadTime/Update/:id',
    name: 'UpdateShippingLeadTime',
    component: UpdateShippingLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ShippingLeadTime/Replace/:id',
    name: 'ReplaceShippingLeadTime',
    component: ReplaceShippingLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/AdditionalShippingLeadTime/Search',
    name: 'SearchAdditionalShippingLeadTime',
    component: SearchAdditionalShippingLeadTime
  },
  {
    path: '/AdditionalShippingLeadTime/Create',
    name: 'CreateAdditionalShippingLeadTime',
    component: CreateAdditionalShippingLeadTime,
    props: true,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/AdditionalShippingLeadTime/Update/:id',
    name: 'UpdateAdditionalShippingLeadTime',
    component: UpdateAdditionalShippingLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TransitLeadTime/Create',
    name: 'CreateTransitLeadTime',
    component: CreateTransitLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TransitLeadTime/Create/:id',
    name: 'CreateTransitLeadTime',
    component: CreateTransitLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TransitLeadTime/Update/:id',
    name: 'UpdateTransitLeadTime',
    component: UpdateTransitLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TransitLeadTime/Replace/:id',
    name: 'ReplaceTransitLeadTime',
    component: ReplaceTransitLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TransitLeadTime/Search',
    name: 'SearchTransitLeadTime',
    component: SearchTransitLeadTime
  },
  {
    path: '/ProposedTransitLeadTime/Search',
    name: 'SearchProposedTransitLeadTime',
    component: SearchProposedTransitLeadTime
  },
  {
    path: '/ProposedHaulageLeadTime/Search',
    name: 'SearchProposedHaulageLeadTime',
    component: SearchProposedHaulageLeadTime
  },
  {
    path: '/HaulageLeadTime/Search',
    name: 'SearchHaulageLeadTime',
    component: SearchHaulageLeadTime
  },
  {
    path: '/HaulageLeadTime/Create',
    name: 'CreateHaulageLeadTime',
    component: CreateHaulageLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/HaulageLeadTime/Create/:id',
    name: 'CopyHaulageLeadTime',
    component: CreateHaulageLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/HaulageLeadTime/Replace/:id',
    name: 'ReplaceHaulageLeadTime',
    component: ReplaceHaulageLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/HaulageLeadTime/Update/:id',
    name: 'UpdateHaulageLeadTime',
    component: UpdateHaulageLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TermsOfDelivery/Create',
    name: 'CreateTermsOfDelivery',
    component: CreateTermsOfDelivery,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TermsOfDelivery/Update/:id',
    name: 'UpdateTermsOfDelivery',
    component: UpdateTermsOfDelivery,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/FinalDestinationLeadTime/create',
    name: 'CreateFinalDestinationLeadTime',
    component: CreateFinalDestinationLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/FinalDestinationLeadTime/Create/:id',
    name: 'CopyFinalDestinationLeadTime',
    component: CreateFinalDestinationLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/FinalDestinationLeadTime/Search',
    name: 'SearchFinalDestinationLeadTime',
    component: SearchFinalDestinationLeadTime
  },
  {
    path: '/FinalDestinationLeadTime/Update/:id',
    name: 'UpdateFinalDestinationLeadTime',
    component: UpdateFinalDestinationLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/FinalDestinationLeadTime/Replace/:id',
    name: 'ReplaceFinalDestinationLeadTime',
    component: ReplaceFinalDestinationLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ProposedFinalDestinationLeadTime/Search',
    name: 'SearchProposedFinalDestinationLeadTime',
    component: SearchProposedFinalDestinationLeadTime
  },
  {
    path: '/LogisticsLeadTime/Create/:id',
    name: 'CreateLogisticsLeadTime',
    component: CreateLogisticsLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/LogisticsLeadTime/Update/:id',
    name: 'UpdateLogisticsLeadTime',
    component: UpdateLogisticsLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/LogisticsLeadTime/Search',
    name: 'SearchLogisticsLeadTime',
    component: SearchLogisticsLeadTime
  },
  {
    path: '/BopoLogisticsLeadTime/Search',
    name: 'SearchBopoLogisticsLeadTime',
    component: SearchBopoLogisticsLeadTime
  },
  {
    path: '/LogisticsLeadTime/DifferenceReport',
    name: 'LogisticsLeadTimeDifferenceReport',
    component: LogisticsLeadTimeDifferenceReport
  },
  {
    path: '/ProposedLogisticLeadTime/Search',
    name: 'SearchProposedLogisticLeadTime',
    component: SearchProposedLogisticLeadTime
  },
  {
    path: '/ProposedLogisticsLeadTime/Create',
    name: 'CreateProposedLogisticsLeadTime',
    component: CreateProposedLogisticsLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/GeographicalCountry/View',
    name: 'ViewGeographicalCountry',
    component: ViewGeographicalCountry,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TransportLocation/View',
    name: 'ViewTransportLocations',
    component: ViewTransportLocations,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TransportLocation/BaseLoad',
    name: 'TransportLocationBaseLoad',
    component: TransportLocationBaseLoad,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/PlanningMarket/View',
    name: 'ViewPlanningMarkets',
    component: ViewPlanningMarkets,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/CorporateBrand/View',
    name: 'ViewCorporateBrands',
    component: ViewCorporateBrands,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/PlanningMarket/BaseLoad',
    name: 'PlanningMarketBaseload',
    component: PlanningMarketBaseload,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/Carrier/View',
    name: 'ViewCarriers',
    component: ViewCarriers,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/Carrier/BaseLoad',
    name: 'CarrierProviderBaseLoad',
    component: CarrierBaseLoad,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/ServiceProvider/View',
    name: 'ViewServiceProvider',
    component: ViewServiceProvider,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ServiceProvider/BaseLoad',
    name: 'ServiceProviderBaseLoad',
    component: ServiceProviderBaseLoad,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/LogicalWarehouse/View',
    name: 'ViewLogicalWarehouse',
    component: ViewLogicalWarehouse,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/LogicalWarehouse/BaseLoad',
    name: 'LogicalWarehouseBaseLoad',
    component: LogicalWarehouseBaseLoad,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/FreightService/View',
    name: 'ViewFreightService',
    component: ViewFreightService,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/GeographicalCountry/BaseLoad',
    name: 'GeographicalCountryBaseLoad',
    component: GeographicalCountryBaseLoad,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/CorporateBrand/BaseLoad',
    name: 'CorporateBrandBaseLoad',
    component: CorporateBrandBaseLoad,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/BulkEventSendLogisticsLeadTime/View',
    name: 'BulkEventSendLogisticsLeadTime',
    component: BulkEventSendLogisticsLeadTime,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/ImportListeners/View',
    name: 'ImportListeners',
    component: ImportListeners,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, TECH_ADMIN_ROLES)
    }
  },
  {
    path: '/IncoTerm/View',
    name: 'ViewCreateOrUpdateIncoTerm',
    component: ViewCreateOrUpdateIncoTerm,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ModeOfTransport/View',
    name: 'ViewCreateOrUpdateModeOfTransport',
    component: ViewCreateOrUpdateModeOfTransport,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/ModeOfTransportCategory/View',
    name: 'ViewCreateOrUpdateModeOfTransportCategory',
    component: ViewCreateOrUpdateModeOfTransportCategory,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/PackingMode/View',
    name: 'ViewCreateOrUpdatePackingMode',
    component: ViewCreateOrUpdatePackingMode,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TermsOfDelivery/Search',
    name: 'SearchTermsOfDelivery',
    component: SearchTermsOfDelivery
  },
  {
    path: '/TermsOfDelivery/Create',
    name: 'CreateTermsOfDelivery',
    component: CreateTermsOfDelivery,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  },
  {
    path: '/TermsOfDelivery/Update/:id',
    name: 'UpdateTermsOfDelivery',
    component: UpdateTermsOfDelivery,
    async beforeEnter (to, from, next) {
      await rolesAllowed(next, ADMIN_ROLES)
    }
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresNoAuth) {
    next()
    return
  }

  await store.dispatch('authenticateUser')
    .then(() => {
      const isUserAuthenticated = store.getters.isUserAuthenticated
      if (isUserAuthenticated) {
        next()
      } else {
        next('/AccessDenied')
      }
    })
    .catch()
})

export default router

export async function rolesAllowed (next, theRulesAllowed) {
  const userRoles = await getUserRoles().catch().finally()
  const hasAccess = userRoles.some(userRole => theRulesAllowed.indexOf(userRole) >= 0)

  if (hasAccess) {
    next()
  } else {
    next('/AccessDenied')
  }
}

async function getUserRoles () {
  await store.dispatch('getUser')
  return store.state.UserInfo.roles
}
