<template>
  <div>
  <v-card>
    <base-card-title v-if="!proposedLogisticLeadTime"
      label="Update Shipping & Logistics lead time"
      icon="mdi-truck-delivery-outline"/>

    <base-card-title v-if="proposedLogisticLeadTime"
                     label="Create proposed shipping & logistics lead time"
                     icon="mdi-truck-delivery-outline"/>

    <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
      <ul v-for="message in validationErrorMessages" v-bind:key="message">
        <li>{{ message }}</li>
      </ul>
    </v-alert>
    <v-simple-table class="ml-6 mr-6" dense v-if="shippingLeadTime.placeOfLoading">
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">Place of loading</th>
          <th class="text-left">Mode of transport</th>
          <th class="text-left">Place of delivery</th>
          <th class="text-left">Total days</th>
          <th class="text-left">View</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="pt-3 pb-5"><base-label-with-flag :item-country="shippingLeadTime.placeOfLoading.location.code" :item-label="shippingLeadTime.placeOfLoading.description"/></td>
          <td class="pt-3 pb-5"><base-label-with-freight-service :freight-service="shippingLeadTime.freightService"/></td>
          <td class="pt-3 pb-5"><base-label-with-flag :item-country="shippingLeadTime.placeOfDelivery.location.code" :item-label="shippingLeadTime.placeOfDelivery.description"/></td>
          <td class="pt-3 pb-5">{{ shippingLeadTime.totalDays }}</td>
          <td class="pt-3 pb-5"><v-icon class="mr-2" @click="showViewShippingLeadTimeDialog()">mdi-magnify</v-icon></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>

  <v-expansion-panels focusable v-model="panel" multiple>
    <!--Transit Lead time section-->
    <template v-if="transitLtExists">
      <v-expansion-panel readonly expanded>
        <v-expansion-panel-header expand-icon="">
          <span class="text-subtitle-2"> <v-icon>mdi-truck-delivery-outline</v-icon> Transit lead time</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="transitLtTableHeaders"
            :items="transitLeadTimes"
            :hide-default-footer="true">
            <!-- slots -->
            <template v-slot:item.availablePoint="{ item }">
              <base-label-with-flag :item-country="item.availablePoint.location.code" :item-label="item.availablePoint.description"/>
            </template>
            <template v-slot:item.placeOfDelivery="{ item }">
              <base-label-with-flag :item-country="item.placeOfDelivery.location.code" :item-label="item.placeOfDelivery.description"/>
            </template>
            <template v-slot:item.outboundFreightService="{ item }">
              <base-label-with-freight-service :freight-service="item.outboundFreightService"/>
            </template>
            <template v-slot:item.transitWarehouse="{ item }">
              <base-label-with-flag :item-country="item.transitWarehouse.geographicalCountry.code" :item-label="item.transitWarehouse.currentWarehouseCode"/>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="showViewTransitDialog(item)">mdi-magnify</v-icon>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>

    <!--Haulage Lead time section-->
    <template v-if="haulageLtExists">
      <v-expansion-panel readonly expanded>
        <v-expansion-panel-header expand-icon="">
          <span class="text-subtitle-2"> <v-icon>mdi-truck-delivery-outline</v-icon> Haulage lead time</span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            :headers="haulageLtTableHeaders"
            :items="haulageLeadTimes"
            :hide-default-footer="true">
            <!-- slots -->
            <template v-slot:item.arrivalPoint="{ item }">
              <base-label-with-flag :item-country="item.arrivalPoint.location.code" :item-label="item.arrivalPoint.description"/>
            </template>
            <template v-slot:item.destinationPoint="{ item }">
              <base-label-with-flag :item-country="item.destinationPoint.location.code" :item-label="item.destinationPoint.description"/>
            </template>
            <template v-slot:item.inboundFreightServices="{ item }">
              <div class="mt-2">
                <base-label-with-freight-services :freight-services="item.inboundFreightServices"/>
              </div>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon class="mr-2" @click="showViewHaulageDialog(item)">mdi-magnify</v-icon>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </template>
    <!--Final Destination Lead time section-->
    <v-expansion-panel readonly expanded>
      <v-expansion-panel-header expand-icon="">
        <span class="text-subtitle-2"> <v-icon>mdi-calendar-export</v-icon> Final destination lead time</span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-data-table
          :headers="finalDestinationLtTableHeaders"
          :items="finalDestinationLeadTimes"
          :hide-default-footer="true">
          <!-- slots -->
          <template v-slot:item.location="{ item }">
            <base-label-with-flag :item-country="item.location.location.code" :item-label="item.location.description"/>
          </template>
          <template v-slot:item.warehouses="{ item }">
            <div class="pt-1 pb-1">
              <div v-for="warehouse in item.logicalWarehouses"  v-bind:key="warehouse.id">
                <template>
                  <div>
                    <span> {{warehouse.currentWarehouseCode}} </span>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <template v-slot:item.planningMarkets="{ item }">
            <div class="pt-1 pb-1">
              <div v-for="planningMarket in item.planningMarketsActiveOnCurrentDate"  v-bind:key="planningMarket.id">
                <div>
                  <span :title="planningMarket.description">{{ planningMarket.abbreviation }}</span>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:item.inboundFreightServices="{ item }">
            <div class="mt-2">
              <base-label-with-freight-services :freight-services="item.inboundFreightServices"/>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" @click="showViewFinalDestinationDialog(item)">mdi-magnify</v-icon>
          </template>
        </v-data-table>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
  </div>
</template>

<script>

import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseLabelWithFreightServices from '@/base/label/BaseLabelWithFreightServices'

export default {
  name: 'CreateProposedOrUpdateLogisticLeadTime',
  components: {
    BaseCardTitle,
    BaseLabelWithFlag,
    BaseLabelWithFreightService,
    BaseLabelWithFreightServices
  },
  data () {
    return {
      panelValues: [0, 1, 2],
      transitLtTableHeaders: [
        { text: 'Available point', align: 'start', sortable: false, value: 'availablePoint' },
        { text: 'Place of delivery', align: 'start', sortable: false, value: 'placeOfDelivery' },
        { text: 'Outbound MoT', sortable: false, value: 'outboundFreightService' },
        { text: 'Transit warehouse', sortable: false, value: 'transitWarehouse' },
        { text: 'Total days', sortable: false, value: 'totalDays' },
        { text: 'View', sortable: false, value: 'actions' }
      ],
      haulageLtTableHeaders: [
        { text: 'Arrival point', align: 'start', sortable: false, value: 'arrivalPoint' },
        { text: 'Inbound freight services', sortable: false, value: 'inboundFreightServices' },
        { text: 'Destination point', sortable: false, value: 'destinationPoint' },
        { text: 'Total days', sortable: false, value: 'totalDays' },
        { text: 'View', sortable: false, value: 'actions' }
      ],
      finalDestinationLtTableHeaders: [
        { text: 'Destination', align: 'start', sortable: false, value: 'location' },
        { text: 'Shipping MoT', align: 'start', sortable: false, value: 'inboundFreightServices' },
        { text: 'Warehouses', align: 'start', sortable: false, value: 'warehouses' },
        { text: 'PM', align: 'start', sortable: false, value: 'planningMarkets' },
        { text: 'Total days', sortable: false, value: 'totalDays' },
        { text: 'View', sortable: false, value: 'actions' }
      ]
    }
  },
  props: {
    proposedLogisticLeadTime: {
      type: Boolean,
      require: false,
      default: false
    },
    shippingLeadTime: {
      type: Object,
      require: true
    },
    validationErrorMessages: {
      type: Array,
      require: false
    },
    transitLtExists: {
      type: Boolean,
      require: true
    },
    transitLeadTimes: {
      type: Array,
      require: true
    },
    haulageLtExists: {
      type: Boolean,
      require: true
    },
    haulageLeadTimes: {
      type: Array,
      require: true
    },
    finalDestinationLeadTimes: {
      type: Array,
      require: true
    }
  },
  methods: {
    showViewShippingLeadTimeDialog () {
      this.$emit('showViewShippingLeadTimeDialog')
    },
    showViewTransitDialog () {
      this.$emit('showViewTransitDialog')
    },
    showViewHaulageDialog (item) {
      this.$emit('showViewHaulageDialog')
    },
    showViewFinalDestinationDialog (item) {
      this.$emit('showViewFinalDestinationDialog')
    }
  },
  computed: {
    panel: {
      get () {
        return this.panelValues
      },
      set (val) {
        this.panelValues = [0, 1, 2]
      }
    }
  }
}
</script>

<style scoped>
.v-card__text {
  padding-top: 0;
}

.v-expansion-panel {
  margin-top: 10px;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 30px;
}

.v-expansion-panel-header:before {
  background-color: white;
  border-bottom: 1px solid;
}
</style>
