<template>
  <v-row justify="center">
    <v-col class="mt-1" cols="6">
      <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
        <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
          <li>{{validationErrorMessage.message}}</li>
        </ul>
      </v-alert>
      <v-card class="mb-6">
        <base-card-title
          label="Create Terms Of Delivery"
          icon="mdi-truck-delivery-outline"/>
        <v-row>
          <v-col cols="10" class="pb-0 ml-6">
            <base-input-select-with-flag
              item-label="Country of delivery"
              :items="countryOfDelivery"
              item-country="code"
              item-text="description"
              item-value="id"
              :rules="[rules.required]"
              :selected-value="termsOfDelivery.countryOfDeliveryId"
              @change="termsOfDelivery.countryOfDeliveryId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
        <v-col cols="10"  class="pb-0 pt-0 ml-6">
          <base-input-select-with-flag
            item-label="Destination logistics country"
            :items="destinationLogisticsCountry"
            item-country="code"
            item-text="description"
            item-value="id"
            :rules="[rules.required]"
            :selected-value="termsOfDelivery.destinationLogisticCountryId"
            @change="termsOfDelivery.destinationLogisticCountryId = arguments[0]"/>
        </v-col>
        </v-row>
        <v-row>
          <v-col cols="10"  class="pb-0 pt-0 ml-6">
            <v-checkbox
              class="mt-0"
              v-model="termsOfDelivery.hazMat"
              @change="termsOfDelivery.hazMat = true"
              label="Is HazMat"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-select-with-logistics-master-data
              item-label="Packing mode"
              :items="packingMode"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
              logistic-lead-time-to-display="packingMode"
              :selected-value="termsOfDelivery.packingModeId"
              @change="termsOfDelivery.packingModeId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10"  class="pb-0 pt-0 ml-6">
            <base-input-select-with-logistics-master-data
              item-label="Mode of transport category"
              :items="modeOfTransportCategory"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
              logistic-lead-time-to-display="motCategory"
              :selected-value="termsOfDelivery.modeOfTransportCategoryId"
              @change="termsOfDelivery.modeOfTransportCategoryId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10"  class="pb-0 pt-0 ml-6">
            <base-input-select-with-logistics-master-data
              item-label="Mode of transport"
              :items="modeOfTransports"
              item-text="name"
              item-value="id"
              :rules="[rules.required]"
              logistic-lead-time-to-display="mot"
              :selected-value="termsOfDelivery.modeOfTransportId"
              @change="termsOfDelivery.modeOfTransportId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10"  class="pb-0 pt-0 ml-6">
            <base-input-select
              item-label="Inco term"
              :items="incoTerm"
              item-text="description"
              item-value="id"
              :rules="[rules.required]"
              :selected-value="termsOfDelivery.incoTermId"
              @change="termsOfDelivery.incoTermId = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-select-date-picker
              item-label="Valid from"
              :selected-value="termsOfDelivery.validFrom"
              :rules="[rules.required]"
              @change="termsOfDelivery.validFrom = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" c class="pb-0 pt-0 ml-6">
            <base-input-select-date-picker
              item-label="Valid to"
              :selected-value="termsOfDelivery.validTo"
              :rules="[rules.fromDateIsBeforeToDate]"
              @change="termsOfDelivery.validTo = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10" class="pb-0 pt-0 ml-6">
            <base-input-select-date-picker
              :min-today="true"
              item-label="Publish time"
              @change="termsOfDelivery.publishTime = arguments[0]"/>
          </v-col>
        </v-row>
      </v-card>
      <base-input-primary-button
        label="save"
        icon="mdi-arrow-right-bold-circle"
        @click="save"/>

      <base-input-secondary-button
        label="cancel"
        icon="mdi-cancel"
        @click="cancel"/>
    </v-col>
  </v-row>
</template>

<script>
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker.vue'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton.vue'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton.vue'
import BaseInputSelectWithLogisticsMasterData from '@/views/termsofdelivery/SelectWithLogisticsMasterData.vue'
import BaseInputSelect from '@/base/input/BaseInputSelect.vue'

export default {
  name: 'CreateTermsOfDeliveryDialog',
  components: {
    BaseInputSelect,
    BaseInputSelectWithFlag,
    BaseInputSelectWithLogisticsMasterData,
    BaseInputSelectDatePicker,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseInputSecondaryButton
  },
  props: {
    termsOfDelivery: {
      type: Object,
      require: true
    },
    countryOfDelivery: {
      type: Array,
      require: true
    },
    destinationLogisticsCountry: {
      type: Array,
      require: true
    },
    modeOfTransports: {
      type: Array,
      require: true
    },
    modeOfTransportCategory: {
      type: Array,
      require: true
    },
    incoTerm: {
      type: Array,
      require: true
    },
    packingMode: {
      type: Array,
      require: true
    },
    rules: {
      type: Object,
      require: false
    },
    validationErrorMessages: {
      type: Array,
      require: false
    }
  },
  methods: {
    save () {
      this.$emit('save')
    },
    cancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
