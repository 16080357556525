<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Import listeners"
        icon="mdi-connection"/>

        <table width="40%" class="ml-2">
          <tr>
            <td><a @click="startAllImportListenersInOrder">Start all listeners in order</a></td>
            <td><a @click="stopAllImportListenersInOrder">Stop all listeners in order</a></td>
          </tr>
        </table>

      <v-data-table
        :headers="tableHeaders"
        :items="importListeners"
        :hide-default-footer="true"
        :search="search">

        <template v-slot:item.started="{ item }">
          <v-icon title="Running" v-if="item.running" color="green">mdi-check</v-icon>
          <v-icon title="Stopped" v-if="!item.running" color="red">mdi-close-octagon</v-icon>
        </template>

        <template v-slot:item.action="{ item }">
          <v-btn icon v-if="item.running" @click="stopImportListener(item)">
            <v-icon title="Stop" color="red">mdi-stop</v-icon>
          </v-btn>
          <v-btn icon v-if="!item.running" @click="startImportListener(item)">
            <v-icon title="Start" color="green">mdi-play</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

  </v-container>
</template>
<script>

import BaseCardTitle from '@/base/card/BaseCardTitle'
import axios from 'axios'

export default {
  name: 'ImportListeners',
  components: {
    BaseCardTitle
  },
  data () {
    return {
      isLoaded: true,
      importListeners: [],
      search: '',
      tableHeaders: [
        { text: 'Queue name', sortable: false, value: 'name' },
        { text: 'Started', sortable: false, value: 'started' },
        { text: 'Action', sortable: false, value: 'action' }
      ]
    }
  },
  mounted () {
    this.loadImportListeners()
  },
  methods: {
    stopAllImportListenersInOrder () {
      axios.get('/api/touchpoint/stopAllImportListenersInOrder')
        .then(() => this.loadImportListeners())
        .catch(e => console.log(e))
    },

    startAllImportListenersInOrder () {
      axios.get('/api/touchpoint/startAllImportListenersInOrder')
        .then(() => this.loadImportListeners())
        .catch(e => console.log(e))
    },

    stopImportListener (item) {
      axios.get('/api/touchpoint/stopImportListener/' + item.name)
        .then(() => this.loadImportListeners())
        .catch(e => console.log(e))
    },

    startImportListener (item) {
      axios.get('/api/touchpoint/startImportListener/' + item.name)
        .then(() => this.loadImportListeners())
        .catch(e => console.log(e))
    },

    loadImportListeners () {
      this.importListeners = []
      axios.get('/api/touchpoint/importListeners')
        .then(response => ([...response.data]).forEach(data => this.importListeners.push(data)))
        .catch(e => console.log(e))
    }
  }
}

</script>
<style scoped>
</style>
