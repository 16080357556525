<template>
  <div>
    <v-select
      filled
      :items="freightServices"
      item-text="name"
      item-value="id"
      :label="itemLabel"
      :menu-props="{ minHeight: 0 }"
      :rules="rules"
      v-model="selectValueItem"
      :validate-on-blur=true
      multiple
      clearable>
      <template v-slot:append-item>
        <v-treeview
          selectable
          v-model="selectValueItem"
          item-children="freightServices"
          :items="groupedFreightServices"
          @input="$emit('change', selectValueItem)">
          <template v-slot:prepend="{ item }">
            <v-icon v-if="resolveIcon(item.transportMode)">
               {{ resolveIcon(item.transportMode) }}
            </v-icon>
          </template>
        </v-treeview>
      </template>
      <template v-slot:selection="{ item, index }">
        <span v-if="index === 0">{{ item.name }}</span>
        <span v-if="index === 1" class="ml-2 grey--text caption">
          (+{{ selectValueItem.length - 1 }} others)
        </span>
      </template>
      <template v-slot:no-data>
        <div></div>
      </template>
      <template v-slot:item>
        <div></div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'

export default {
  name: 'BaseInputMultiSelectWithFreightService',
  mixins: [transportModeIconResolverMixin],
  props: {
    groupedFreightServices: {
      type: Array,
      require: true
    },
    freightServices: {
      type: Array,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      type: Array,
      require: false
    },
    rules: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      selectValueItem: [],
      emptyList: []
    }
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  },
  mounted () {
    this.selectValueItem = this.selectedValue
  }
}
</script>

<style scoped>

</style>
