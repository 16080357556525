<template>
  <v-dialog persistent v-model="dialog" max-width="750">
    <v-card v-if="createLLtsDailog">
      <v-card-text>
        <v-row justify="center" class="pt-16 mb-5">
          Direct creation of SLLTs will end the existing SLLT associated with the Shipping Lead Time and create a new SLLT with the replaced Shipping Lead time.
          The new SLLT will also be published with today's date.
          <v-row class="mt-4 ml-1">
          Do you want to continue?
          </v-row>
          <v-spacer></v-spacer>
        </v-row>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button @click="$emit('createDirectLLts')" label="Yes"/>
        <base-input-secondary-button @click="$emit('closeSaveAndCreateLLtsDialog')" label="No"/>
      </v-card-actions>
    </v-card>

    <v-card v-if="createLLtsResultDailog">
      <v-card-text>
        <v-row justify="center" class="pt-16 mb-5">
          The new shipping lead times have been created and the previous ones have ended, along with its SLLTs.
          New logistic SLLTs have been created.
        </v-row>
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button @click="goToLogisticLTs()" label="Go to SLLT search"/>
        <base-input-secondary-button @click="$emit('doAnotherCombination')" label="New multi-replace search"/>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
export default {
  name: 'ReplaceShippingLeadTimeDialogStep4',
  components: {
    BaseInputSecondaryButton,
    BaseInputPrimaryButton
  },
  props: {
    dialog: {
      type: Boolean,
      require: true
    },
    createLLtsDailog: {
      type: Boolean,
      require: true
    },
    createLLtsResultDailog: {
      type: Boolean,
      require: true
    }
  },
  methods: {
    goToProposedLTs () {
      this.$router.push({ name: 'SearchProposedLogisticLeadTime' })
    },
    goToLogisticLTs () {
      this.$router.push({ name: 'SearchLogisticsLeadTime' })
    }
  }
}
</script>

<style scoped>

</style>
