const initState = () => {
  return {
    additionalShippingLeadTimeSearchCriteria: {
      freightServiceIds: [],
      placeOfDeliveryId: '',
      placeOfLoadingId: '',
      placeOfLoadingCountryId: '',
      placeOfDeliveryCountryId: '',
      active: 'true'
    },
    additionalShippingLeadTimeSearchResults: {
      items: [],
      totalItems: 0,
      isInit: true
    }
  }
}

const searchAdditionalShippingLeadTime = {
  state: initState(),
  mutations: {
    updateAdditionalShippingLeadTimeSearchResults (state, searchResults) {
      state.additionalShippingLeadTimeSearchResults = searchResults
    },
    resetAdditionalShippingLeadTimeSearchCriteria (state) {
      Object.assign(state.additionalShippingLeadTimeSearchCriteria, initState().additionalShippingLeadTimeSearchCriteria)
    }
  },
  getters: {
    additionalShippingLeadTimeSearchCriteria: state => state.additionalShippingLeadTimeSearchCriteria,
    additionalShippingLeadTimeSearchResults: state => state.additionalShippingLeadTimeSearchResults
  },
  actions: {
  }
}

export default searchAdditionalShippingLeadTime
