<template>
  <div class="text-start">
     <span v-for="(transportMode, index) in freightService.transportModes" v-bind:key="index">
        <v-icon class="mr-1">{{ resolveIcon(transportMode) }}</v-icon>
      </span>
     <span> {{freightService.name}} </span>
  </div>
</template>

<script>
import { transportModeIconResolverMixin } from '@/mixins/transportModeIconResolverMixin'

export default {
  name: 'BaseLabelWithFreightService',
  mixins: [transportModeIconResolverMixin],
  props: {
    freightService: {
      type: Object,
      require: true
    }
  }
}
</script>

<style scoped>
.normal-flag {
    margin: -15px;
}

</style>
