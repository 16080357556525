<template>
  <v-container v-if="isLoaded">
    <v-toolbar flat>
      <base-input-primary-button v-if="canUserCreateUpdate"
          label="Create mode of transport category"
          @click="openCreateUpdateDialog"/>
      <base-table-excel-exporter
        v-if="this.modeOfTransportCategoryStatus ? this.activeModeOfTransportCategories.length : this.modeOfTransportCategories.length"
        :excel-data="this.excelData"
        :fetch-all="true"
        file-name="ModeOfTransportCategoriesList"
        @fetchAll="downLoadExcel"/>
      <v-spacer></v-spacer>
      <v-checkbox
        class="mt-0"
        v-model="modeOfTransportCategoryStatus"
        @change="fetchModeOfTransportCategories()"
        label="Only active Mode Of Transport Categories"/>
    </v-toolbar>
    <base-table-paged-with-search
      :items="this.modeOfTransportCategoryStatus ? this.activeModeOfTransportCategories : this.modeOfTransportCategories"
      :table-headers="headers"
      :table-heading="this.heading"
    >
      <template v-slot:item.name="{ item }">
        <base-label-with-mode-of-transport-category :mode-of-transport-category="item"/>
      </template>
      <template v-slot:item.creationTime="{ item }">
        {{ item.creationTime | formatDate }}
      </template>
      <template v-slot:item.modificationTime="{ item }">
        {{ item.modificationTime | formatDate }}
      </template>
      <template v-slot:item.action="{ item }">
        <base-tool-tip v-if="canUserCreateUpdate"
                       icon="mdi-pencil"
                       label="Edit"
                       @click="editItem(item, true)">
        </base-tool-tip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-switch
          v-model="item.active"
          @change="updateModeOfTransportCategoryStatus(item)">
        </v-switch>
      </template>
    </base-table-paged-with-search>
    <v-form ref='create-update-form'>
      <base-dialog-plain
        :dialog="dialog"
        :ok-action-label="!this.modeOfTransportCategoriesInContext.id ? 'Save' : 'Update'"
        :persistent="true"
        @cancel="close"
        @ok="save">
        <v-alert v-if="errorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="errorMessages in errorMessages" v-bind:key="errorMessages">
            <li>{{errorMessages}}</li>
          </ul>
        </v-alert>
        <base-card-title
          :label="formTitle"/>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="11">
                <v-text-field v-if="this.modeOfTransportCategoriesInContext.id"
                  filled
                  id="externalId"
                  v-model="modeOfTransportCategoriesInContext.externalId"
                  :disabled="!!this.modeOfTransportCategoriesInContext.id"
                  label="Id"
                ></v-text-field>
                <v-text-field
                  filled
                  id="name"
                  v-model="modeOfTransportCategoriesInContext.name"
                  :rules="[rules.required]"
                  hint="Name must not exceed 100 characters"
                  label="Name"
                  :counter="100"
                  maxlength="100"
                ></v-text-field>
                <v-text-field
                  filled
                  id="priority"
                  v-model="modeOfTransportCategoriesInContext.priority"
                  :rules="[rules.priority]"
                  hint="Priority must be numeric"
                  label="Priority"
                ></v-text-field>
                <v-row>
                  <v-card-text class="pl-3 pb-0 mb-0 subtitle-1">Active ?</v-card-text>
                  <v-switch class="pl-3 pt-1 mt-1"
                            v-model="modeOfTransportCategoriesInContext.active">
                  </v-switch>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </base-dialog-plain>
    </v-form>
    <base-dialog-ok
      :dialog="successMessageDialog"
      :persistent="true"
      label="Mode of transport category saved successfully."
      @ok="() => this.successMessageDialog = false"
    />
  </v-container>

</template>

<script>
import BaseTablePagedWithSearch from '@/base/table/BaseTablePagedWithSearch'
import BaseTableExcelExporter from '@/base/table/BaseTableExcelExporter'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import axios from 'axios'
import BaseDialogPlain from '@/base/dialog/BaseDialogPlain'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import BaseLabelWithModeOfTransportCategory from '@/base/label/BaseLabelWithModeOfTransportCategory'
import { mapGetters } from 'vuex'

export default {
  name: 'ViewCreateOrUpdateModeOfTransportCategory',
  components: {
    BaseDialogPlain,
    BaseTablePagedWithSearch,
    BaseTableExcelExporter,
    BaseDialogOk,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseToolTip,
    BaseLabelWithModeOfTransportCategory
  },
  data: () => ({
    isLoaded: false,
    dialog: false,
    modeOfTransportCategoryStatus: true,
    excelData: [],
    errorMessages: [],
    successMessageDialog: false,
    heading: 'Mode Of Transport Categories',
    headers: [
      { text: 'Id', sortable: true, value: 'externalId' },
      { text: 'Name', sortable: true, value: 'name' },
      { text: 'Priority', sortable: true, value: 'priority' },
      { text: 'Created', sortable: true, value: 'creationTime' },
      { text: 'Last modified', sortable: true, value: 'modificationTime' },
      { text: 'Active?', sortable: true, value: 'active' },
      { text: 'Action', align: 'center', sortable: false, value: 'action' }
    ],
    modeOfTransportCategoriesInContext: {},
    rules: {
      required: value => !!value || 'Required.',
      priority: value => {
        const regexPattern = /^\d+$/
        return regexPattern.test(value) || 'Priority must be numeric'
      }
    }
  }),

  computed: {
    formTitle () {
      return this.modeOfTransportCategoriesInContext.id ? 'Update Mode Of Transport Category' : 'Create Mode Of Transport Category'
    },
    ...mapGetters([
      'isMemberOfAny',
      'getUserRoles',
      'activeModeOfTransportCategories',
      'modeOfTransportCategories'
    ]),
    canUserCreateUpdate () {
      const rolesAllowed = ['ROLE_TECH_ADMIN', 'ROLE_ADMIN']
      return this.isMemberOfAny(rolesAllowed)
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getUser'),
      this.$store.dispatch('getAllModeOfTransportCategories')
    ]).then(() => {
      this.isLoaded = true
    })
  },
  watch: {
    dialog (val) {
      this.errorMessages = []
      val || this.close()
    }
  },
  methods: {

    editItem (item, editDialogPopUp) {
      this.modeOfTransportCategoriesInContext = {
        ...item
      }
      editDialogPopUp ? this.dialog = editDialogPopUp : this.updateModeOfTransportCategory()
    },

    save () {
      if (this.$refs['create-update-form'].validate()) {
        this.errorMessages = []
        this.modeOfTransportCategoriesInContext.id ? this.updateModeOfTransportCategory() : this.createModeOfTransportCategory()
      }
    },

    createModeOfTransportCategory () {
      if (!this.errorMessages.length) {
        axios.post('/api/touchpoint/logistics-master-data/mode-of-transport-categories/create', {
          name: this.modeOfTransportCategoriesInContext.name,
          priority: this.modeOfTransportCategoriesInContext.priority,
          active: this.modeOfTransportCategoriesInContext.active
        })
          .then(response => {
            if (response.data.status === 'SUCCESS') {
              this.success()
            } else {
              this.errorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      }
    },

    updateModeOfTransportCategory () {
      axios.put('/api/touchpoint/logistics-master-data/mode-of-transport-categories/update', {
        id: this.modeOfTransportCategoriesInContext.id,
        externalId: this.modeOfTransportCategoriesInContext.externalId,
        name: this.modeOfTransportCategoriesInContext.name,
        priority: this.modeOfTransportCategoriesInContext.priority,
        active: this.modeOfTransportCategoriesInContext.active
      })
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.success()
          } else {
            this.errorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },

    success () {
      this.$store.commit('reloadModeOfTransportCategories')
      this.close()
      this.fetchModeOfTransportCategories()
      this.successMessageDialog = true
    },

    close () {
      this.modeOfTransportCategoriesInContext = {}
      this.dialog = false
      this.$refs['create-update-form'].resetValidation()
    },

    openCreateUpdateDialog () {
      this.modeOfTransportCategoriesInContext = {
        active: true
      }
      this.dialog = true
    },

    downLoadExcel () {
      this.excelData = []
      this.modeOfTransportCategories.forEach(data => {
        this.excelData.push({
          Id: data.externalId,
          Name: data.name,
          Priority: data.priority,
          Active: data.active.toString().toLowerCase(),
          Created: this.$options.filters.formatDate(data.creationTime),
          'Last modified': this.$options.filters.formatDate(data.modificationTime)
        })
      })
    },

    updateModeOfTransportCategoryStatus (item) {
      axios.put('/api/touchpoint/logistics-master-data/mode-of-transport-categories/update', item)
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.success()
          } else {
            this.errorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },

    fetchModeOfTransportCategories () {
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>

</style>
