<template>
    <v-btn
      tile
      color="red darken-2"
      elevation="0"
      class="white--text"
      @click="click">
      {{ label }}
      <v-icon v-if="icon" right dark>
        {{ icon }}
      </v-icon>
    </v-btn>
</template>

<script>
export default {
  name: 'BaseInputPrimaryButton',
  props: {
    label: {
      type: String,
      require: true
    },
    icon: {
      type: String,
      require: false
    }
  },
  methods: {
    click () {
      this.$emit('click')
    }
  }
}
</script>

<style scoped>

</style>
