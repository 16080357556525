<template>
  <v-container>
    <v-form ref="bulk-update-form">
      <v-card class="mt-5">
        <base-card-title
          label="Bulk Event Send of Logistics Lead Time"
          icon="mdi-wrench"/>
        <v-card-text class="pl-8 pr-8">
          <v-row class="pt-3">
            <v-col md="4" class="pb-0 pt-0">
              <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
                <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
                  <li>{{ validationErrorMessage.message }}</li>
                </ul>
              </v-alert>
              <base-input-select-date-picker
                item-label="Valid from date"
                :selected-value="bulkEventSendCommand.validFromDate"
                @change="bulkEventSendCommand.validFromDate = arguments[0]"
                :rules="[ rules.fromDateIsBeforeToToday]"
              />
              <base-input-select-date-picker
                item-label="Publish from date"
                :selected-value="bulkEventSendCommand.publishFromDate"
                @change="bulkEventSendCommand.publishFromDate = arguments[0]"
                :rules="[ rules.fromDateIsBeforeToToday]"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <div class="mt-2">
        <base-input-primary-button label="Bulk event send" @click="extracted.call(this);"/>
      </div>

      <base-dialog-confirm
        :confirm-dialog="confirmDialog"
        label="Do you really want to bulk send LLT events? This will cause all subscribing systems to receive event."
        @confirm="bulkEventSendLeadTimes"
        @cancel="() => this.confirmDialog = false"/>

      <base-dialog-ok
        label="Logistic lead time events have been sent!"
        @ok="closeBulkEventSendLeadTimeDialog"
        :dialog="eventBulkSendOkDialog"/>
    </v-form>
  </v-container>
</template>
<script>

import axios from 'axios'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import BaseCardTitle from '../../base/card/BaseCardTitle'
import BaseInputPrimaryButton from '../../base/input/BaseInputPrimaryButton'
import { validateDate } from '@/validation/customvalidation'

export default {
  name: 'BulkEventSendLogisticsLeadTime',
  components: {
    BaseInputSelectDatePicker,
    BaseCardTitle,
    BaseInputPrimaryButton,
    BaseDialogConfirm,
    BaseDialogOk
  },
  data () {
    return {
      bulkEventSendCommand: {
        validFromDate: '',
        publishFromDate: ''
      },
      validationErrorMessages: [],
      confirmDialog: false,
      eventBulkSendOkDialog: false,
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToToday: value => validateDate(value, new Date().toISOString().split('T')[0]) || 'Date cannot be after today.'
      }
    }
  },
  methods: {
    bulkEventSendLeadTimes () {
      axios.post('/api/touchpoint/logistic-lead-time/bulk-event-send', this.bulkEventSendCommand)
        .then((response) => {
          if (response.status === 200) {
            this.confirmDialog = false
            this.eventBulkSendOkDialog = true
          } else {
            this.showErrorMessage = true
            window.scrollTo(0, 0)
          }
        }).catch(e => console.log(e))
    },
    closeBulkEventSendLeadTimeDialog () {
      this.eventBulkSendOkDialog = false
    },
    extracted () {
      const formOk = this.$refs['bulk-update-form'].validate()
      if (formOk) {
        this.confirmDialog = true
      }
    }
  }
}

</script>
<style scoped>
</style>
