<template>
    <v-text-field
      :value="value"
      :label="itemLabel"
      prepend-icon="mdi-calendar"
      :hint="hint"
      readonly
      filled
      persistent-hint
      disabled/>
</template>

<script>
export default {
  name: 'BaseInputSelectDatePickerReadOnly',
  props: {
    itemLabel: {
      type: String,
      require: true
    },
    value: {
      type: String,
      require: true
    }
  },
  computed: {
    hint () {
      return this.itemLabel + ' is not allowed to be updated'
    }
  }
}
</script>

<style scoped>

</style>
