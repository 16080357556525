<template>
  <v-dialog persistent
    v-model="sendDialog" max-width="750">
    <v-card>
      <v-card-text>
        <v-row justify="center" class="pt-10 mb-5">
          <v-icon size="80" color="green">mdi-checkbox-marked-circle</v-icon>
        </v-row>
        <v-row justify="center" class="mb-5">
          {{ label }}
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-primary-button @click="$emit('create')" icon="mdi-file-plus-outline" label="Create new"/>
        <base-input-primary-button v-if="showCopy" @click="$emit('copy')" icon="mdi-file-multiple-outline" label="Create copy"/>
        <base-input-secondary-button @click="$emit('search')" icon="mdi-magnify" label="Search"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
export default {
  name: 'BaseDialogSend',
  components: {
    BaseInputSecondaryButton,
    BaseInputPrimaryButton
  },
  props: {
    sendDialog: {
      type: Boolean,
      require: true
    },
    label: {
      type: String,
      require: true
    },
    showCopy: {
      type: Boolean,
      require: false,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
