<template>
  <v-container>
    <v-row>
      <v-col class="mt-8">
        <v-alert v-if="validationErrorMessages.length > 0" color="red lighten-2" dark>
          <ul v-for="validationErrorMessage in validationErrorMessages" v-bind:key="validationErrorMessage">
            <li>{{validationErrorMessage.message}}</li>
          </ul>
        </v-alert>
        <v-stepper v-model="stepper">
          <create-shipping-lead-time-stepper-head
            :stepper="stepper"/>
          <v-divider></v-divider>
          <v-stepper-items>
            <v-stepper-content step="1">
              <create-shipping-lead-time-step1
                ref="step1"
                :shipping-lead-time="shippingLeadTime"
                :geographical-countries="geographicalCountries"
                :places-of-loading="placesOfLoading"
                :service-providers="activeServiceProviders"
                :freight-services="freightServices"
                :places-of-delivery="placesOfDelivery"
                :carriers="carriers"
                :service-codes="serviceCodes"
                @freightServiceUpdated = "clearEvents" />
            </v-stepper-content>

            <v-stepper-content step="2">
              <create-shipping-lead-time-step2
                ref="events"
                :selected-freight-service-id="shippingLeadTime.freightServiceId"
                :freight-services="freightServices"
                @change="updateEvents"/>
            </v-stepper-content>

            <v-stepper-content step="3">
              <create-shipping-lead-time-step3
                :shipping-lead-time="shippingLeadTime"
                :geographical-countries="geographicalCountries"
                :places-of-loading="placesOfLoading"
                :service-providers="activeServiceProviders"
                :freight-services="freightServices"
                :places-of-delivery="placesOfDelivery"
                :carriers="carriers"
                :service-codes="serviceCodes"/>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <div class="mt-2">
        <base-input-primary-button v-if="stepper < 3"
            label="continue"
            icon="mdi-arrow-right-bold-circle"
            @click="nextStep"/>

        <base-input-primary-button v-if="stepper === 3"
          label="save"
          icon="mdi-arrow-right-bold-circle"
          @click="save"/>

        <base-input-secondary-button
            v-if="stepper > 1"
            label="back"
            icon="mdi-arrow-left-bold-circle"
            @click="previousStep"/>

          <base-input-secondary-button
            label="clear"
            icon="mdi-autorenew"
            @click="clear"/>

          <base-input-secondary-button
            label="cancel"
            icon="mdi-cancel"
            @click="cancel"/>
        </div>
      </v-col>
      <v-col cols="5" class="mt-8">
        <create-shipping-lead-time-time-line
          :shipping-lead-time="shippingLeadTime"
          :geographical-countries="geographicalCountries"
          :places-of-loading="placesOfLoading"
          :service-providers="activeServiceProviders"
          :freight-services="freightServices"
          :places-of-delivery="placesOfDelivery"
          :carriers="carriers"/>
      </v-col>
    </v-row>
    <base-dialog-send
      label="Shipping lead time has been created successfully!"
      @create="createNew"
      @copy="copy"
      @search="search"
      :send-dialog="sendDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import CreateShippingLeadTimeStepperHead from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStepperHead'
import CreateShippingLeadTimeTimeLine from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeTimeLine'
import CreateShippingLeadTimeStep1 from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStep1'
import CreateShippingLeadTimeStep2 from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStep2'
import CreateShippingLeadTimeStep3 from '@/components/shippingleadtime/createshippingleadtime/CreateShippingLeadTimeStep3'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateShippingLeadTime',
  components: {
    CreateShippingLeadTimeStep1,
    BaseDialogSend,
    CreateShippingLeadTimeStep3,
    CreateShippingLeadTimeStep2,
    CreateShippingLeadTimeTimeLine,
    CreateShippingLeadTimeStepperHead,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton
  },
  props: {
    copyShippingLeadTimeFrom: {
      type: Array,
      require: false
    }
  },
  data () {
    return {
      validationErrorMessages: [],
      stepper: 1,
      sendDialog: false,
      shippingLeadTime: {
        countryOfDeliveryId: '',
        placeOfLoadingId: '',
        placeOfDeliveryId: '',
        freightServiceId: '',
        serviceProviderId: '',
        carrierId: '',
        service: '',
        validFrom: '',
        validTo: '',
        main: false,
        defaultForCountryOfDelivery: false,
        serviceCode: '',
        shippingLeadTimeEvents: []
      }
    }
  },
  computed: {
    ...mapGetters([
      'geographicalCountries',
      'placesOfLoading',
      'placesOfDelivery',
      'freightServices',
      'activeServiceProviders',
      'carriers',
      'serviceCodes'
    ])
  },
  methods: {
    nextStep () {
      let isValid = false
      if (this.stepper === 1) {
        isValid = this.$refs.step1.validate()
      } else if (this.stepper === 2) {
        isValid = this.$refs.events.validate()
      }

      if (isValid) {
        this.stepper++
      }
    },
    previousStep () {
      this.validationErrorMessages = []
      this.stepper--
    },
    clear () {
      this.clearShippingLeadTimeAttributes()
      this.stepper = 1
    },
    cancel () {
      this.clearShippingLeadTimeAttributes()
      this.$router.push({ name: 'SearchShippingLeadTime' })
    },
    clearShippingLeadTimeAttributes () {
      this.shippingLeadTime = {
        countryOfDeliveryId: '',
        placeOfLoadingId: '',
        placeOfDeliveryId: '',
        freightServiceId: '',
        serviceProviderId: '',
        carrierId: '',
        service: '',
        validFrom: '',
        validTo: '',
        main: false,
        defaultForCountryOfDelivery: false,
        serviceCode: '',
        shippingLeadTimeEvents: []
      }
      this.sendDialog = false
      this.clearEvents()
    },
    updateEvents (events) {
      this.shippingLeadTime.shippingLeadTimeEvents = [...events]
    },
    clearEvents () {
      this.$refs.events.clearEvents()
    },
    save () {
      axios.post('/api/touchpoint/shipping-lead-time', this.shippingLeadTime)
        .then(response => {
          if (response.data.status === 'SUCCESS') {
            this.sendDialog = true
          } else {
            this.validationErrorMessages = response.data.errorMessageList
            window.scrollTo(0, 0)
          }
        })
        .catch(e => console.log(e))
    },
    createNew () {
      this.cancel()
    },
    copy () {
      this.stepper = 1
      this.sendDialog = false
    },
    search () {
      this.$router.push({ name: 'SearchShippingLeadTime' })
    },
    mapShippingLeadTimeResponse (data) {
      this.shippingLeadTime.countryOfDeliveryId = data.countryOfDelivery.id
      this.shippingLeadTime.placeOfLoadingId = data.placeOfLoading.id
      this.shippingLeadTime.placeOfDeliveryId = data.placeOfDelivery.id
      this.shippingLeadTime.freightServiceId = data.freightService.id
      this.shippingLeadTime.serviceProviderId = data.serviceProvider.id
      this.shippingLeadTime.carrierId = data.carrier && data.carrier.id
      this.shippingLeadTime.service = data.service
      this.shippingLeadTime.serviceCode = data.serviceCode && data.serviceCode.name
      this.shippingLeadTime.main = data.main
      this.shippingLeadTime.defaultForCountryOfDelivery = data.defaultForCountryOfDelivery
      this.shippingLeadTime.validFrom = data.validFrom
      this.shippingLeadTime.validTo = data.validTo
    }
  },
  async mounted () {
    // Move to support data
    if (this.$route.params.id != null) {
      axios.get('/api/touchpoint/shipping-lead-time/' + this.$route.params.id)
        .then((response) => this.mapShippingLeadTimeResponse(response.data))
        .catch(e => console.log(e))
    }

    await axios.all([
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllPlacesOfDelivery'),
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllActiveServiceProviders'),
      this.$store.dispatch('getAllCarriers'),
      this.$store.dispatch('getAllServiceCodes')
    ])

    if (this.copyShippingLeadTimeFrom) {
      this.shippingLeadTime = this.copyShippingLeadTimeFrom
      this.$refs.events.addEvents(this.shippingLeadTime.shippingLeadTimeEvents)
    }
  }
}
</script>

<style scoped>
</style>
