<template>
  <v-container>
      <v-row>
        <v-col class="mt-8">
          <v-card class="mb-6">
            <base-card-title
              label="Carrier base load"
              icon="mdi-calendar-export"/>
            <div class="text-h6 mb-3 mt-4 ml-4">Carrier details</div>
            <v-row >
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-text-area-by-new-line
                  item-label="Carrier ids"
                  hint="Separate by new line"
                  :value="search.ids"
                  @blur="search.ids = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-text-area-by-new-line
                  item-label="Carrier codes"
                  hint="Separate by new line"
                  :value="search.codes"
                  @blur="search.codes = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row >
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-text-area-by-new-line
                  item-label="Carrier names"
                  hint="Separate by new line"
                  :value="search.names"
                  @blur="search.names = arguments[0]"/>
              </v-col>
            </v-row>
            <div class="text-h6 mb-3 mt-4 ml-4">Creation time</div>
            <v-row >
              <v-col cols="10" class="pb-0 pt-4 ml-6">
                <base-input-select-date-picker
                  item-label="Creation time from"
                  :selected-value="search.creationTimeFrom"
                  @change="search.creationTimeFrom = arguments[0]"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="10" class="pb-0 pt-0 ml-6">
                <base-input-select-date-picker
                  item-label="Creation time to"
                  :selected-value="search.creationTimeTo"
                  @change="search.creationTimeTo = arguments[0]"/>
              </v-col>
            </v-row>
          </v-card>

          <base-input-primary-button
            label="Start BaseLoad"
            icon="mdi-arrow-right-bold-circle"
            @click="start" />
        </v-col>

        <v-col cols="5" class="mt-8">
          <base-load-status
            :base-load-statuses="baseLoadStatuses"
            @baseLoadUpdate="baseLoadStatusRefresh"
            complete-url="/api/touchpoint/carriers/base-loads/complete"
            cancel-url="/api/touchpoint/carriers/base-loads/cancel"/>
        </v-col>

        <base-dialog-ok
          :dialog="dialogStartBaseLoad"
          label="Base load has been submitted"
          @ok="() => this.dialogStartBaseLoad = false"/>

      </v-row>
  </v-container>
</template>

<script>

import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseLoadStatus from '@/components/baseload/BaseLoadStatus'
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import BaseInputTextAreaByNewLine from '@/base/input/BaseInputTextAreaByNewLine'
export default {
  name: 'CarrierBaseLoad',
  components: {
    BaseInputTextAreaByNewLine,
    BaseDialogOk,
    BaseLoadStatus,
    BaseInputSelectDatePicker,
    BaseInputPrimaryButton,
    BaseCardTitle
  },
  data () {
    return {
      search: {
        ids: [],
        codes: [],
        names: [],
        creationTimeFrom: '',
        creationTimeTo: ''
      },
      baseLoadStatuses: {},
      dialogStartBaseLoad: false,
      baseLoadRefreshRequest: {}
    }
  },
  mounted () {
    this.updateBaseLoadStatus()
    this.baseLoadRefreshRequest = setInterval(this.updateBaseLoadStatus, 5000)
  },
  beforeDestroy () {
    clearInterval(this.baseLoadRefreshRequest)
  },
  methods: {
    start () {
      axios.post('/api/touchpoint/carriers/base-loads/run', this.search)
        .then((response) => {
          this.updateBaseLoadStatus()
          this.dialogStartBaseLoad = true
        })
        .catch(e => console.log(e))
    },
    updateBaseLoadStatus () {
      axios.get('/api/touchpoint/carriers/base-loads/statuses', { headers: { silent: 'silent' } })
        .then((response) => { this.baseLoadStatuses = response.data })
        .catch(e => console.log(e))
    },
    baseLoadStatusRefresh (baseLoads) {
      this.baseLoadStatuses = baseLoads
    }
  }
}
</script>

<style scoped>

</style>
