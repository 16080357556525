<template>
  <div>
    <div v-for="freightService in freightServices" v-bind:key="freightService.id">
      <base-label-with-freight-service :freight-service="freightService"/>
    </div>
  </div>
</template>

<script>
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'

export default {
  name: 'BaseLabelWithFreightServices',
  components: { BaseLabelWithFreightService },
  props: {
    freightServices: {
      type: Array,
      require: true
    }
  }
}
</script>

<style scoped>
</style>
