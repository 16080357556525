<template>
  <v-text-field
    filled
    :label="itemLabel"
    :rules="rules"
    v-model="theValue"
    :hint="hint"
    :persistent-hint="showHint"
    @blur="$emit('blur', theValue)"
    clearable>
  </v-text-field>
</template>

<script>
export default {
  name: 'BaseInputTextField',
  props: {
    itemLabel: {
      type: String,
      require: true
    },
    value: {
      type: [String, Number],
      require: false
    },
    rules: {
      type: Array,
      require: false
    },
    hint: {
      String,
      require: false
    }
  },
  data () {
    return {
      theValue: ''
    }
  },
  mounted () {
    this.theValue = this.value
  },
  watch: {
    value (val) {
      this.theValue = val
    }
  },
  computed: {
    showHint () {
      return !!this.hint
    }
  }
}
</script>

<style scoped>

</style>
