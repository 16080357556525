<template>
  <v-container>
    <v-form ref="terms-of-delivery-create-form">
      <create-terms-of-delivery-dialog
        :terms-of-delivery="termsOfDelivery"
        :country-of-delivery="geographicalCountries"
        :destination-logistics-country="geographicalCountries"
        :mode-of-transports="activeModeOfTransports"
        :mode-of-transport-category="activeModeOfTransportCategories"
        :packing-mode="packingModes"
        :inco-term="activeIncoTerms"
        :validation-error-messages="validationErrorMessages"
        :rules="rules"
        :timelineRequired="true"
        @save="save"
        @cancel="cancel"/>
    </v-form>
    <base-dialog-send
      :send-dialog="sendDialog"
      :show-copy=false
      label="Terms of delivery has been saved successfully!"
      @create="createNew"
      @search="search"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseDialogSend from '@/base/dialog/BaseDialogSend'
import CreateTermsOfDeliveryDialog from '@/components/termsofdelivery/createtermsofdelivery/CreateTermsOfDeliveryDialog.vue'
import { validateDate } from '@/validation/customvalidation'
import { mapGetters } from 'vuex'

export default {
  name: 'CreateTermsOfDelivery',
  components: { CreateTermsOfDeliveryDialog, BaseDialogSend },
  data () {
    return {
      sendDialog: false,
      validationErrorMessages: [],
      termsOfDelivery: {
        countryOfDeliveryId: '',
        destinationLogisticCountryId: '',
        modeOfTransportId: '',
        modeOfTransportCategoryId: '',
        packingModeId: '',
        incoTermId: '',
        hazMat: '',
        validFrom: '',
        validTo: '',
        publishTime: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        selected: value => value.length > 0 || 'Select one of the item.',
        fromDateIsBeforeToDate: value => validateDate(this.termsOfDelivery.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  computed: {
    ...mapGetters([
      'getUserRoles',
      'geographicalCountries',
      'packingModes',
      'activeIncoTerms',
      'activeModeOfTransports',
      'activeModeOfTransportCategories'
    ])
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllIncoTerms'),
      this.$store.dispatch('getAllPackingModes'),
      this.$store.dispatch('getAllModeOfTransports'),
      this.$store.dispatch('getAllModeOfTransportCategories')
    ]).then(() => {
      this.isLoaded = true
    })
  },
  methods: {
    validate () {
      return this.$refs['terms-of-delivery-create-form'].validate()
    },
    save () {
      if (this.validate()) {
        axios.post('/api/touchpoint/terms-of-delivery/create', this.termsOfDelivery)
          .then((response) => {
            if (response.data.status === 'SUCCESS') {
              this.sendDialog = true
            } else {
              this.validationErrorMessages = response.data.errorMessageList
              window.scrollTo(0, 0)
            }
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    cancel () {
      this.$router.push({ name: 'SearchTermsOfDelivery' })
    },
    createNew () {
      this.sendDialog = false
      this.termsOfDelivery = {
        countryOfDeliveryId: '',
        destinationLogisticCountryId: '',
        modeOfTransportId: '',
        modeOfTransportCategoryId: '',
        packingModeId: '',
        incoTermId: '',
        hasMat: '',
        validFrom: '',
        validTo: '',
        publishTime: ''
      }
    },
    search () {
      this.$router.push({ name: 'SearchTermsOfDelivery' })
    }
  }
}
</script>

<style scoped>
</style>
