<template>
  <v-dialog
    v-model="viewDialog"
    scrollable
    persistent
    max-width="750px"
    @keydown.esc="viewDialog = false">
    <v-card v-if="viewDialog">
      <v-card-title class="label">
        PROPOSED HAULAGE LEAD TIME DETAILS
      </v-card-title>
      <v-divider class="mb-2"></v-divider>
      <v-card-text style="height: 200px;" class="text-body-1 text--primary">

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Arrival point
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black">
            <base-label-with-flag
              v-if="viewDialogHaulageLeadTime.arrivalPoint"
              :item-country="viewDialogHaulageLeadTime.arrivalPoint.location.code"
              :item-label="viewDialogHaulageLeadTime.arrivalPoint.description"/>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Inbound freight service
          </v-col>
          <v-col class="pt-2 pb-2 pr-0">
            <base-label-with-freight-service :freightService="viewDialogHaulageLeadTime.freightService"/>
          </v-col>
        </v-row>

        <v-divider class="mt-2 mb-2"></v-divider>

        <v-row class="mt-1 mr-0" dense>
          <v-col cols="5" class="ml-8 pt-2 pb-2">
            Valid from:
          </v-col>
          <v-col class="pt-2 pb-2 pr-0 text--black" v-if="viewDialogHaulageLeadTime.validFrom">
            <v-icon medium>mdi-clock</v-icon> {{ viewDialogHaulageLeadTime.validFrom | formatDate }}
          </v-col>
        </v-row>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <base-input-secondary-button label="Close" icon="mdi-close" @click="close"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'

export default {
  name: 'SearchProposedHaulageLeadTimeViewDetailsDialog',
  components: {
    BaseInputSecondaryButton,
    BaseLabelWithFlag,
    BaseLabelWithFreightService
  },
  props: {
    viewDialog: {
      type: Boolean,
      require: true
    },
    viewDialogHaulageLeadTime: {
      type: Object,
      require: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
