<template>
  <v-autocomplete
    filled
    dense
    :items="serviceProvidersItems"
    item-text="description"
    item-value="id"
    :rules="rules"
    :hint="hint"
    :validate-on-blur="true"
    hide-details="auto"
    v-model="selectValueItem"
    :clearable="clearable"
    @change="$emit('change', selectValueItem)">
  </v-autocomplete>
</template>

<script>
export default {
  name: 'BaseInputSelectThinServiceProviders',
  props: {
    serviceProviders: {
      type: Array,
      require: true
    },
    selectedValue: {
      require: false
    },
    rules: {
      type: Array,
      require: false
    },
    hint: {
      type: String,
      require: false
    },
    clearable: {
      type: Boolean,
      require: false,
      default: () => true
    }
  },
  data () {
    return {
      selectValueItem: ''
    }
  },
  mounted () {
    this.selectValueItem = this.selectedValue
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  },
  computed: {
    showHint () {
      return !!this.hint
    },
    serviceProvidersItems () {
      const returnServiceProviders = []

      returnServiceProviders.push({ header: 'Active service providers' })
      this.serviceProviders.filter(serviceProviders => serviceProviders.active === true)
        .forEach(serviceProviders => returnServiceProviders.push(serviceProviders))

      returnServiceProviders.push({ header: 'Inactive service providers' })
      this.serviceProviders.filter(serviceProviders => serviceProviders.active === false)
        .forEach(serviceProviders => returnServiceProviders.push(serviceProviders))

      return returnServiceProviders
    }
  }
}
</script>

<style scoped>

</style>
