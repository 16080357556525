<template>
  <v-dialog
    v-model="createDialog"
    scrollable
    persistent
    max-width="700px"
    @keydown.esc="close">
    <v-container>
      <v-form ref="transit-lead-time-create-form">
        <create-transit-or-proposed-transit-lead-time
          :transit-lead-time="transitLeadTime"
          :freight-services="freightServices"
          :transit-warehouses="transitWarehouses"
          :transport-locations="transportLocations"
          :rules="rules"
          :timeline-required="false"
          @save="save"
          @cancel="cancel"/>
      </v-form>
      <base-dialog-ok
        label="Transit lead time has been created successfully!"
        :max-width="550"
        :persistent="true"
        @ok="close"
        :dialog="confirmationDialog"/>
    </v-container>
  </v-dialog>
</template>

<script>
import CreateTransitOrProposedTransitLeadTime from '@/components/transitleadtime/createtransitleadtime/CreateTransitOrProposedTransitLeadTime'
import { validateDate } from '@/validation/customvalidation'
import axios from 'axios'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'

export default {
  name: 'CreateProposedTransitLeadTimeDialog',
  components: {
    CreateTransitOrProposedTransitLeadTime,
    BaseDialogOk
  },
  props: {
    createDialog: {
      type: Boolean,
      require: true
    },
    createDialogTransitLeadTime: {
      type: Object,
      require: true
    }
  },
  data () {
    return {
      sendDialog: false,
      confirmationDialog: false,
      validationErrorMessages: [],
      transitWarehouses: [],
      transportLocations: [],
      freightServices: [],
      serviceProviders: [],
      carriers: [],
      transitLeadTime: {
        transitWarehouseId: '',
        availablePointId: '',
        placeOfDeliveryId: '',
        inboundFreightServiceId: '',
        outboundFreightServiceId: '',
        arrivalToAvailable: '',
        availableToInTransit: '',
        inTransitToDeparture: '',
        departureToPlaceOfDelivery: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      },
      rules: {
        required: value => !!value || 'Required.',
        fromDateIsBeforeToDate: value => validateDate(this.transitLeadTime.validFrom, value) || 'Valid to date cannot be before valid from date.'
      }
    }
  },
  mounted () {
    axios.get('/api/touchpoint/logical-warehouse')
      .then(response => ([...response.data])
        .filter(value => value.warehouseType === 'TR')
        .forEach(data => this.transitWarehouses.push(data)))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/transportLocations')
      .then(response => ([...response.data]).forEach(data => {
        this.transportLocations.push(data)
      }))
      .catch(e => console.log(e))

    axios.get('/api/touchpoint/freightServices')
      .then(response => ([...response.data]).forEach(data => this.freightServices.push(data)))
      .catch(e => console.log(e))
  },
  watch: {
    createDialog (val) {
      if (val && Object.keys(this.createDialogTransitLeadTime).length > 0) {
        this.transitLeadTime.inboundFreightServiceId = this.createDialogTransitLeadTime.freightService.id
        this.transitLeadTime.availablePointId = this.createDialogTransitLeadTime.availablePoint.id
      }
    }
  },
  methods: {
    validate () {
      return this.$refs['transit-lead-time-create-form'].validate()
    },
    save () {
      if (this.validate()) {
        axios.post('/api/touchpoint/create-from-proposed-transit-lead-time',
          {
            transitLeadTimeCommand: this.transitLeadTime,
            proposedLeadTimeId: this.createDialogTransitLeadTime.id
          })
          .then(() => {
            this.confirmationDialog = true
            this.transitLeadTime = {}
          })
          .catch(e => console.log(e))
      } else {
        window.scrollTo(0, 0)
      }
    },
    close () {
      this.reset()
      this.$refs['transit-lead-time-create-form'].reset()
      this.$emit('close')
    },
    cancel () {
      this.reset()
      this.$refs['transit-lead-time-create-form'].reset()
      this.$emit('cancel')
    },
    reset () {
      this.confirmationDialog = false
      this.transitLeadTime = {
        transitWarehouseId: '',
        availablePointId: '',
        placeOfDeliveryId: '',
        inboundFreightServiceId: '',
        outboundFreightServiceId: '',
        arrivalToAvailable: '',
        availableToInTransit: '',
        inTransitToDeparture: '',
        departureToPlaceOfDelivery: '',
        validFrom: '',
        validTo: '',
        totalDays: ''
      }
    },
    mapTransitLeadTimeResponse (data) {
      this.transitLeadTime.transitWarehouseId = data.transitWarehouse.id
      this.transitLeadTime.availablePointId = data.availablePoint.id
      this.transitLeadTime.placeOfDeliveryId = data.placeOfDelivery.id
      this.transitLeadTime.inboundFreightServiceId = data.inboundFreightService.id
      this.transitLeadTime.outboundFreightServiceId = data.outboundFreightService.id
      this.transitLeadTime.arrivalToAvailable = data.arrivalToAvailable
      this.transitLeadTime.availableToInTransit = data.availableToInTransit
      this.transitLeadTime.inTransitToDeparture = data.inTransitToDeparture
      this.transitLeadTime.departureToPlaceOfDelivery = data.departureToPlaceOfDelivery
      this.transitLeadTime.validFrom = data.validFrom
      this.transitLeadTime.validTo = data.validTo
    }
  }
}
</script>

<style scoped>

</style>
