<template>
  <v-select
    filled
    :items="items"
    :item-text="itemText"
    :item-value="itemValue"
    :label="itemLabel"
    disabled
    :hint="hint"
    persistent-hint
    v-model="selectValueItem">
  </v-select>
</template>

<script>
export default {
  name: 'BaseInputSelectReadOnly',
  props: {
    items: {
      type: Array,
      require: true
    },
    itemText: {
      type: String,
      require: true
    },
    itemValue: {
      type: String,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    },
    selectedValue: {
      require: false
    }
  },
  data () {
    return {
      selectValueItem: ''
    }
  },
  computed: {
    hint () {
      return this.itemLabel + ' is not allowed to be updated'
    }
  },
  mounted () {
    this.selectValueItem = this.selectedValue
  },
  watch: {
    selectedValue (val) {
      this.selectValueItem = val
    }
  }
}
</script>

<style scoped>

</style>
