<template>
  <v-container v-if="isLoaded">
    <v-card class="mt-5">
      <base-card-title
        label="Search proposed shipping & logistic lead time"
        icon="mdi-calendar-range"/>
      <v-card-text class="pl-8 pr-8">
        <v-row class="pt-3">
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Country of delivery"
              :selected-values="proposedLogisticLeadTimeSearchCriteria.countryOfDeliveryIds"
              @change="proposedLogisticLeadTimeSearchCriteria.countryOfDeliveryIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select-with-freight-service
              :grouped-freight-services="groupedFreightServices"
              :freight-services="freightServices"
              item-label="Shipping MoT"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.freightServiceIds"
              @change="proposedLogisticLeadTimeSearchCriteria.freightServiceIds = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-multi-select
              :items="planningMarkets"
              item-value="id"
              item-text="description"
              item-label="Planning market"
              :selected-values="proposedLogisticLeadTimeSearchCriteria.planningMarketIds"
              @change="proposedLogisticLeadTimeSearchCriteria.planningMarketIds = arguments[0]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              item-label="Service provider"
              :items="serviceProviders"
              item-text="description"
              item-value="id"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.serviceProviderId"
              @change="proposedLogisticLeadTimeSearchCriteria.serviceProviderId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placesOfLoading"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Shipping place of loading"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.shippingPlaceOfLoadingId"
              @change="proposedLogisticLeadTimeSearchCriteria.shippingPlaceOfLoadingId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="placesOfDischarge"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Shipping place of delivery"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.shippingPlaceOfDeliveryId"
              @change="proposedLogisticLeadTimeSearchCriteria.shippingPlaceOfDeliveryId = arguments[0]"/>
          </v-col>
        </v-row>

        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="geographicalCountries"
              item-value="id"
              item-text="description"
              item-country="code"
              item-label="Final destination country"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.finalDestinationCountryId"
              @change="proposedLogisticLeadTimeSearchCriteria.finalDestinationCountryId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-with-flag
              :items="transportLocations"
              item-value="id"
              item-text="description"
              item-country="location.code"
              item-label="Final destination location"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.finalDestinationLocationId"
              @change="proposedLogisticLeadTimeSearchCriteria.finalDestinationLocationId = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select
              item-label="Lead times with transit"
              :items="optionsForIncludeTransitLeadTime"
              item-text="name"
              item-value="id"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.includeTransit"
              @change="proposedLogisticLeadTimeSearchCriteria.includeTransit = arguments[0]"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="4" class="pb-0 pt-0">
            <base-input-select-date-picker
              item-label="Valid from"
              :selected-value="proposedLogisticLeadTimeSearchCriteria.validFrom"
              @change="proposedLogisticLeadTimeSearchCriteria.validFrom = arguments[0]"/>
          </v-col>
          <v-col md="4" class="pb-0 pt-0">
            <v-checkbox
              class="mt-0"
              v-model="proposedLogisticLeadTimeSearchCriteria.active"
              @change="setActiveOnlyFlag(arguments[0])"
              label="Only active proposed lead times"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <div class="mt-2">
      <base-input-primary-button
        label="Search"
        icon="mdi-magnify"
        @click="search"/>
      <base-input-secondary-button
        label="Reset"
        icon="mdi-close"
        @click="reset"/>
      <base-input-primary-button
        class="ml-2"
        icon="mdi-microsoft-excel"
        label="Download result to excel"
        @click="download"/>
      <base-input-primary-button
        label="Total report"
        icon="mdi-microsoft-excel"
        class="ml-2"
        @click="downloadTotalReport"/>
      <base-input-primary-button
        v-if="hasResult"
        label="Bulk create"
        class="ml-2"
        @click="showBulkCreateConfirmDialog"/>
    </div>

    <base-dialog-yes-no
      :label="`This action will create ${totalActiveLeadTimeCount} shipping & logistics lead times. Do you want to bulk create?`"
      :dialog="bulkCreateConfirmDialog"
      @yes="showBulkCreateOptions"
      @no="hideBulkCreateConfirmDialog">
    </base-dialog-yes-no>

    <base-dialog-confirm
      :confirm-dialog="bulkCreateOptionsDialog"
      label="Select a publish date for logistic lead time. This configuration will be applied for all lead times during bulk creation.
       Note: Logistics lead time will be valid during the common interval of it's components"
      @confirm="createBulkLogisticsLeadTimes"
      @cancel="() => this.bulkCreateOptionsDialog = false">
      <v-form ref="bulkCreateOptionsForm">
        <v-row>
          <v-col cols="8" class="pb-0 ml-2">
            <base-input-select-date-picker
              item-label="Publish time"
              :selected-value="publishTime"
              @change="publishTime = arguments[0]"/>
          </v-col>
        </v-row>
      </v-form>
    </base-dialog-confirm>

    <base-dialog-ok
      label="Successfully created logistics lead times!"
      :max-width="550"
      :persistent="true"
      @ok="hideBulkCreateSuccessDialog"
      :dialog="bulkCreateSuccessDialog"/>

    <v-card class="mt-10 logistics-lead-time-search-results">
      <base-table-paged-server-side
        ref="table"
        :search-criteria="proposedLogisticLeadTimeSearchCriteria"
        :table-headers="tableHeaders"
        search-result-name="proposedLogisticLeadTimeSearchResults"
        update-search-result-mutation-name="updateProposedLogisticLeadTimeSearchResults"
        reset-search-criteria-mutation-name="resetProposedLogisticLeadTimeSearchCriteria"
        url="/api/touchpoint/proposed-logistic-lead-time/criteria-search">

        <template v-slot:item.shippingLtFrom="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.placeOfLoading.location.code"
            :item-label="item.shippingLeadTime.placeOfLoading.description"/>
        </template>
        <template v-slot:item.shippingLtTo="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.placeOfDelivery.location.code"
            :item-label="item.shippingLeadTime.placeOfDelivery.description"/>
        </template>
        <template v-slot:item.shippingMoT="{ item }">
          <base-label-with-freight-service :freight-service="item.shippingLeadTime.freightService"/>
        </template>
        <template v-slot:item.shippingLtTo="{ item }">
          <base-label-with-flag
            :item-country="item.shippingLeadTime.placeOfDelivery.location.code"
            :item-label="item.shippingLeadTime.placeOfDelivery.description"/>
        </template>

        <template v-slot:item.finalDestination="{ item }">
          <base-label-with-flag
            :item-country="item.finalDestinationLeadTime.location.location.code"
            :item-label="item.finalDestinationLeadTime.location.description"/>
        </template>
        <template v-slot:item.warehouses="{ item }">
          <div class="pt-1 pb-1">
            <base-warehouse-with-tool-tip :logical-warehouses="item.finalDestinationLeadTime.logicalWarehouses"/>
          </div>
        </template>
        <template v-slot:item.planningMarkets="{ item }">
          <div class="pt-1 pb-1">
            <base-planning-market-with-tool-tip :planning-markets="item.finalDestinationLeadTime.planningMarketsActiveOnCurrentDate"/>
          </div>
        </template>
        <template v-slot:item.transitLt="{ item }">
          <a v-if="hasTransitLeadTime(item) === 'Yes'" @click="showTransitView(item)" title="View transit details">Yes</a>
          <span v-else>No</span>
        </template>
        <template v-slot:item.haulageLt="{ item }">
          <a v-if="hasHaulageLeadTime(item) === 'Yes'" @click="showHaulageView(item)" title="View haulage details">Yes</a>
          <span v-else>No</span>
        </template>
        <template v-slot:item.serviceProvider="{ item }">
          <label>{{item.shippingLeadTime.serviceProvider.code}}</label>
        </template>
        <template v-slot:item.shippingLtValidFrom="{ item }">
          <span v-if="item.shippingLeadTime.validFrom"> {{ item.shippingLeadTime.validFrom | formatDate }} </span>
        </template>
        <template v-slot:item.active="{ item }">
          <v-switch
            v-model="item.active"
            @change="updateProposedLogisticLeadTime(item)">
          </v-switch>
        </template>
        <template v-slot:item.actions="{ item }">
          <base-tool-tip
            label="View detail"
            icon="mdi-magnify"
            @click="showViewDialog(item)">
          </base-tool-tip>
          <base-tool-tip
            v-if="item.active"
            label="Create shipping & logistic lead time"
            icon="mdi-truck-delivery-outline"
            @click="showCreateDialog(item)">
          </base-tool-tip>
        </template>
      </base-table-paged-server-side>
    </v-card>

    <base-dialog-ok
      :show-icon=false
      label="No active proposed logistic lead time exists for bulk create. Try searching with different criteria."
      @ok="() => this.noActiveProposedExistsDialog = false"
      :dialog="noActiveProposedExistsDialog"/>

    <search-logistic-lead-time-view-details-dialog
      :viewDialog="viewDialog"
      :viewDialogLogisticLeadTime="viewDialogLogisticLeadTime"
      :can-update="false"
      :proposed-logistic-lead-time="true"
      @close="closeViewDialog"/>

    <search-transit-lead-time-view-details-dialog
      :view-dialog="viewTransitDialog"
      :view-dialog-transit-lead-time="transitInContext"
      :can-update="false"
      @close="closeTransitViewDialog"/>

    <search-haulage-lead-time-view-details-dialog
      :view-dialog="viewHaulageDialog"
      :view-dialog-haulage-lead-time="haulageInContext"
      :can-update="false"
      @close="closeHaulageViewDialog"/>

    <create-logistics-lead-time-from-proposed-lead-time
      :create-dialog-logistic-lead-time="createDialogLogisticLeadTime"
      :shipping-lead-time="shippingLeadTime"
      :transit-lt-exists="transitLtExists"
      :haulage-lt-exists="haulageLtExists"
      :transit-lead-times="transitLeadTimes"
      :haulage-lead-times="haulageLeadTimes"
      :final-destination-lead-times="finalDestinationLeadTimes"
      :create-dialog="createDialog"
      @close="closeCreateDialog"
      @cancel="cancelCreateDialog"/>
  </v-container>
</template>

<script>
import axios from 'axios'
import BaseCardTitle from '@/base/card/BaseCardTitle'
import BaseTablePagedServerSide from '@/base/table/BaseTablePagedServerSide'
import BaseInputMultiSelectWithFlag from '@/base/input/BaseInputMultiSelectWithFlag'
import BaseInputMultiSelectWithFreightService from '@/base/input/BaseInputMultiSelectWithFreightService'
import BaseInputMultiSelect from '@/base/input/BaseInputMultiSelect'
import BaseInputSelect from '@/base/input/BaseInputSelect'
import BaseInputSelectWithFlag from '@/base/input/BaseInputSelectWithFlag'
import BaseLabelWithFlag from '@/base/label/BaseLabelWithFlag'
import BaseLabelWithFreightService from '@/base/label/BaseLabelWithFreightService'
import SearchTransitLeadTimeViewDetailsDialog from '@/components/transitleadtime/searchtransitleadtime/SearchTransitLeadTimeViewDetailsDialog'
import SearchHaulageLeadTimeViewDetailsDialog from '@/components/haulageleadtime/searchhaulageleadtime/SearchHaulageLeadTimeViewDetailsDialog'
import SearchLogisticLeadTimeViewDetailsDialog from '@/components/logisticsleadtime/searchlogisticleadtime/SearchLogisticLeadTimeViewDetailsDialog'
import BaseToolTip from '@/base/tooltip/BaseToolTip'
import CreateLogisticsLeadTimeFromProposedLeadTime from '@/views/logisticsleadtime/CreateLogisticsLeadTimeFromProposedLeadTime'
import BaseInputPrimaryButton from '@/base/input/BaseInputPrimaryButton'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import BaseDialogYesNo from '@/base/dialog/BaseDialogYesNo'
import BaseDialogConfirm from '@/base/dialog/BaseDialogConfirm'
import BaseInputSelectDatePicker from '@/base/input/BaseInputSelectDatePicker'
import BaseDialogOk from '@/base/dialog/BaseDialogOk'
import { arraysUtils, download } from '@/mixins/utilsMixin'
import { mapGetters } from 'vuex'
import BaseWarehouseWithToolTip from '@/base/tooltip/BaseWarehouseWithToolTip'
import BasePlanningMarketWithToolTip from '@/base/tooltip/BasePlanningMarketWithToolTip'

export default {
  name: 'SearchProposedLogisticLeadTime',
  components: {
    BasePlanningMarketWithToolTip,
    BaseWarehouseWithToolTip,
    BaseDialogOk,
    BaseInputSelectDatePicker,
    BaseDialogConfirm,
    BaseDialogYesNo,
    BaseInputSecondaryButton,
    BaseInputPrimaryButton,
    CreateLogisticsLeadTimeFromProposedLeadTime,
    BaseCardTitle,
    BaseInputMultiSelectWithFlag,
    BaseInputMultiSelectWithFreightService,
    BaseInputMultiSelect,
    BaseInputSelect,
    BaseInputSelectWithFlag,
    BaseTablePagedServerSide,
    BaseLabelWithFlag,
    BaseLabelWithFreightService,
    SearchLogisticLeadTimeViewDetailsDialog,
    SearchTransitLeadTimeViewDetailsDialog,
    SearchHaulageLeadTimeViewDetailsDialog,
    BaseToolTip
  },
  mixins: [arraysUtils, download],
  data () {
    return {
      isLoaded: false,
      viewDialog: false,
      createDialog: false,
      viewHaulageDialog: false,
      viewTransitDialog: false,
      transitLtExists: false,
      haulageLtExists: false,
      transitInContext: undefined,
      haulageInContext: undefined,
      viewDialogLogisticLeadTime: {},
      createDialogLogisticLeadTime: {},
      shippingLeadTime: {},
      transitLeadTimes: [],
      haulageLeadTimes: [],
      finalDestinationLeadTimes: [],
      optionsForIncludeTransitLeadTime: [],
      isSearchDone: false,
      totalActiveLeadTimeCount: 0,
      bulkCreateConfirmDialog: false,
      bulkCreateOptionsDialog: false,
      bulkCreateSuccessDialog: false,
      noActiveProposedExistsDialog: false,
      publishTime: '',
      tableHeaders: [
        { text: 'Shipping from', align: 'start', sortable: true, value: 'shippingLtFrom' },
        { text: 'Shipping to', align: 'start', sortable: true, value: 'shippingLtTo' },
        { text: 'Shipping MoT', align: 'start', sortable: true, value: 'shippingMoT' },
        { text: 'Final destination', align: 'start', sortable: true, value: 'finalDestination' },
        { text: 'Warehouses', align: 'start', sortable: false, value: 'warehouses' },
        { text: 'PM', align: 'start', sortable: false, value: 'planningMarkets' },
        { text: 'Transit', align: 'start', sortable: false, value: 'transitLt' },
        { text: 'Haulage', align: 'start', sortable: false, value: 'haulageLt' },
        { text: 'TSP', align: 'start', sortable: true, value: 'serviceProvider' },
        { text: 'Valid from', align: 'start', sortable: false, value: 'shippingLtValidFrom' },
        { text: 'Active?', sortable: false, value: 'active' },
        { text: 'Actions', sortable: false, value: 'actions' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'freightServices',
      'groupedFreightServices',
      'planningMarkets',
      'serviceProviders',
      'transportLocations',
      'geographicalCountries',
      'placesOfLoading',
      'placesOfDischarge',
      'proposedLogisticLeadTimeSearchCriteria'
    ]),
    hasResult () {
      if (this.isSearchDone && this.$refs.table) {
        return this.$refs.table.getTotalItems() > 0
      }
      return false
    }
  },
  async mounted () {
    await axios.all([
      this.$store.dispatch('getAllFreightServices'),
      this.$store.dispatch('getAllGroupedFreightServices'),
      this.$store.dispatch('getAllPlanningMarkets'),
      this.$store.dispatch('getAllServiceProviders'),
      this.$store.dispatch('getAllTransportLocations'),
      this.$store.dispatch('getAllGeographicalCountries'),
      this.$store.dispatch('getAllPlacesOfLoading'),
      this.$store.dispatch('getAllPlacesOfDischarge')
    ]).then(() => { this.isLoaded = true })

    this.optionsForIncludeTransitLeadTime = [
      { id: null, name: 'All' },
      { id: true, name: 'Include' },
      { id: false, name: 'Exclude' }
    ]
  },
  methods: {
    async search () {
      await this.$refs.table.doSearch()
      this.isSearchDone = true
      this.hideAllBulkCreateDialogs()
    },
    reset () {
      this.$refs.table.doReset()
      this.hideAllBulkCreateDialogs()
    },
    download () {
      const fileName = 'ProposedLogisticLeadTimes.xlsx'
      const url = '/api/touchpoint/proposed-logistic-lead-time/criteria-search/download'
      this.downloadToExcel(url, this.proposedLogisticLeadTimeSearchCriteria, fileName)
    },
    hasTransitLeadTime (item) {
      if (item.transitLeadTime) {
        return 'Yes'
      }
      return 'No'
    },
    hasHaulageLeadTime (item) {
      if (item.haulageLeadTime) {
        return 'Yes'
      }
      return 'No'
    },
    showTransitView (item) {
      this.transitInContext = item.transitLeadTime
      this.viewTransitDialog = true
    },
    showHaulageView (item) {
      this.haulageInContext = item.haulageLeadTime
      this.viewHaulageDialog = true
    },
    showViewDialog (item) {
      this.viewDialogLogisticLeadTime = item
      this.viewDialog = true
    },
    closeViewDialog () {
      this.viewDialog = false
    },
    closeHaulageViewDialog () {
      this.viewHaulageDialog = false
    },
    closeTransitViewDialog () {
      this.viewTransitDialog = false
    },
    setActiveOnlyFlag (val) {
      this.searchCriteria.active = val || null
    },
    showCreateDialog (proposedLogisticLeadTime) {
      this.transitLtExists = false
      this.haulageLtExists = false
      this.transitLeadTimes = []
      this.haulageLeadTimes = []
      this.finalDestinationLeadTimes = []

      this.shippingLeadTime = proposedLogisticLeadTime.shippingLeadTime

      if (proposedLogisticLeadTime.transitLeadTime) {
        this.transitLeadTimes.push(proposedLogisticLeadTime.transitLeadTime)
        this.transitLtExists = true
      }

      if (proposedLogisticLeadTime.haulageLeadTime) {
        this.haulageLeadTimes.push(proposedLogisticLeadTime.haulageLeadTime)
        this.haulageLtExists = true
      }

      this.finalDestinationLeadTimes.push(proposedLogisticLeadTime.finalDestinationLeadTime)
      this.createDialogLogisticLeadTime = proposedLogisticLeadTime
      this.createDialog = true
    },
    closeCreateDialog () {
      this.createDialogLogisticLeadTime = {}
      this.createDialog = false
      this.search()
    },
    cancelCreateDialog () {
      this.createDialogLogisticLeadTime = {}
      this.createDialog = false
    },
    updateProposedLogisticLeadTime (item) {
      axios.put('/api/touchpoint/proposed-logistic-lead-time/',
        {
          id: item.id,
          active: item.active
        })
        .then(response => {
          this.search()
        })
        .catch(e => console.log(e))
    },
    showBulkCreateConfirmDialog () {
      axios.post('/api/touchpoint/proposed-logistic-lead-time/affected-lead-times-count', this.proposedLogisticLeadTimeSearchCriteria)
        .then(response => {
          if (response.data > 0) {
            this.totalActiveLeadTimeCount = response.data
            this.bulkCreateConfirmDialog = true
          } else {
            this.noActiveProposedExistsDialog = true
          }
        })
        .catch(e => console.log(e))
    },
    hideBulkCreateConfirmDialog () {
      this.bulkCreateConfirmDialog = false
    },
    showBulkCreateOptions () {
      this.hideBulkCreateConfirmDialog()
      this.bulkCreateOptionsDialog = true
      this.publishTime = ''
    },
    createBulkLogisticsLeadTimes () {
      if (!this.$refs.bulkCreateOptionsForm.validate()) {
        return
      }

      const bulkCreateCommand = {
        criteria: this.proposedLogisticLeadTimeSearchCriteria,
        publishTime: this.publishTime
      }
      axios.post('/api/touchpoint/proposed-logistic-lead-time/bulk-create', bulkCreateCommand)
        .then(response => {
          this.bulkCreateSuccessDialog = true
        })
        .catch(e => console.log(e))
    },
    hideBulkCreateSuccessDialog () {
      this.bulkCreateSuccessDialog = false
      this.hideAllBulkCreateDialogs()
      this.search()
    },
    hideAllBulkCreateDialogs () {
      this.bulkCreateConfirmDialog = false
      this.bulkCreateOptionsDialog = false
      this.bulkCreateSuccessDialog = false
      this.noActiveProposedExistsDialog = false
      this.publishTime = ''
    },
    downloadTotalReport () {
      const currentDateTime = new Date()
      const fileName = 'TotalProposedShippingAndLogisticLeadTimeReport-' +
        currentDateTime.toISOString().split('T')[0] + '-' +
        currentDateTime.toISOString().split('T')[1].split('.')[0].replaceAll(':', '-') + '.xlsx'
      axios.post('/api/touchpoint/proposed-logistic-lead-time/download-total-report',
        this.removeEmptyArrays(this.searchCriteria), {
          headers: { 'Content-Disposition': 'attachment; filename=' + fileName },
          responseType: 'arraybuffer'
        }
      ).then((response) => {
        if (response.status === 204) { // no content
          this.okDialogMessage = 'No proposed lead time found for selected criteria.'
          this.showOkDialog = true
        } else {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
        }
      })
        .catch((error) => console.log(error))
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 100%;
}
</style>
