<template>
  <div>
  <v-app-bar color="white" app elevation="1">
    <v-app-bar-nav-icon id="navBar" @click="drawer = !drawer" v-if="isUserAuthenticated"/>

    <a @click="gotoHome">
      <v-img alt="HMGroup Logo"
             class="shrink mr-2"
             contain
             src="@/assets/hmgroup-logo.png"
             width="150"/>
    </a>

    <v-toolbar-title @click="gotoHome" class="font-weight-bold app-title">SHIPPING & LOGISTIC LEAD TIME</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-menu bottom :open-on-hover="true" v-if="isUserAuthenticated">
      <template v-slot:activator="{ on, attrs }">
        <v-avatar v-bind="attrs" v-on="on" class="red lighten-3" id="userAvatar">
          <span>{{ getAbbreviatedUserName }}</span>
        </v-avatar>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><v-icon color="blue">mdi-email</v-icon> {{getUserName}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="canElevateAccess(getUserRoles) && !isTechAdmin(getUserRoles)">
          <v-list-item-content>
            <v-list-item-title><v-icon color="red">mdi-arrow-up-box</v-icon> <a id="elevateAccess" @click="elevateAccess">Elevate access</a></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><v-icon color="blue">mdi-logout</v-icon> <a class="text-decoration-none" @click="logout">Logout</a></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>

  <v-navigation-drawer fixed
                       temporary
                       width="370"
                       style="margin-top: 64px"
                       v-model="drawer"
                       v-if="isUserAuthenticated">
    <v-list>
      <template v-for="item in item">
        <v-list-group :key="item.title"
                      v-if="hasAccess(getUserRoles, item.rolesAllowed)"
                      v-model="item.active"
                      :prepend-icon="item.icon"
                      no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"/>
            </v-list-item-content>
          </template>
          <template v-for="child in item.subItems">
            <navigation-group v-if="child.subItems" :group-item="child" v-bind:key="child.title"/>
            <template v-else>
              <v-list-item v-if="hasAccess(getUserRoles, child.rolesAllowed)" :key="child.title">
                <v-list-item dense :to="child.to" v-text="child.title"></v-list-item>
              </v-list-item>
            </template>
          </template>
        </v-list-group>
      </template>

    </v-list>
  </v-navigation-drawer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { security } from '@/mixins/utilsMixin'
import NavigationGroup from '@/navigation/NavigationGroup'
import auth from '@/auth/auth-stateless'

export default {
  name: 'Navigation',
  components: { NavigationGroup },
  mixins: [security],
  data: () => ({
    drawer: null,
    username: undefined,
    item: [
      {
        icon: 'mdi-ferry',
        title: 'Shipping lead time',
        subItems: [
          { title: 'Search/View', to: { name: 'SearchShippingLeadTime' } },
          { title: 'Create', to: { name: 'CreateShippingLeadTime' }, rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] },
          { title: 'Replace', to: { name: 'ReplaceShippingLeadTimes' }, rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] }
        ]
      },
      {
        icon: 'mdi-clock-plus',
        title: 'Additional shipping lead time',
        rolesAllowed: ['ROLE_TECH_ADMIN'],
        subItems: [
          { title: 'Search/View', to: { name: 'SearchAdditionalShippingLeadTime' } },
          { title: 'Create', to: { name: 'CreateAdditionalShippingLeadTime' } }
        ]
      },
      {
        icon: 'mdi-airplane',
        title: 'Transit lead time',
        subItems: [
          { title: 'Proposed', to: '/ProposedTransitLeadTime/Search', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] },
          { title: 'Search/View', to: '/TransitLeadTime/Search' },
          { title: 'Create', to: '/TransitLeadTime/Create', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] }
        ]
      },
      {
        icon: 'mdi-truck-delivery-outline',
        title: 'Haulage lead time',
        subItems: [
          { title: 'Proposed', to: '/ProposedHaulageLeadTime/Search', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] },
          { title: 'Search/View', to: '/HaulageLeadTime/Search' },
          { title: 'Create', to: '/HaulageLeadTime/Create', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] }
        ]
      },
      {
        icon: 'mdi-warehouse',
        title: 'Final destination lead time',
        subItems: [
          { title: 'Proposed', to: '/ProposedFinalDestinationLeadTime/Search', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] },
          { title: 'Search/View', to: '/FinalDestinationLeadTime/Search' },
          { title: 'Create', to: '/FinalDestinationLeadTime/create', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] }
        ]
      },
      {
        icon: 'mdi-calendar-clock',
        title: 'Shipping & Logistic lead time',
        subItems: [
          { title: 'Create Proposed', to: '/ProposedLogisticsLeadTime/Create', rolesAllowed: ['ROLE_TECH_ADMIN'] },
          { title: 'Search Proposed', to: '/ProposedLogisticLeadTime/Search', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] },
          { title: 'Search/View', to: '/LogisticsLeadTime/Search' },
          { title: 'BO & PO Search/View', to: '/BopoLogisticsLeadTime/Search' },
          { title: 'Create', to: '/ShippingLeadTime/Search', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] },
          {
            title: 'Reports',
            subItems: [
              { title: 'Difference Report', to: '/LogisticsLeadTime/DifferenceReport' }
            ]
          }
        ]
      },
      {
        icon: 'mdi-truck-delivery-outline',
        title: 'Terms of delivery',
        subItems: [
          { title: 'Search/View', to: '/TermsOfDelivery/Search' },
          { title: 'Create', to: '/TermsOfDelivery/Create', rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'] }
        ]
      },
      {
        icon: 'mdi-database',
        title: 'Master data',
        rolesAllowed: ['ROLE_TECH_ADMIN', 'ROLE_ADMIN'],
        subItems: [
          { title: 'Carriers', to: '/Carrier/View' },
          { title: 'Corporate brands', to: '/CorporateBrand/View' },
          { title: 'Freight services', to: '/FreightService/View' },
          { title: 'Geographical countries', to: '/GeographicalCountry/View' },
          { title: 'Inco terms', to: '/IncoTerm/View' },
          { title: 'Logical warehouses', to: '/LogicalWarehouse/View' },
          { title: 'Mode of transports', to: '/ModeOfTransport/View' },
          { title: 'Mode of transport categories', to: '/ModeOfTransportCategory/View' },
          { title: 'Packing modes', to: '/PackingMode/View' },
          { title: 'Planning markets', to: '/PlanningMarket/View' },
          { title: 'Service providers', to: '/ServiceProvider/View' },
          { title: 'Transport locations', to: '/TransportLocation/View' }
        ]
      },
      {
        icon: 'mdi-database-export-outline',
        title: 'Base load',
        rolesAllowed: ['ROLE_TECH_ADMIN'],
        subItems: [
          { title: 'Geographical countries', to: '/GeographicalCountry/BaseLoad' },
          { title: 'Transport locations', to: '/TransportLocation/BaseLoad' },
          { title: 'Planning markets', to: '/PlanningMarket/BaseLoad' },
          { title: 'Corporate brands', to: '/CorporateBrand/BaseLoad' },
          { title: 'Carriers', to: '/Carrier/BaseLoad' },
          { title: 'Service providers', to: '/ServiceProvider/BaseLoad' },
          { title: 'Logical warehouses', to: '/LogicalWarehouse/BaseLoad' }
        ]
      },
      {
        icon: 'mdi-wrench',
        title: 'Tech Admin',
        rolesAllowed: ['ROLE_TECH_ADMIN'],
        subItems: [
          { title: 'Bulk Event Send of LLTs', to: '/BulkEventSendLogisticsLeadTime/View' },
          { title: 'Import Listeners', to: '/ImportListeners/View' }
        ]
      }
    ]
  }),
  computed: {
    ...mapGetters([
      'getUserRoles',
      'getUserName',
      'getAbbreviatedUserName',
      'isUserAuthenticated'
    ])
  },
  methods: {
    elevateAccess () {
      this.$store.commit('elevateRole')
    },
    gotoHome () {
      this.$router.push({ name: 'Home' })
    },
    logout () {
      auth.logout()
        .then(() => {
          this.$store.commit('resetUserInfo')
          this.$router.push({ name: 'Logout' })
        })
    }
  }
}
</script>
<style scoped>
  .col {
    padding: 5px;
  }
  .v-list-item {
    min-height: 48px;
  }
</style>
