<template>
  <div class="text-start">
    <country-flag :country="itemCountry" style="margin: -15px"/>  <span> {{itemLabel}} </span>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag'

export default {
  name: 'BaseLabelWithFlag',
  components: { CountryFlag },
  props: {
    itemCountry: {
      type: String,
      require: true
    },
    itemLabel: {
      type: String,
      require: true
    }
  }
}
</script>

<style scoped>
.normal-flag {
    margin: -15px;
}

</style>
