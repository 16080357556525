<template>
  <div>
    <div v-if="isLoading" class="loader-overlay">
      <div class="loader">
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="green"
        ></v-progress-circular>
      </div>
    </div>

    <v-overlay
      absolute="absolute"
      :value="hasErrors">
      <v-dialog v-model="hasErrors" :persistent="true" :max-width="500">
        <v-card>
          <v-card-title>Error happened while contacting LLT</v-card-title>
          <v-card-text>
            <p>{{error}}</p>
            <div>{{message}}</div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <base-input-secondary-button @click="okayTheError" label="Continue"/>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>
import axios from 'axios'
import BaseInputSecondaryButton from '@/base/input/BaseInputSecondaryButton'
import { mapGetters } from 'vuex'

export default {
  name: 'BaseAjaxLoader',
  components: { BaseInputSecondaryButton },
  data () {
    return {
      requestCount: 0,
      isLoading: false,
      hasErrors: false,
      error: '',
      message: ''
    }
  },
  computed: {
    ...mapGetters([
      'getAccessToken',
      'isAuthenticating'
    ])
  },
  watch: {
    isAuthenticating (val) {
      this.setLoading(val)
    }
  },
  mounted () {
    const self = this

    if (this.isAuthenticating) {
      this.setLoading(this.isAuthenticating)
    }

    axios.interceptors.request.use(async (config) => {
      await this.$store.dispatch('authenticateApiUser')
      config.headers.Authorization = 'Bearer ' + this.getAccessToken
      if (!config.headers.silent) {
        self.setLoading(true)
      }
      return config
    }, (error) => {
      self.setLoading(false)
      return Promise.reject(error)
    })
    axios.interceptors.response.use((response) => {
      self.setLoading(false)
      return response
    }, function (error) {
      self.setLoading(false)
      self.hasErrors = true
      self.formatError(error)
      self.formatMessage(error)
      return Promise.reject(error)
    })
  },
  methods: {
    setLoading (isLoading) {
      if (isLoading) {
        this.requestCount++
        this.isLoading = true
      } else if (this.requestCount > 0) {
        this.requestCount--
        this.isLoading = (this.requestCount > 0)
      }
    },
    okayTheError () {
      this.hasErrors = false
      this.error = ''
      this.message = ''
    },
    formatError (error) {
      if (error.toString().indexOf('timeout') >= 0) {
        this.error = 'LLT took too long to respond, please try again in sometime. If the problem persists, please contact support.'
      } else {
        this.error = error.toString()
      }
    },
    formatMessage (error) {
      if (error.response) {
        if (error.response.status >= 500) {
          this.message = 'Internal server error happened. The error has been logged. If the problem persists, please contact support.'
        } else if (error.response.status === 403) {
          this.message = 'You do not have permission to perform this request. Please contact support for more information.'
        } else if (error.response.status >= 400 && error.response.status < 500) {
          this.message = 'Unable to connect to LLT or bad request. please try again later. If the problem persists, please contact support.'
        } else {
          this.message = ''
        }
      }
    }
  }
}
</script>

<style scoped>
  .loader-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    cursor: pointer;
    margin: 0 auto;
  }

  .container {
    width: 50%;
  }

  .loader {
    top: 50%;
    position: absolute;
    left: 50%;
  }

</style>
