export const transportModeIconResolverMixin = {
  methods: {
    resolveIcon (transportMode) {
      if (transportMode && transportMode.name === 'AIR') {
        return 'mdi-airplane'
      } else if (transportMode && transportMode.name === 'RAIL') {
        return 'mdi-train'
      } else if (transportMode && transportMode.name === 'ROAD') {
        return 'mdi-truck'
      } else if (transportMode && transportMode.name === 'SEA') {
        return 'mdi-ferry'
      } else if (transportMode && transportMode.name === 'SUPPLIERS_CHOICE') {
        return 'mdi-help'
      }
    }
  }
}
